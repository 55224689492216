/*
Jobs Promoter
*/
.block-jobs-promoter {

  .content {
    @extend %grid-layout;

    .no-jobs {
      @include grid-area(1,1,1,7);
    }
  }

  .job-tile {
    @include grid-area(auto,auto,auto,span 4);
  }

}
