/*
Configuration
*/

$search-icon-size: 23px;
$member-icon-size: 27px;


// Search and Member icons

.nav-search a, .nav-member a {
	display:block;
	transition:background $transition-d-bezier;
}

.nav-search a {
	@include set-on-page-styles((blue green)) {
		@extend .i-search.o-light;
		&:hover {
			@extend .i-search.o-dark;
		}
	}
	&:hover {
		@extend .i-search.o-orange;
	}
}

.nav-member a {
	@include set-on-page-styles((blue green)) {
		@extend .i-user.o-light;
		&:hover {
			@extend .i-user.o-dark;
		}
	}
	&:hover {
		@extend .i-user.o-orange;
	}
}

.nav-search {
	padding:0 $spacer-s;
	margin-right:$spacer-s;

	a {
		width:$search-icon-size;
		height:$search-icon-size;
	}

	&.small-screen {
		// position:absolute;
		// top:40px;
		// right:$spacer-normal;
		// z-index:99;
	}

	@include respond-below(md) {

		position:absolute;
		top:50px;
		margin-top:2px;
		right:$spacer-normal;
		z-index:99;

		&.desktop {
			display:none;
		}
	}

	@include respond-below(sm) {
		top:40px;
	}

	@include respond-above(md) {
		&.small-screen {
			display:none;
		}
	}
}

.nav-member a {
	width:$member-icon-size;
	height:$member-icon-size;
}

//////////
// Desktop
//////////
@include respond-above(md) {
	nav.nav-items {
		text-align:left;
		display:flex;
		width:100%;
		align-items:center;
		justify-content: space-between;
		margin-left:-#{$spacer-xs};

		ol {
			display:flex;
			flex-grow: 1;
			align-items:center;
			justify-content: space-between;
			margin-right:$spacer-normal;

			@include respond-below(lg) {
				margin-right:$spacer-m;
			}
		}

		li {
			display:inline-block;

			.nav-sub-menu {
				display:none;
			}

			&.nav-item {
				a {

					@include set-on-page-styles((blue)) {
						color:$colour-white;
					}
					@include set-on-page-styles((green)) {
						color:$colour-white;
					}

					white-space: nowrap;
					position:relative;
					transition: color $transition-d-bezier;
					text-decoration:none;
					box-sizing: content-box;
					display:block;
					padding:$spacer-xs;
					// margin-right:$spacer-m;

					&::after {
						bottom:-5px;
						opacity:0;
					}
				}

				&:hover a,
				&.active a {
					@include set-on-page-styles((green)) {
						color:$colour-blue;
					}

					color:$colour-orange;

					&::after {
						@include set-on-page-styles((green)) {
							border-color:$colour-blue;
						}
						opacity:1;
						bottom:4px;
					}
				}
			}
		}
	}
}


//////////
// Small Screen
//////////
.nav-small-screen-button {
	position:absolute;
	top:$spacer-m + 10;
	right:$spacer-m - 5;
	display:none;
	height:25px;
	width:25px;
	margin:0;
	z-index:103;

	@include respond-below(sm) {
		top:$spacer-m;
	}

	&:focus {
		outline:none;
	}

	.page-style-green &,
	.page-style-blue & {
		@extend .i-menu.o-light;
	}
}

// Small Screen alterations
@include respond-below(md) {

	// STATE: Default
	.nav-small-screen-button {
		display:block;
	}

	.nav-items {
		display:none;
	}

	.nav-item {

		.nav-sub-menu-button {
			display:inline-block;
			margin-left:$spacer-m;
			width:32px;
			position:relative;
			top:5px;
			cursor: pointer;
		}

		.nav-sub-menu {
			display:none;
			flex-direction: column;
			margin-left:$spacer-m;

			&.open {
				display:flex;
			}
		}

		.nav-sub-menu-link {
			margin-top:7px;
			margin-bottom:3px;
			&:first-of-type {
				margin-top:12px;
			}
		}

	}

	.site-nav {
		@include absolute-position(false);
		display:none;
		position:fixed;
		z-index:100;
		width:100vw;
		height:100vh;
		background:$colour-orange;
		padding:$spacer-normal * 2 $spacer-m $spacer-m $spacer-m;
	}

	.nav-member {
		margin-top:$spacer-m;
		padding-top:$spacer-s;
		border-top:1px solid $colour-white;

		.i-user {
			$icon: get-icon(user, "light");
			background-image:url($icon);
			&:hover {
				background-image:url($icon);
			}
		}

		a {
			text-decoration:none!important;
		}

		a::after {
			content:"Login";
			display:block;
			padding-left:$spacer-m;
			color:$colour-white!important;
			text-decoration:none!important;
		}
	}

	// STATE: Active/open
	body.nav-small-screen--open {

		// Stop scrolling
		overflow:hidden;

		.nav-small-screen-button {
			right:$spacer-m;
		}

		.nav-items {
			display:block;
		}

		.site-nav {
			display:block;
		}

		.nav-member a {
			filter:none!important;
		}
	}
}
