/*
Overlay
*/
.overlay {
  @include absolute-position(false);
  height:100vh;
  width:100vw;
  z-index:200;
  background:transparentize($colour-black, .5);
  position:fixed;
  animation: fade-in 1s normal forwards ease-in-out;
  display:none;

  &.active {
    display:block;
  }

  >  .outer {
    margin-left:auto;
    width:calc((#{$layout-content-max-width} / 12 * 10) - #{$layout-grid-gap});
    height:100vh;
    overflow-y: scroll;
    background:$colour-grey;

    @include respond-below(md) {
      max-width:100vw;
    }

    > .inner {
      position:relative;
      @extend %grid-overlay-person;
    }
  }

  .close {
    $size:75px;

    transition: background $motion-easing-basic;
    background-image:url(get-icon(close-circle, "light"));
    cursor: pointer;
    position:absolute;
    top:$spacer-m;
    right:$spacer-m;
    z-index:1;
    width:$size;
    height:$size;

    @include respond-below(sm) {
      right:$spacer-s;
      width:$size * .6;
      height:$size * .6;
      top:$spacer-s;
      background-size: contain;
    }

    &:hover {
      background-image:(url(get-icon(close-circle, "dark")));
    }
  }

}
