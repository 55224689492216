/*
News Promoter
*/
.block-news-promoter {

  .top, .content {
    padding-left:0;
    padding-right:0;
  }

  .bottom {
    padding-top:$spacer-normal;

    @include respond-below(sm) {
      padding-top:0;
    }
  }

  .news-article-thumb {
    @include grid-area(1, auto, 1, span 4);
    @include target-ie {
      margin-right:$layout-grid-gap;
      $span: 4;
      @for $i from 1 through 3 {
        &:nth-of-type(#{$i}) {
          @include grid-area(1, #{($i - 1) * $span + 1}, 1, #{$i * $span + 1});
        }
      }
    }
    @include respond-below(md) {
      @include grid-area(1, auto, 1, span 6);
      @include target-ie {
        margin-right:$layout-grid-gap;
        $span: 6;
        @for $i from 1 through 2 {
          &:nth-of-type(#{$i}) {
            @include grid-area(1, #{($i - 1) * $span + 1}, 1, #{$i * $span + 1});
          }
        }
      }
      &:nth-of-type(3) {
        display:none;
      }
    }
    @include respond-below(sm) {
      margin-bottom:$spacer-s;
    }
  }

}
