$graph-height:270px;
.block.block-data-graph {

  &.o-size-normal {
    .information {
      @include grid-area(1,1,1,6);
    }
    .graph {
      @include grid-area(1,8,1,12);
    }
  }

  &.o-size-narrow {
    .information {
      @include grid-area(1,2,1,6);
      @include respond-below(lg) {
        @include grid-area(1,1,1,6);
      }
    }
    .graph {
      @include grid-area(1,8,1,12);
      @include respond-below(lg) {
        @include grid-area(1,8,1,13);
      }
    }
  }

  .information {
    .data-set.o-cols-two {
      // columns: 2;
    }
    .data-dot {
      display:inline-block;
      height:10px;
      width:10px;
      border-radius:100%;
      margin-right:10px;
    }
    .data-label, .data-value {
      color:$colour-white;
    }
    .data-value {
      display:inline-block;
      min-width:max-content;
      width:40px;
    }
    .data-label {
      word-wrap: break-word;
      overflow-wrap: break-word;
    }
  }

  .graph {
    padding-right:$spacer-normal;
    position:relative;
    height:max-content;

    canvas {
      cursor: pointer;
    }

    @include respond-below(sm) {
      margin:$spacer-normal - 10 $spacer-m $spacer-s $spacer-m;
      padding-right:0;
    }
  }

  .graph-tooltip {
    @include absolute-position(false);

    text-align: center;
    display:flex;
    flex-direction:column;
    align-items: center;
    align-content: center;
    justify-content: center;
    height:auto;
    width:calc(100% - #{$spacer-normal});
    pointer-events: none;
    opacity:0;

    @include respond-below(sm) {
      width:100%;
    }

    .stat-figure {
      margin-bottom:$spacer-xs;
      @include respond-between(sm,lg) {
        font-size: 3em!important;
      }
      @include respond-below(sm) {
        margin-bottom:$spacer-m + 10;
      }
    }

    .stat-definition {
      color:$colour-white;
      max-width:60%;
      text-align: center!important;
    }
  }
}
