/*
Person Thumb
*/
.person-thumb {

  &:hover {
    .portrait {
      @include sexy-box-shadow($colour-orange);
      transform:$transform-lift;
      &.fx-gradient img {
        opacity:0;
      }
    }
    .name {
      color:$colour-orange;
    }
  }

  &.loading {
    .image {
      position:relative;
      &::before {
        @include absolute-position(true);
        display:flex;
        justify-content: center;
        align-content: center;
        align-items: center;
        content:"Loading";
        color:$colour-white;
        background:transparentize($colour-orange,.3);
        animation:
          fade-in 1s normal forwards ease-in-out,
          loading-text-pulse 2s infinite alternate ease-in-out;
        z-index:2;
      }
    }
  }

  .portrait {
    box-shadow:none;
    transition: box-shadow $transition-d-bezier;

    &.o-silhouette img {
      background-color: $colour-grey;
      display:block;
    }

    &.fx-gradient {
      background-size:cover;
      img {
        transition: opacity $transition-d-bezier;
        display:block;
      }
    }
  }

  .inner {
    padding:$spacer-xs;
    a {
      text-decoration: none;
    }
  }

  .name {
    font-weight:$font-weight-heavy;
    transition:color $transition-d-bezier;
    display:block;
  }

  .role {
    display:block;
    color:$colour-blue;
    opacity:.7;
  }

  .block.o-style-blue & {
    .name, .role {
      color:$colour-white;
    }
  }
}
