/*
Three Column
*/
.block-three-column {

  @include respond-below(sm) {
    .column:not(:last-of-type) {
      margin-bottom:$spacer-m;
    }
  }

  ul,ol {
    columns: 1;
  }

  .content {
    @extend %grid-layout;
  }

  &.o-alignment-center {
    .introduction,
    .column {
      text-align: center;
    }
  }

  &.o-size- {
    &normal {
      .column {
        @include grid-area(1, auto, 1, span 4);
        @include target-ie {
          padding-right:$layout-grid-gap;
          $span: 4;
          @for $i from 1 through 3 {
            &:nth-of-type(#{$i}) {
              @include grid-area(1, #{($i - 1) * $span + 1}, 1, #{$i * $span + 1});
            }
          }
        }
      }
    }
    &narrow {
      .top .inner {
        @include grid-area(1, 2, 1, 7);
      }
      $cols: 3;
      @for $i from 1 through 3 {
        .column:nth-of-type(#{$i}) {
          @include grid-area(1 , #{2 + (($i - 1) * $cols)}, 1, span 3);
        }
      }
    }
  }
}
