/*
Variables
*/
$file-path-media:     '/assets/media/';
$file-path-graphics:  $file-path-media + 'graphics/';

/*
Globals
*/
$phi: 1.6180339887498948482;



/*
Colours
@see http://chir.ag/projects/name-that-color/
*/
// The 101s
$colour-black:				  #000000;
$colour-white:				  #ffffff;
$colour-grey-mid:       #D3D3D3;
$colour-grey:           #E7EDEC;
// Brand styles
$colour-orange:         #ED5436;
$colour-blue:           #1D314D;
$colour-green:          #5A8080;
// System alerts
// $colour-green:          #77AF6B;
$colour-red:            #c0c0c0;
// Base
$colour-base: 				  $colour-blue;

// Declare as CSS vars in the :root scope
:root {
  --colour-black:   #{$colour-black};
  --colour-white:   #{$colour-white};
  --colour-grey:    #{$colour-grey};
  --colour-orange:  #{$colour-orange};
  --colour-blue:    #{$colour-blue};
  --colour-red:     #{$colour-red};
  --colour-green:   #{$colour-green};
}


/*
Spacing
*/
$spacer-normal:	     80px;
$spacer-m:           $spacer-normal / 2;
$spacer-s:           $spacer-normal / 4;
$spacer-xs:          $spacer-normal / 8;

// @debug "Spacing" $spacer-normal $spacer-m $spacer-s $spacer-xs;


/*
Breakpoints
*/
$breakpoints: (
  xs: 576px,
  sm: 768px,
  md: 992px,
  lg: 1200px
);


/*
Motion
*/
$motion-easing-basic: 		.3s ease-in-out;
$motion-transition-all: 	all $motion-easing-basic;
$motion-subnav-fadein-duration: .6s;


/*
Style
*/
$style-border-radius:     35px;
$style-border-colour:     rgba(165,165,165,0.44);
$style-opacity-mid:       .8;
$style-opacity-low:       .5;


/*
Effects
*/
$transform-lift: matrix3d(1,0,0.00,0,0.00,1,0.00,-.00005,0,0,1,0,0,0,0,1);
$sexy-box-shadow:
3px 6.4px 7.6px -26px transparentize($colour-orange, 1-0.047),
5px 11.2px 14.1px -26px transparentize($colour-orange, 1-0.098),
10px 15.2px 19.4px -26px transparentize($colour-orange, 1-0.15),
15px 19.1px 24px -26px transparentize($colour-orange, 1-0.196),
20px 25px 29px -26px transparentize($colour-orange, 1-0.227),
25px 44px 39px -26px transparentize($colour-orange, 1-0.24);
