/*
Key Data
*/
.block.block-key-data {

  .content {
    display:flex;
    justify-content: flex-start;
    align-items: stretch;
    align-content:flex-start;
    padding-top:$spacer-normal - 20!important;
    padding-bottom:$spacer-normal - 20!important;

    @include respond-below(lg) {
      padding-left:$spacer-m!important;
    }
    @include respond-below(sm) {
      display:block;
    }
  }

  .intro {
    min-width:250px;

    h2 {
      margin-bottom:$spacer-s;
    }

    .logo {
      width:75%;
      img {
        max-height:100px;
        max-width:100%;
        width:auto;
      }
    }

    @include respond-below(sm) {
      display:flex;
      justify-content: space-between;
      width:100%;
      padding-bottom:$spacer-m;
      margin-bottom:$spacer-m;
      border-bottom:1px solid transparentize($colour-blue, .8);

      .logo {
        width:50%;
      }
    }
  }

  .data-items {
    width:100%;
    display:flex;
    flex-wrap: wrap;
  }

  .data-item {
    // flex:1;
    width:20%;
    min-width:max-content;

    margin-bottom:$spacer-s;

    .title, .value {
      white-space: nowrap;
      margin-bottom:$spacer-xs;
    }

    .value {
      font-size:$font-size-m;
    }

    @include respond-between(sm, md) {
      width:50%;
    }

    @include respond-above(sm) {
      padding:0 $spacer-s + 10;
      border-left:1px solid transparentize($colour-blue, .8);
    }

    @include respond-below(sm) {
      display:inline-block;
      margin-bottom:$spacer-m;
      width:50%;

      .title {
        margin-bottom:$spacer-xs;
      }
    }
    //
    // &:first-of-type {
    //   border:none!important;
    // }
  }

}
