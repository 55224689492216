.block.block-logo-gallery {

  .logos {
    @include grid-area(auto,1,auto,13);
    display:flex;
    flex-wrap:wrap;

    @include respond-below(sm) {
      justify-content: space-between;
    }
  }

  .logo {
    width:calc(100% / 5);
    margin-right:$spacer-m;

    @include respond-below(sm) {
      width:calc(48%);
      margin-right: 0;
      margin-bottom:$spacer-s;
    }
  }

  .title {
    opacity:.5;
  }

}
