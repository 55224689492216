.block.block-press-information {

  .information-section {
    margin-bottom:$spacer-normal;

    &:nth-of-type(odd) {
      @include grid-area(auto, 2, auto, span 4);
    }

    &:nth-of-type(even) {
      @include grid-area(auto, 7, auto, span 4);
    }

    @include respond-below(lg) {

      @include grid-area(auto, 2, auto, 12, true);
    }

  }

  &[data-columns="3"] .information-section {
    // @include grid-area(auto, auto, auto, span 4);
  }

}
