/*
Icon
*/

$btn-icon-size: 20px;

//////////
// Mixins
//////////

@function get-icon($name, $colour) {
  @return $file-path-graphics + "#{$name}-#{$colour}.svg";
}

@mixin set-icon-colour($name, $colour) {
  background-image:url($file-path-graphics + "#{$name}-#{$colour}.svg");
}


//////////
// Icons
//////////
$icon-names:arrow, close-circle, close, search, user, arrow-circle, point, menu;
$icon-colours:light, dark, orange;
$icon-preload-cache:[];

// Build icons
@each $icon-name in $icon-names {
  .i-#{$icon-name} {
    background-size:contain;

    // Build preload
    @each $icon-colour in $icon-colours {
      $preload-image: get-icon($icon-name, $icon-colour);
      $icon-preload-cache: append($icon-preload-cache, url(#{$preload-image}));
    }

    // Setup icons
    @if $icon-name == "arrow" {
      &::after {
        &, &.o-dark {
          $image: get-icon($icon-name, "dark");
          background:url($image) no-repeat center center;
        }
        &.o-light {
          $image: get-icon($icon-name, "light");
          background-image:url($image);
        }
        &.o-orange {
          $image: get-icon($icon-name, "orange");
          background-image:url($image);
        }
      }

    } @else {
      &, &.o-dark {
        $image: get-icon($icon-name, "dark");
        background:url($image) no-repeat center center;
      }
      &.o-light {
        $image: get-icon($icon-name, "light");
        background-image:url($image);
      }
      &.o-orange {
        $image: get-icon($icon-name, "orange");
        background-image:url($image);
      }
    }
  }
}

// Setup icon preload
.is-mob {
  $urls: to-string($icon-preload-cache, ", ");
  background-image: $urls;
}

//////////
// Styles
//////////

.i-arrow,
%i-arrow {
  position:relative;

  &::after {
    content:"";
    position:absolute;
    display:inline-block;
    height:$btn-icon-size;
    width:$btn-icon-size;
    right:$spacer-xs+3;
    top:calc(30%);
    background-image:url(get-icon(arrow, "orange"));
    background-repeat: no-repeat;
  }

  /*
  Variations
  */
  &.o-no-arrow {
    padding-right:$spacer-m - 3;
    &::after {
      display:none;
    }
  }
  &.o-down::after {
    transform: rotate(90deg) translateX(-50%);
    top:50%;
  }
  &.o-up::after {
    transform: rotate(-90deg) translateX(50%);
    top:calc(50% - 1px);
  }
  &.o-up-right::after {
    transform: rotate(-45deg) translateX(50%);
    top:calc(50% - 1px);
  }
  &.o-plus::after {
    background-image:url(get-icon(plus, "dark"));

    &.o-light {
      background-image:url(get-icon(plus, "light"));
    }

    &.o-orange {
      background-image:url(get-icon(plus, "orange"));
    }
  }
}

.i-point {
  height:32px;
  width:19px;
  &.o-down {
    transform:rotate(90deg);
  }
}
