.block.block-press-contacts {

  .contact-group {
    @include grid-area(auto, 1, auto, 13);

    display:flex;

    .title {
      width:40%;
      margin-right:$layout-grid-gap;
    }

    &:not(:first-of-type) {
      padding-top:$spacer-m;
      border-top:1px solid $colour-grey-mid;
    }
  }

  .group-contacts {
    display:flex;
    flex-wrap: wrap;
    width:60%;
    margin-bottom:$spacer-m;

    @include respond-below(sm) {
      flex-direction: column;
    }
  }

  .group-contact {
    width:calc(50% - #{$layout-grid-gap});
    margin-right:$layout-grid-gap;
    margin-top:$spacer-m;

    @include respond-below(sm) {
      width:100%;
      &:not(:last-of-type) {
        margin-bottom:$spacer-m;
      }
    }

    &:nth-of-type(-n+2) {
      margin-top:0;
    }

    &:nth-of-type(even) {
      margin-right:0;
    }

    a {
      display:block;
    }
  }

}
