$logo-size-ratio: 420 / 114;
$logo-width: 178px;
$logo-width-small: 128px;

.site-logo {
  background:transparent url($file-path-media + "logos/logo-blue.svg") no-repeat center center;
  background-size:contain;
  display:block;
  width:$logo-width;
  height:$logo-width / $logo-size-ratio;

  @include respond-below(sm) {
    width:$logo-width-small;
    height:$logo-width-small / $logo-size-ratio;
  }

  &.o-light {
    background-image:url($file-path-media + "logos/logo-white.svg");
  }

  .site-header & {
    @include grid-area(1, 1, 1, 4);
  }
}
