.block.block-global-stats {

  &.o-bg-blue {
    @include respond-above(md) {
      background-image: linear-gradient(to bottom, $colour-blue 0%, $colour-blue 70%, $colour-blue 70%, $colour-blue 100%);
    }
  }

  &.o-bg-grey {
    @include respond-above(md) {
      background-image: linear-gradient(to bottom, $colour-blue 0%, $colour-blue 70%, $colour-grey 70%, $colour-grey 100%);
    }
  }

  &.o-bg-white {
    background-image: linear-gradient(to bottom, $colour-blue 0%, $colour-blue 70%, $colour-white 70%, $colour-white 100%);
  }

  .global-offices {
    position:absolute;
    bottom:10%;
    left:50%;
    transform: translateX(-50%);

    @include respond-below(md) {
      transform: translateX(-50%) scale(.8);
    }
    @include respond-below(sm) {
      display:none;
    }
  }

  .banner {
    display: flex;
    text-align: center;
    align-items: center;

    &::before, &::after {
      display:block;
      content:"";
      width:50%;
      background:rgba(255,255,255,.2);
      height:1px;
    }
    img {
      width:30px;
      margin:0 20px;

      @include respond-below(md) {
        width:15px;
      }
    }
  }

  .cities {
    list-style-type: none;
    padding:0;
    margin:20px auto 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;

    li {
      display: flex;
      padding:0;
      margin:0 5px;
      white-space: nowrap;

      &:first-of-type {
        margin-left:0;
        &::before {
          display:none;
        }
      }
      &:last-of-type {
        margin-right:0;
      }

      &::before {
        display:block;
        margin:0 10px 0 0;
        color:$colour-white;
        transform: none;
        font-size: 20px;
      }
    }
  }

  .start-stats {
    .global-stats {
      opacity:1;
    }
  }

  .global-stats-wrapper {
    position:relative;
    @include grid-area(1,1,1,13);

    @include respond-below(md) {
      margin:0 -$layout-grid-gap - 10;
      margin-top:$spacer-s;
    }
  }

  .image {
    @include respond-below(sm) {
      position:absolute;
      z-index: -1;
      height:100%;
      width:100vw;
      overflow: hidden;
      img {
        height:100%;
        width:auto;
        object-fit: cover;
        object-position: center;
      }
    }
  }

  .global-stats {
    transition: opacity 5s $animation-easing-bezier;
    opacity:0;
    position:absolute;
    top:50%;
    left:50%;
    transform: translate3d(-50%,-50%,0);
    text-align:center;
    z-index:20;
    display:flex;
    // justify-content:space-between;
    flex-direction: row;
    align-content: stretch;
    align-items: stretch;
    width:calc(100% - #{$spacer-m});

    @include respond-below(sm) {
      position: static;
      transform:none;
      display:block;
      text-align: center;
      margin:0 auto;
      width:auto;
      padding: $spacer-m 0;
    }
  }

  .global-stat {
    text-align: center;
    flex-grow: 1;
    width:33%;

    @include respond-below(sm) {
      display:flex;
      flex-direction: column;
      justify-content:space-between;
      width:auto;

      &:last-of-type {
        margin-bottom:0;
      }
    }

    .stat-headline {
      color:$colour-white;
      display:block;
      margin:0 auto $spacer-xs auto;
      text-align: center;
      white-space: nowrap;

      @include respond-between(md, lg) {
        font-size:1.5vw;
      }
    }

    .stat-figure {
      text-align: center;
      display:block;

      @include respond-between(md, lg) {
        font-size:6vw!important;
      }
    }
  }
}
