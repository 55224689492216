/*
Header
*/

$fold-hint-size:    38px;

/////////
// Site Header
////////

.site-header {

  overflow-x:hidden;
  min-height:50vh;

  @include respond-below(md) {
    position:relative;
  }

  .site-logo {
    // position:absolute;
    .nav-small-screen--open & {
      z-index:300;
    }
  }

  .page-style-green &,
  .page-style-blue & {
    .site-logo {
      background-image:url($file-path-media + "logos/logo-white.svg");
    }
    h1, .summary, .strapline, .introduction * {
      color:$colour-white;
    }
  }

  .inner {
    @extend %grid-container;
    @include respond-below(md) {
      padding-left:0;
      padding-right:0;
    }
  }
}

.site-header-top {
  @extend %grid-layout;
  @include respond-below(md) {
    display:block;
  }
  @include respond-below(sm) {
    padding-top:$spacer-m;
  }
  padding-top:$spacer-m + 10;
}

.site-nav {
  @include grid-area(1, 4, 1, 13);
}



//////
// Page Header
///////
.page-header {
  padding-top:$spacer-normal + $spacer-xs + 2;
  overflow-y:visible;

  h1 {
    @include grid-area(1, 4, 1, 11);
    text-align:left;
    z-index:1;

    @include respond-between(sm, lg) {
      @include grid-area(1, 3, 1, 11);
    }

    @include respond-below(sm) {
      width:90%;
    }
  }

  img, video {
    width:100%;
    height:auto;
  }

  .content {
    @extend %grid-layout;

    min-height:400px;
  }

  .summary {
    @extend .h2;

    @include grid-area(2, 6, 2, 12);
    z-index:2;
    color:$colour-blue;

    @include respond-below(sm) {
      width:75%;
    }
  }

  .fold-hint {

    //
    display:none;
    //

    position:absolute;
    transform:translateX(-25%);
    z-index:3;
    height:$fold-hint-size;
    width:$fold-hint-size;
    top:calc(80vh - #{$fold-hint-size});
    left:50%;

    @include respond-below(md) {
      top:calc(60vh - #{$fold-hint-size});
    }

    @include respond-below(sm) {
      top:calc(80vh - #{$fold-hint-size})!important;
      transform:translateX(-50%);
    }

    &::after {
      background-size:100%!important;
      height:100%;
      width:100%;
    }
  }
}



///////
// Heros
//////

// NB > CSS Grid can't be used on the image elements as the elements need to extend beyond the grid limits
$hero-width: calc-grid-size(1.25);
$hero-width-narrow: calc-grid-size(2);
$hero-vmargin: 200px;

.hero {
  overflow-x: visible;
  img, video {
    position:absolute;
  }
}

.hero:nth-of-type(1) {

  @include grid-area(1,7,1,13);
  margin-top:$spacer-m - 3;

  img, video {
    width:calc(50vw);
    max-height:50vh;
    object-fit: cover;
  }

  &:not(:only-of-type) {
    @include grid-area(1,8,1,13);

    margin-top:$hero-vmargin;
    margin-bottom:$hero-vmargin / 1.75;

    img, video {
      width:calc(50vw - #{$hero-width});
      right:0;

      @include respond-below(md) {
        width:calc(50vw - #{$hero-width / 2});
      }
    }
  }

  @include respond-between(sm, lg) {
    margin-top:$hero-vmargin / 1.5 + 10;
  }

  @include respond-below(sm) {
    margin-top:0!important;

    img, video {
      width:calc(100% - #{$spacer-normal})!important;
    }
  }
}

.hero:nth-of-type(2) {
  @include grid-area(2, 1, 2, 6);
  @include respond-below(md) {
    @include grid-area(2, 1, 2, 6);
  }

  margin-top:-$hero-vmargin - $spacer-normal;
  // This must be half of the top margin
  // margin-bottom:$hero-vmargin / 2;

  img, video {
    width:calc(50vw - #{$hero-width});
    left:0;
  }

  @include respond-below(md) {
    img, video {
      width:calc(50vw - #{$hero-width / 2});
    }
  }

  @include respond-below(sm) {
    img, video {
      width:calc(100% - #{$spacer-normal})!important;
    }
    margin-top:-$spacer-normal!important;
  }
}
