/*
Blocks
*/

$block-styles: "blue", "grey", "white", "green";
$block-styles-tags: h1,h2,h3,h4,h5,h6,p,li;

/*
Base
*/
.block {

	.top, .content, .bottom {
	  @extend %grid-container;
		@extend %grid-layout;
		padding-left:0;
		padding-right:0;
	}

	.top {
		.inner {
			@include grid-area(1, 1, 1, 8);

			h3:only-child {
				margin-bottom:$spacer-s;
			}

			h3 + .large:last-child {
				margin-bottom:$spacer-xs;
			}

			@include respond-below(lg) {
				@include grid-area(1, 1, 1, 9);
			}
			@include respond-below(md) {
				@include grid-area(1, 1, 1, 10);
			}

			@include respond-above(sm) {
				p:not(.large) {
					width:70%;
				}
			}
		}
	}

	.bottom {
		.inner {
			@include grid-area(1, 4, 1, 10);
			text-align:center;
		}
	}

	.video {
		overflow: hidden;
		video {
			height:100%;
			width:100%;
		}
	}
}

// First block inset. Set on .page-content
.o-inset-first-block {
	position: relative;
	&::before {
		@include absolute-position(true);
		height:$spacer-normal * 2;
	}
	.page-style-green &::before {
		background-color: $colour-green;
	}
	.page-style-blue &::before {
		background-color: $colour-blue;
	}
	.page-style-grey &::before {
		background-color: $colour-grey;
	}
}

/*
Additional bottom padding to manage offset image blocks
*/
.block.o-padding-bottom .content {
	padding-bottom:$spacer-normal * 2!important;
}

/*
Styles
*/
.block.o-style- {
	&blue {
		background-image:linear-gradient(
      $colour-blue, $colour-blue
    );
		@include contrast-colour-set($colour-white, $colour-base);
		@include set-in-block-styles(white, btn-primary, btn-page, btn-sec);
  }
  &white {
		background-image:linear-gradient(
      $colour-white, $colour-white
    );
		@include contrast-colour-set($colour-base, $colour-white);
  }
	&grey {
		background-image:linear-gradient(
      $colour-grey, $colour-grey
    );
		@include contrast-colour-set($colour-base, $colour-white);
  }
	&green {
		background-image:linear-gradient(
      $colour-green, $colour-green
    );
		@include contrast-colour-set($colour-white, $colour-base);
		@include set-in-block-styles(white, btn-primary, btn-page, btn-sec);
  }
	&none {
		@include set-on-page-styles((green, blue)) {
			@include contrast-colour-set($colour-white, $colour-base);
			@include set-in-block-styles(white, btn-primary, btn-page, btn-sec);
		}
	}
}

/*
Offset
*/
.block.o-bkg-offset- {
	&50 {
		background-repeat:no-repeat;
		background-position:bottom;
		background-size:100% 50%;
	}
	&60 {
		background-repeat:no-repeat;
		background-position:40% 100%;
		background-size:100% 60%;
	}
	&70 {
		background-repeat:no-repeat;
		background-position:30% 100%;
		background-size:100% 70%;
	}
}

/*
Margin
*/
.block.o-margin- {
	&bottom {
		margin-bottom:$spacer-normal * 2;
	}
}


/*
Spacing
*/
// - Vertical top and bottom padding space between other modules
.block[class*="o-spacing-"] {
	// Default spacing
	.top {
		padding-top:$spacer-normal;
		@include respond-below(sm) {
      padding-top:$spacer-m;
    }
	}
	.content:first-child:only-child {
		// Is just the content block
		padding:$spacer-normal 0;
		@include respond-below(sm) {
      padding:$spacer-m 0;
    }
	}
	.content:nth-child(2):last-child {
		// Has a top block, but no bottom block
		padding-top:$spacer-m;
		padding-bottom:$spacer-normal;
		@include respond-below(sm) {
      padding-bottom:$spacer-m;
    }
	}
	.content:nth-child(2):not(:last-child) {
		// Has a bottom and top block
		padding-top:$spacer-s;
		padding-bottom:$spacer-m;
	}
	.content:first-child:not(:last-child) {
		// Has a bottom block, but not top block
		padding-top:$spacer-normal;
		@include respond-below(sm) {
      padding-top:$spacer-m;
    }
	}
	.bottom {
		padding-bottom:$spacer-normal;
		@include respond-below(sm) {
      padding-bottom:$spacer-m;
    }
	}
	&.o-spacing-{
		&normal {
			//
		}
		&none {
			.top,
			.content:first-child {
				padding-top:0;
			}
			.bottom,
			.content:first-child {
				padding-bottom:0;
			}
		}
		&none-above {
			.top,
			.content:first-child {
				padding-top:0;
			}
		}
		&none-below {
			.content:last-child,
			.bottom {
				padding-bottom:0;
				*:last-child {
					margin-bottom:0;
				}
			}
		}
	}
}

/*
Sizing
*/
.block.o-size-narrow {
	.top .inner {
		@include grid-area(1, 2, 1, 8);
		@include respond-below(lg) {
			@include grid-area(1, 1, 1, 8);
		}
	}
}

/*
Vertical Offset
*/
// - For adding extra buffer to blocks with .o-verticaloffset
.block.o-pre-verticaloffset {
	padding-bottom:$spacer-normal * 2;
}

/*
Header media
*/
.block .top .image,
.block .top .video {
	@include grid-area(1,8,1,13);
}

.block.o-header-image-offset-top {
	overflow:visible;
	.top .image,
	.top .video {
		// $move: -60%;
		$move: -$spacer-normal * 2;
		transform:translateY($move);
		margin-bottom:$move;

		@include respond-below(sm) {
			margin-bottom:$move + $spacer-m + 10;
		}
	}
}
