a[class*='control-'] {
  @extend .h5;

  @include respond-below(sm) {
    display:none!important;
  }

  color:$colour-orange;
  position:fixed;
  z-index:50;
  top:45vh;
  margin:0;
  text-decoration: none;
  border-left:2px solid $colour-orange;
  text-align: center;
  max-height:300px;
  transition: all $motion-easing-basic!important;

  @include respond-below(md) {
    display:none;
  }

  &.control-next {
    // transform: translateY(-50%) rotate(90deg);
    right:$spacer-s;
    writing-mode:vertical-rl;
    writing-mode:tb-rl;

    @include respond-below(lg) {
      right:$spacer-xs;
    }
    @include respond-below(md) {
      right:$spacer-xs - 4;
      border-left:0;
    }
  }

  &.control-prev {
    transform:rotate(-180deg);
    left:$spacer-s;
    writing-mode:tb;
    writing-mode:vertical-rl;

    @include respond-below(lg) {
      left:$spacer-xs;
    }
    @include respond-below(md) {
      left:$spacer-xs - 4;
      border-left:0;
    }
  }

  &:hover {
    opacity:.5!important;
  }

}
