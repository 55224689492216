/*
Office Thumb
*/
.office-thumb {

  h2 {
    margin-bottom:$spacer-s;
    width:100%;

    a {
      color:$colour-orange;
      text-decoration: none;
      font-family: inherit;
    }
  }

  .inner {
    padding:$spacer-xs + 5 $spacer-s;
    display:flex;
    flex-wrap: wrap;

    @include respond-below(sm) {
      flex-direction: column;
    }
  }

  h2 {
    flex-basis: 100%;
  }

  address {
    display:inline-block;
    width:calc(60% - #{$spacer-m});
    @include respond-below(sm) {
      width:100%;
    }
  }

  .contact {
    margin-left:$spacer-s;
    @include respond-below(sm) {
      margin-left:0;
    }

    .label {
      color:$colour-base!important;
    }

    a {
      display:block;
      margin-bottom:$spacer-s;
      color:$colour-orange;
    }
  }
}
