/*
Animations
*/
$animation-easing-bezier: cubic-bezier(0.19, 1, 0.22, 1);
$transition-d-bezier:     600ms $animation-easing-bezier;
$transition-200-ease:     .2s ease-in-out;
$transition-300-easeout:  .3s ease-out;
$transition-800-bezier:   800ms $animation-easing-bezier;

$animation-forwards:      $animation-easing-bezier 0s 1 forwards;
$animation-backwards:     $animation-easing-bezier 0s 1 reverse;
$animation-nav:           1s $animation-forwards fade-in;
$animation-loop:          linear 0s infinite alternate;

/*****
Global animations
****/
// Header
.site-logo,
.site-nav {
  // opacity:0;
  // animation: fade-in 1s normal forwards ease-in-out;
}
.site-header {
  h1 {
    opacity:0;
    animation: slidedown-and-fade-in 1.5s normal forwards .3s ease-in-out;
  }
  .hero {
    opacity:0;
    animation: fade-in 1.8s normal forwards 1s ease-in-out;
    &:nth-of-type(2) {
      animation: fade-in 1.8s normal forwards 1.7s ease-in-out;
    }
  }
  .summary,
  .introduction {
    opacity:0;
    animation: fade-in 1s normal forwards 1.5s ease-in-out;
  }
  .fold-hint {
    opacity:0;
    animation: slidedown-and-fade-in 1s normal forwards 2s ease-in-out;
  }
}
.control-next {
  opacity:0;
  animation: slidein-from-right 1s normal forwards 2.5s ease-in-out;
}
.control-prev {
  opacity:0;
  animation: slidein-from-left 1s normal forwards 2.5s ease-in-out;
}
.site-header[class*='halo-']::before {
  opacity:0;
  animation: slidedown-and-fade-in 4s normal forwards .5s ease-in-out;
}

// Blocks
// Default animation
.block {
  .content, .top {
    opacity:0;
  }
  &.block-enter {
    .top, .content {
      animation: fade-in .8s normal forwards ease-in-out;
    }
  }
}
// Delayed animation for top of page,
// .page-content .block:not(:only-of-type):first-of-type {
//   .content, .top {
//     opacity:0;
//   }
//   &.block-enter {
//     .top, .content {
//       transform-style: preserve-3d;
//       animation: fade-in .8s normal 3s forwards ease-in-out;
//     }
//   }
// }

// Default fade-in animation
@keyframes fade-in {
  from {
    opacity:0;
  }
  to {
    opacity:1;
  }
}

@keyframes fade-out {
  from {
    opacity:1;
  }
  to {
    opacity:0;
  }
}

// Animation keyframes
@keyframes slideup-and-fade-in {
  from {
    opacity:0;
    transform:translateY(40px);
  }
  to {
    opacity:1;
    transform:translateY(0px);
  }
}

// Slide out
@keyframes slideback-and-fade-out {
  from {
    opacity:1;
  }
  to {
    opacity:0;
    display:none;
  }
}

// Fade halo in
@keyframes slidedown-and-fade-in {
  from {
    opacity:0;
    transform:translateY(-40px);
  }
  to {
    opacity:1;
    transform:translateY(0px);
  }
}


// Slide out to right
@keyframes slideout-to-right {
  from {
    opacity:1;
    transform:translateX(0%);
  }
  to {
    opacity:0;
    transform:translateX(150%);
  }
}

// Slide in from right
@keyframes slidein-from-right {
  from {
    opacity:0;
    transform:translateX(150%);
  }
  to {
    opacity:1;
    transform:translateX(0);
  }
}

// Slide in from left
@keyframes slidein-from-left {
  from {
    opacity:0;
    transform:translateX(-150%);
  }
  to {
    opacity:1;
    transform:translateX(0);
  }
}

// Pulsing
@keyframes loading-pulse {
  to {
    opacity:.7;
  }
  from {
    opacity:1;
  }
}

// Text pulse
@keyframes loading-text-pulse {
  to {
    color:transparentize($colour-white, .7);
  }
  from {
    color:transparentize($colour-white, 0);
  }
}
