/*
Filter
- Used to filter a variety of data interfaces
*/
.filter {
  border-bottom:1px solid $colour-grey-mid;

  &.active {

    .filter-panel {
      display:block;
      animation: fade-in .5s normal forwards ease-in-out;
      transform-style: preserve-3d;
      z-index:100;
    }

    .filter-value {
      &::after {
        @extend .i-close;
      }
      &:hover {
        &::after {
          background-image:url(get-icon(close, "orange"));
          background-size: contain;
        }
      }
    }
  }

  .filter-value {
    width:100%;
    cursor: pointer;

    &.btn-sec::after {
      transform: rotate(90deg) translateX(-50%)!important;
    }
  }

  .filter-panel {
    display:none;
    position:absolute;
    left:0;
    width:100%;
    background:$colour-blue;
    background-color: $colour-blue;
    padding:$spacer-s 0 $spacer-s + 10 0;

    &::before {
      @include absolute-position(true);
      width:200vw;
      background:$colour-blue;
      z-index:-1;
      left:-50vw;

      @include respond-below(md) {
        width:100vw;
        left:-$layout-grid-gap - 5;
      }
    }

    @include respond-below(sm) {
      left:-$spacer-m + 10;
      padding-left:$spacer-s - 10;
    }
  }

  .filter-options {
    display:flex;
    justify-content:flex-end;
    max-width: $layout-content-max-width;
    margin:auto;

    @include respond-below(md) {
      justify-content:center;
    }

    @include respond-below(sm) {
      display:block;
    }

    .small {
      color:transparentize($colour-white, .5);
      padding:$spacer-xs 0;
      line-height:$font-size-m;
      margin-bottom:0!important;
      margin-left:$spacer-s;

      &:not(:first-of-type) {
        margin-top:$spacer-xs;
      }
    }
  }

  .filter-group {
    margin-right:$spacer-normal;
    &[data-cols='2'] .filter-group-options {
      columns: 2;
    }
    &[data-cols='3'] .filter-group-options {
      columns: 3;
    }
    @include respond-below(md) {
      .filter-group-options {
        columns:1!important;
      }
    }
    @include respond-below(sm) {
      &:not(:last-of-type) {
        margin-bottom:$spacer-s;
      }
    }
  }

  a.filter-item {
    display:block;
    position: relative;
    text-decoration: none;
    padding:$spacer-xs / 2 0;
    margin-left:$spacer-s;
    color:$colour-white;
    transition:color $transition-d-bezier;

    &:before {
      $size:20px;
      display:inline-block;
      margin-right:$spacer-xs;
      transform: translateY(4px);
      content:"";
      height:$size;
      width:$size;
      border-radius:50%;
      transition:all $transition-d-bezier;
      border:1px solid rgba(255,255,255,.4);
      background:rgba(255,255,255,.3);
    }

    &:hover,
    &.active {

      color:$colour-orange;
      &::before {
        border:1px solid transparentize($colour-orange, .4);
        background:transparentize($colour-orange, .3);
      }
    }
    &.active::before {
      background:$colour-orange;
    }
  }
}
