/*
Four Column
*/
.block-four-column {

  ul,ol {
    columns: 1;
  }

  .content {
    @extend %grid-layout;
    figure + {
      & h2, & h3, & h4 {
        margin-top:$spacer-s;
      }
    }
  }

  .top {
    padding-bottom:$spacer-m;
  }

  /*
  Options
  */
  &.o-alignment-center {
    .introduction,
    .column {
      text-align: center;
    }
  }

  /*
  Grid
  */
  &.o-size- {
    &normal {
      .column {
        @include grid-area(1, auto, 1, span 3);
        @include target-ie {
          padding-right:$layout-grid-gap;
          $span: 3;
          @for $i from 1 through 4 {
            &:nth-of-type(#{$i}) {
              @include grid-area(1, #{($i - 1) * $span + 1}, 1, #{$i * $span + 1});
            }
          }
        }
      }
    }
    &narrow {
      .top .inner {
        @include grid-area(1, 2, 1, 7);
      }
      $cols: 4;
      @for $i from 1 through $cols {
        .column:nth-of-type(#{$i}) {
          @include grid-area(1, #{2 + (($i - 1) * 2)}, 1, span 2);
        }
      }
    }
    @include respond-between(sm,md) {
      &normal, &narrow {
        .column {
          @include grid-area(auto, auto, auto, span 6);
          margin-bottom:$spacer-m;
        }
      }
    }
  }
}
