.overlay.person {

  display:block;
  z-index:300;

  >  .outer {
    margin-left:auto;
    min-width:900px;
    width:80vw;

    @include respond-below(lg) {
      min-width:auto;
      width:90vw;
    }

    @include respond-below(sm) {
      min-width:auto;
      width:100vw;
    }
  }

  .inner {
    padding-bottom:$spacer-normal;

    @include respond-below(sm) {
      padding:$spacer-s $spacer-s $spacer-normal $spacer-s;
    }
  }

  .close {
    background-image:url(get-icon(close-circle, "orange"));
    &:hover {
      background-image:url(get-icon(close-circle, "dark"));
    }
  }

  // HIDING UNTIL QUERY IS RESOLVED
  a[class*='control-'] {
    display:none;
  }

  a[class*='control-prev'] {
    left:$spacer-s + 10;
  }

  .portrait {
    @include grid-area(1,5,3,11);
    margin-bottom:$spacer-m;
  }

  .details {
    @include grid-area(1,3,3,11);
    position:relative;
    z-index:1;
    margin-top:$spacer-normal * 2;

    @include respond-below(sm) {
      margin-top:$spacer-m;
    }
  }

  .team {
    color:$colour-orange;
    @include respond-above(sm) {
      margin-bottom:$spacer-normal;
    }
  }

  .name {
    margin-bottom:$spacer-s;
  }

  .summary {
    @include grid-area(3,5,3,10);
  }

  .insights {
    @include grid-area(5,1,5,11);
    @extend %grid-overlay-person;

    margin-top:$spacer-m;
    background:$colour-white;

    &:empty {
      display:none;
    }
  }

  .statement {
    @extend .h2;
    @include grid-area(1,2,1,10);
    margin:$spacer-normal 0;
  }

  .interest {
    @include grid-area(auto,4,auto,10);

    &:last-of-type {
      margin-bottom:$spacer-normal;
    }

    .question {
      @extend .h3;
      margin:$spacer-m 0 $spacer-s 0;
      color:$colour-orange;
      width:75%;
    }
  }

  .investments {
    @include grid-area(6,2,6,10);
    text-align: center;
    padding-top:$spacer-normal;

    h3 {
      @include grid-area(1,2,1,11);
    }

    .carousel {
      @include grid-area(2,2,2,10);
      position:relative;
      @include respond-below(sm) {
        margin-top:$spacer-normal + 20;
      }
    }

    .carousel-control {
      position:absolute;
      top:0;
      right:0;
      transform: translateY(calc(-100% - #{$spacer-s}));

      &[role="Previous"] {
        transform:translateY(calc(-100% - #{$spacer-s})) scaleX(-1)!important;
        right:$spacer-normal + $spacer-s;
        @include respond-below(sm) {
          right:auto;
          left:0;
        }
      }
    }

    .carousel-pane.active {
      display:flex;
      animation: slideup-and-fade-in 3s normal forwards $animation-easing-bezier;

      @include respond-below(sm) {
        flex-wrap: wrap;
      }
      &.hide {
        animation: fade-out .5s normal forwards $animation-easing-bezier;
      }
    }

    .carousel-pagination {
      width:66%;
      margin-top:$spacer-m;

      @include respond-below(sm) {
        margin-top:$spacer-xs;
        width:100%;
        margin-left:auto;
        margin-right:auto;
      }
    }

    .investment-thumb {
      width:calc(100% / 3);

      @include respond-below(sm) {
        width:100%;
        margin-right:0!important;
        margin-bottom:$layout-grid-gap;
      }

      &:not(:last-of-type) {
        margin-right:$layout-grid-gap;
      }
      @include respond-below(sm) {
        width:100%;
        margin-right:0!important;
        margin-bottom:$layout-grid-gap;
      }
    }

    .btn-primary {
      margin-top:$spacer-m;
    }
  }


  .news {
    @include grid-area(7,2,7,10);
    text-align:center;
    padding-top:$spacer-normal;

    .articles {
      display:flex;
      justify-content: space-between;
    }

    .news-article-thumb {
      width:calc(50% - #{$layout-grid-gap / 2});
    }

    .btn-primary {
      margin-top:$spacer-m;
    }
  }
}
