/*
News Article Thumbnail
*/
.news-article-thumb {
  display:flex;

  // Styles
  .o-style-blue &,
  &.o-style-white {
    .inner {
      background-color:$colour-white!important;
    }
    &.no-image .inner {
      background-image:url($file-path-media + "shapes/shape-g-green.svg");
    }
    .title, .category, .date {
      color:$colour-blue!important;
    }
  }

  &.o-style-green {
    .inner {
      background-color:$colour-green!important;
    }
    &.no-image .inner {
      background-image:url($file-path-media + "shapes/shape-g-blue.svg");
    }
    .title, .category, .date {
      color:$colour-white!important;
    }
    a:hover {
      // color:$colour-blue;
      .title, .category, .date {
        // color:$colour-blue!important;
      }
    }
  }

  &.no-image {
    .inner {
      padding-top:206px;
      background-image:url($file-path-media + "shapes/shape-g-orange.svg");
      background-repeat: no-repeat;
      background-position:50px 0;
      background-size:110%;
    }
  }

  a {
    flex:1;
    display:flex;
    flex-direction: column;
    text-decoration:none;
    transition:
      box-shadow $motion-easing-basic,
      transform $motion-easing-basic;

    @include target-ie() {
      display:block;
    }

    .title, .category, .date {
      transition:all .5s $animation-easing-bezier;
    }

    &:hover {
      color:inherit;
      @include sexy-box-shadow($colour-orange);
      transform:$transform-lift;

      .title, .category, .date {
        color:$colour-orange!important;
        opacity:1;
      }
    }
  }

  .image {
    img {
      display:block;
    }
  }

  .inner {
    position:relative;
    background:$colour-blue;
    height:100%;
    padding:$spacer-s;
    padding-bottom:$spacer-m;
  }

  .title {
    @extend .h2;
    color:$colour-white!important;
  }

  .category, .date {
    @extend .small;

    opacity:.5;
    color:$colour-white;
    bottom:$spacer-s;
    position:absolute;
    margin-bottom:0;
  }

  .category {
    text-align:right;
    right:$spacer-s;
  }

  .date {
    text-transform:uppercase;
  }
}
