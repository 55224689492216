/*
Investment Thumbnail
*/
.investment-thumb {
  display: flex;

  a {
    flex: 1;
    display: flex;
    flex-direction: column;
    text-decoration: none;
    transition: box-shadow $motion-easing-basic, transform $motion-easing-basic;

    @include target-ie {
      display: block;
      height: auto !important;
    }

    .title,
    .desc,
    .sector,
    .invested,
    .status,
    .goals {
      transition: all 0.5s $animation-easing-bezier;
    }

    &:hover {
      @include sexy-box-shadow($colour-orange);
      transform: $transform-lift;
      color: inherit;

      @include target-ie {
        // Remove hover animation in IE
        transform: none;
      }

      .title,
      .desc,
      .sector,
      .status {
        color: $colour-orange;
        opacity: 1;
      }
      .invested,
      .goals {
        color: $colour-orange;
      }
    }
  }

  .image {
    img {
      display: block;
    }
  }

  .inner {
    display: flex;
    flex-direction: column;
    position: relative;
    background: $colour-white;
    padding: $spacer-xs;
    padding-bottom: $spacer-normal - 10;
    flex: 1;
    justify-content: flex-start;

    @include target-ie {
      height: auto !important;
    }
  }

  .title {
    @extend .h2;
    margin-bottom: $spacer-xs;
    flex-shrink: 1;
  }

  .invested,
  .goals {
    opacity: 0.5;
  }

  .sdg-wrapper {
    margin-top: auto;
  }

  .sdg-goals {
    $spacer: $spacer-s + 20;
    $gap: $spacer * 2 / 3;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    // justify-content: space-between;

    @include respond-below(sm) {
      justify-content: flex-start;
    }

    .image {
      width: calc(33% - #{$gap});
      margin-right: $spacer;
      &:nth-of-type(3n) {
        margin-right: 0;
      }
      &:nth-of-type(n + 4) {
        margin-top: $spacer-xs;
      }
      @include respond-below(sm) {
        width: calc(33% - #{$gap});
        max-width: 70px;
      }
    }
  }

  .sector,
  .status {
    @extend .small;

    color: $colour-blue;
    position: absolute;
    bottom: $spacer-xs;
    margin-bottom: 0;

    @include respond-below(sm) {
      bottom: $spacer-xs;
    }
  }

  .status {
    left: $spacer-xs;
    text-transform: uppercase;

    @include respond-between(sm, md) {
      bottom: $spacer-m - 8;
    }

    @include respond-below(sm) {
      left: $spacer-xs;
    }
  }

  .sector {
    text-align: right;
    right: $spacer-xs;

    @include respond-between(sm, md) {
      right: auto;
      left: $spacer-xs;
    }

    @include respond-below(sm) {
      right: $spacer-s;
    }
  }

  // Option to not show thumb content
  // Keep data in DOM just hide
  &[data-show-information="false"] {
    a:hover {
      // Override box-shadow to avoid showing DIV outline
      box-shadow: none;
      // Put on the image instead
      .image {
        @include sexy-box-shadow($colour-orange);
        transform: $transform-lift;
      }
    }

    .desc,
    .status,
    .invested,
    .sdg-wrapper,
    .sector {
      display: none;
    }

    .image {
      height: auto;
      transition: box-shadow $motion-easing-basic,
        transform $motion-easing-basic;
    }

    .inner {
      background: none;
      padding-bottom: $spacer-s;
    }
  }
}
