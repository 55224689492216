.fund-thumb {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 100%;
  justify-content: flex-start;

  padding: 0 $spacer-m $spacer-normal 0;
  margin-right: $spacer-m;

  &.active {
    .fund-figure {
      color: $colour-orange;
    }

    .fund-graphic::before {
      background: $colour-orange;
      opacity: 0.6;
      top: 25%;
      height: 75%;
    }
  }

  .fund-year {
    margin-bottom: $spacer-xs;
    color: $colour-orange !important;

    &.line {
      overflow: visible;

      &::after {
        background: $colour-orange !important;
        width: calc(100%);
      }
    }
  }

  .fund-title {
    margin-bottom: $spacer-m;
    min-height: 72px;
  }

  .fund-figure {
    @extend .h1;
    margin: 0;
    transition: 0.3s color $motion-easing-basic;
  }

  .fund-bottom {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    // outline:1px solid blue;
    justify-content: flex-start;

    .fund-phase {
      margin-top: $spacer-m;
      margin-bottom: $spacer-s;
      // flex-grow: 1;
    }

    .fund-management {
      opacity: 0.5;
    }

    .fund-more,
    .fund-resource {
      margin-top: $spacer-s;
      -webkit-transform-style: preserve-3d;
      -webkit-backface-visibility: hidden;
      margin-right: 0;
    }


    .fund-resources {
      margin-top: $spacer-s /2;
    }

    .fund-resource {
      @extend %i-arrow;
      color: white;
      text-decoration: none;
      font-size: $font-size-s;
      padding-right: 30px;
      display: block;

      &::after {
        transition: background $motion-easing-basic;
        background-image: url(get-icon(arrow, "light"));
      }

      &:hover {
        color: $colour-orange;

        &::after {
          background-image: url(get-icon(arrow, "orange"));
        }
      }
    }
  }

  .fund-graphic-wrapper {
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;

    -webkit-transform-style: preserve-3d;
    -webkit-backface-visibility: hidden;
  }

  .fund-label {
    color: white;
    position: absolute;
    text-align: center;
    top: calc(50% - 65px);
    text-transform: uppercase;
  }

  .fund-graphic {
    // height:300px;
    // outline:1px solid red;

    -webkit-transform-style: preserve-3d;
    -webkit-backface-visibility: hidden;

    cursor: pointer;
    background: $colour-blue;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    width: calc(var(--fund-size) * 4);
    min-width: 30%;

    position: relative;
    transform-style: preserve-3d;
    transition: all 0.6s $motion-easing-basic;

    @include respond-below(sm) {
      width: calc(10% + var(--fund-size));
    }

    &::after {
      content: "";
      display: block;
      padding-top: 100%;
    }

    &::before {
      @include absolute-position(true);

      transition: all 0.5s $motion-easing-basic;
      right: $spacer-m;
      left: 25%;
      width: 80%;
      height: 100%;
      bottom: 0;
      transform: translateZ(-1px);
      background: $colour-black;
      background-blend-mode: multiply;
      opacity: 0.4;
      border-radius: 100%;
      filter: blur(13px);
    }

    &::after {
      transition: filter $motion-easing-basic;
    }

    &:hover {
      border-color: transparent;
      color: $colour-orange;

      .fund-figure {
        color: $colour-orange;
      }
    }
  }
}