/*
Funds Timeline
*/
.block.block-funds-timeline {

  position:relative;
  overflow-x: hidden;

  .content {
    display:block;
  }

  .top {
    margin-bottom:$spacer-m;
    position:relative;
  }

  // Timeline
  .timeline {
    display:flex;
    flex-direction: row;
    flex-wrap: nowrap;
    width:max-content;
    overflow-y: visible;
  }

  .fund-thumb {
    flex:1;
    max-width:300px;
    width:300px;
    height:auto;

    @include respond-below(sm) {
      $width: calc(100vw - #{$spacer-m});
      width:$width;
      max-width:$width;
    }
  }

  .fund-thumb:first-of-type {
    padding-left:0;
  }

  .btn-page {
    position:absolute;
    bottom:$spacer-m;
    cursor: pointer;

    @include respond-below(sm) {
      position:static;
    }

    &[role="Next"] {
      right:0;
    }
    &[role="Previous"] {
      right:$spacer-normal + $spacer-m;
      transform: scaleX(-1)!important;
    }
  }

  .controls {
    @include respond-below(sm) {
      margin:0 auto;
      display:flex;
      justify-content: space-between;
      margin-right:$spacer-s;
    }
  }


  // Fund Investments
  .investments {
    display:none;
    background-color:$colour-orange;
    background-image:linear-gradient(to bottom, rgba(0,0,0,.3) 0%, rgba(0,0,0,0) 15%);
    text-align: center;
    padding-bottom:$spacer-m;
    margin-left:-50vw;
    margin-right:-50vw;

    @include respond-below(md) {
      background:$colour-orange;
      margin-left:auto;
      margin-right:auto;
    }

    &.active {
      display:block;
    }

    .btn-primary {
      margin-bottom:$spacer-m;
      background:$colour-orange;

      &:hover {
        border-color:$colour-blue;
        color:$colour-blue;
        &::after {
          background-image:url(get-icon(arrow, "dark"));
        }
      }
    }
  }

  .investments-inner {
    position:relative;
    padding:$spacer-normal;
    padding-bottom:0;
    max-width:1200px;
    margin:0 auto;

    @include respond-below(md) {
      padding:$spacer-normal $spacer-m $spacer-m $spacer-m;
    }

    @include respond-below(sm) {
      padding:$spacer-m $spacer-s $spacer-s $spacer-s;
    }

    &::before {
      content:"";
      display:block;
      height:47px;
      width:47px;
      background:$colour-blue;
      position:absolute;
      top:0;
      left:50%;
      transform: translateX(-75%) translateY(-70%) rotate(45deg);
    }

    &::after {
      display:block;
      z-index:-1;
      content:"";
      position:absolute;
      transform: translateX(-50vw);
      top:0;
      height:calc(100% + #{$spacer-normal} * 2);
      width:200vw;
      background:$colour-orange;
    }
  }

  .fund-investments {
    display:none;
    &.active {
      display:flex;
    }
    flex-wrap:wrap;
    align-content:center;
    justify-content:center;
    padding-bottom:$spacer-normal;

    @include respond-between(sm, md) {
      justify-content: space-between;
    }

    @include respond-below(sm) {
      padding-top:$spacer-m;
    }
  }

  .fund-investment {
    $width: 300px;
    display:inline-block;
    position:relative;
    width:$width;
    height:calc((#{$width}/16)*9);
    background:$colour-white;
    text-decoration: none;
    margin-right:$spacer-m;
    margin-bottom:$spacer-normal;

    &:nth-of-type(even) {
      margin-right:$spacer-m / 1.5;
    }

    @include respond-between(sm, md) {
      width:calc(50% - #{$layout-grid-gap});
      margin-right:0!important;
    }

    @include respond-below(sm) {
      width:calc(100%);
      margin-right:0!important;
    }

    figure {
      padding:$spacer-m;
      position:absolute;
      top:50%;
      transform: translateY(-50%);
      text-align: center;

      img {
        max-height:calc((#{$width}/16)*8);
        &[data-orientation="portrait"] {
          max-height:calc(((#{$width}/16)*8) - #{$spacer-m});
          width:auto;
          margin:0 auto;
        }
      }
    }

    .status {
      position:absolute;
      bottom:-$spacer-s - 10;
      color:$colour-white;
      // text-transform: uppercase;
      padding-top:$spacer-xs;
    }
  }
}
