/*
Mixins and Functions
*/

@function to-string($list, $glue: '', $is-nested: false) {
  $result: null;

  @for $i from 1 through length($list) {
    $e: nth($list, $i);

    @if type-of($e) == list {
      $result: $result#{to-string($e, $glue, true)};
    } @else {
      $result: if(
        $i != length($list) or $is-nested,
        $result#{$e}#{$glue},
        $result#{$e}
      );
    }
  }
  @return $result;
}


//////////
// Grid
//////////

/*
Calulate grid size equivalent
*/
@function calc-grid-size($noCols) {
  $colWidth: $layout-content-max-width / 12 * $noCols;
  $colWidth: $colWidth + (($noCols - 2) * $layout-grid-gap);
  @return $colWidth;
}

/*
Generate long-form grid CSS to help autoprefixer
*/
@mixin grid-area($rowStart, $colStart, $rowEnd, $colEnd, $important: false) {
	$imp: '';
	@if $important == true {
		$imp: '!important';
	}
	grid-column-start: $colStart#{$imp};
	grid-column-end: $colEnd#{$imp};
	grid-row-start: $rowStart#{$imp};
	grid-row-end: $rowEnd#{$imp};
}


/*
Set column areas
*/
@mixin set-column-areas($args...) {
	$i : 1;
	@each $area in $args {
		.column:nth-child(#{$i}) {
			grid-area: unquote($area);
		}
		$i: $i+1;
	}
}

/*
Set a column to vertically center it's child elements via FlexBox
*/
@mixin vertical-center() {
	display:flex;
	flex:1;
	flex-direction:column;
	justify-content: center;
}

///////
// Aesthetic, visual fx-gradient
//////

@mixin sexy-box-shadow($colour : $colour-orange) {
  box-shadow:
    0px 6.4px 7.6px -26px transparentize($colour, 1-0.047),
    15px 11.2px 14.1px -26px transparentize($colour, 1-0.098),
    10px 15.2px 19.4px -26px transparentize($colour, 1-0.15),
    15px 19.1px 24px -26px transparentize($colour, 1-0.196),
    20px 25px 29px -26px transparentize($colour, 1-0.227),
    25px 44px 39px -26px transparentize($colour, 1-0.24)
  ;
}

/*
Used for applying specific styles based off the .page-style-<value> definition
- Typically used for light on dark contrast
*/
@mixin set-on-page-styles($styles) {
	@each $name in $styles {
		.page-style-#{$name} & {
			@content;
		}
	}
}

/*
Used for applying specific styles to block level elements
- Typically used for light on dark contrast
*/
@mixin set-in-block-styles($styleName, $args...) {
	@each $class in $args {
		.#{$class} {
			@extend .#{$class}.o-#{$styleName};
		}
	}
}

/*
Short-hand for applying specific styles to display within styled blocks
*/
@mixin contrast-colour-set($foreground, $background) {
	td, p, li, h1, h2, h3, h4, h5, blockquote, cite {
		color:$foreground;
	}
}

/*
Add glow to element
*/
@mixin add-glow($colour) {
	box-shadow: 0 0 8px -2px $colour;
}



/////////
// Links and buttons
////////
/*
Add custom underline
*/
@mixin link-underline($colour) {
  text-decoration:none;
  background:
   linear-gradient(
     to bottom, #{$colour} 0%,
     #{$colour} 100%
   );
  background-position: 0 100%;
  background-repeat: repeat-x;
  background-size: 100% 2px;
  &:hover {
    background-size:100% 100%;
  }
}



/////////
// DOM Helpers
/////////
/*
Apply standard absolute position syntax
*/
@mixin absolute-position($pseudo) {
	@if $pseudo == true {
		display:block;
		content:"";
	}
	position:absolute;
	top:0;
	left:0;
	right:0;
	bottom:0;
}

/*
Remove focus highlight on specific fields
*/
@mixin remove-focus-highlight() {
	&:focus{
		outline: none;
	}
}




//////////
// Imagery
//////////
/*
Image cropping by ratio configuration
*/
@mixin image-crop-ratio($ratio) {
	&[data-ratio='#{$ratio}'] {
		@content;
	}
}

//////////
// Fonts
//////////
/*
Embed fontface
@author https://github.com/magnetikonline/sass-boilerplate/blob/master/fontface.scss
*/
@mixin fontFace($family, $src, $style: normal, $weight: normal) {
	@font-face {
		font-family: $family;
		src: url('#{$src}/eot/#{$family}.eot'); // IE9 compat
		src: url('#{$src}/eot/#{$family}.eot?#iefix') format('embedded-opentype'), // IE8 and below
			url('#{$src}/woff/#{$family}.woff') format('woff'), // standards
      url('#{$src}/woff2/#{$family}.woff2') format('woff2'), // standards
			url('#{$src}/ttf/#{$family}.ttf') format('truetype');

		font-style: $style;
		font-weight: $weight;
	}
}




//////////
// Browser targeting
//////////
@mixin target-ie {
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    @content;
  }
}

@mixin target-edge {
  @supports (-ms-ime-align: auto) {
    @content;
  }
}

@mixin target-firefox {
  @at-root {
    @-moz-document url-prefix() {
      & {
        @content;
      }
    }
  }
}
