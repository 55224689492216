/*
Video
*/
.block-video {
  @extend .o-bkg-offset-50;
  .content {
    > div {
      @include grid-area(1,1,1,13);
    }
  }
}
