.page-error {
  background:$colour-grey;
  display:flex;
  justify-content: center;
  align-content: center;
  align-items: center;

  background-image:url($file-path-media + "shapes/shape-g-orange.svg");
  background-repeat: no-repeat;
  background-position: center;

  .error-message {
    min-width:300px;
    width:45vw;
    background:$colour-white;
    padding:$spacer-m + 10;
    opacity:0;
    animation: slideup-and-fade-in 4s $animation-easing-bezier .5s forwards;

    @include respond-below(lg){
      width:65vw;
    }

    @include respond-below(md){
      width:80vw;
    }

    .site-logo {
      display:block;
      width:120px;
      margin-bottom:$spacer-s;
    }
  }
}
