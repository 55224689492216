/*
Grid
*/

$layout-grid-gap:               25px;
$layout-content-max-width:  		1200px;
$layout-page-gutter:   				  $spacer-normal;

///////
// Normalise
//////
html, body { height: 100%; margin: 0; }

///////
// Grid Container
//////
%grid-container,
.grid-container {
	width:calc(100% - #{$spacer-normal * 2});
	max-width:$layout-content-max-width;
	margin-left:auto;
	margin-right:auto;

	@include respond-above(sm) {
		padding-left:$layout-grid-gap;
		padding-right:$layout-grid-gap;
	}

  @include respond-below(lg) {
		width:calc(100% - #{$spacer-normal});
  }

	@include respond-below(md) {
		width:calc(100% - #{$spacer-normal - 20});
  }
}

///////
// Grid Layout
// - Used on a module basis, versus across a whole page
//////
%grid-layout {
	@include respond-above(sm) {
		display: grid;
		grid-template-columns: repeat(12, 1fr);
		grid-template-rows: auto;
		// grid-auto-rows: auto;
		grid-auto-flow: row;
		grid-column-gap: $layout-grid-gap;
		grid-row-gap: 0px;
	}
}


%grid-overlay-person {
	@include respond-above(sm) {
		display:grid;
		grid-template-columns: repeat(10, 1fr);
		grid-template-rows: auto;
		grid-auto-flow: row;
		grid-column-gap: $layout-grid-gap;
		grid-row-gap: 0px;
	}
}
