/*
Footer
*/

$semi-opacity:.5;

////////////
// Styles
////////////

/*
Wrapper
*/
.site-footer {
  color:$colour-blue;
  background:$colour-white;
  padding-top:$spacer-normal + $spacer-m;
  position:relative;

  @include respond-below(sm) {
    display:flex;
    flex-direction: column;
    padding-top:$spacer-s;
  }
}

.site-footer-top,
.site-footer-middle {
  @extend %grid-container;
  @extend %grid-layout;

  p:not(.h1):not(.xsmall):not(.stat-definition), a {
    @extend .small;
  }
}


/*
Top
*/
.site-footer-top {
  align-content:space-between;

  @include respond-below(sm) {
    order:2;
    margin-bottom:$spacer-m;
  }

  // Grid configuration
  .stat-carousel {
    @include grid-area(1, 1, 1, 3);
    @include respond-below(sm) {
      margin-bottom:$spacer-normal;
      width:30%;
    }

    .stat-pane {
      @include respond-below(sm) {
        // display:flex;
      }
      &.active {
        animation: fade-in 1s normal forwards ease-in-out;
      }
    }

    .stat-figure {
      margin-bottom:$spacer-m;
    }

    .stat-definition {
      font-size:rem(18px);
    }
  }

  .footer-nav-link {
    margin-bottom:3px;
    a {
      @extend .h4;
      text-decoration: none;
    }
  }

  .footer-col-one {
    @include grid-area(1, 5, 1, 7);
    @include respond-below(lg) {
      @include grid-area(1, 5, 1, 8);
    }
    @include respond-below(md) {
      @include grid-area(1, 5, 1, 10);
    }
  }

  .footer-col-two {
    @include grid-area(1, 7, 1, 9);
    @include respond-below(lg) {
      @include grid-area(1, 9, 1, 12);
    }
    @include respond-below(md) {
      margin-top:$spacer-m;
      @include grid-area(2, 5, 2, 12);
    }
  }

  .footer-col-three {
    @include grid-area(1, 9, 1, 11);
    @include respond-below(lg) {
      margin-top:$spacer-normal;
      @include grid-area(2, 9, 2, 12);
    }
    @include respond-below(md) {
      margin-top:$spacer-m;
      @include grid-area(3, 5, 3, 13);
    }
  }

  .footer-follow {
    @include grid-area(1, 11, 1, 13);
    @include respond-below(lg) {
      margin-top:$spacer-normal;
      @include grid-area(2, 5, 2, 8);
    }
    @include respond-below(md) {
      margin-top:0;
      @include grid-area(1, 9, 1, 13);
    }
    a {
      display:block;
      width:max-content;
      text-decoration:none;
      margin-bottom:$spacer-xs/2;
    }
  }

  .legaltext {
    @include grid-area(2, 5, 2, 13);
    @include respond-below(lg) {
      @include grid-area(3, 5, 3, 13);
    }
    @include respond-below(md) {
      @include grid-area(4, 5, 4, 13);
    }
    @include respond-below(sm) {
      border-top:1px solid $colour-grey-mid;
      padding-top:$spacer-m;
    }

    opacity:$semi-opacity;
    margin-top:$spacer-m;
  }

}

/*
Middle
*/
.site-footer-middle {

  margin-top:$spacer-m;
  margin-bottom:$spacer-s;

  @include respond-below(sm) {
    order:1;
  }

  .site-logo {
    @include grid-area(1, 1, 1, 3);

    width:75%;
    margin-bottom:0;

    @include respond-below(sm) {
      width:auto;
      background-position: top left;
      margin-bottom:$spacer-m;
    }
  }

  .footer-nav {
    @include grid-area(1, 5, 1, 13);
    margin-top:5px;

    @include respond-below(sm) {
      margin-left:-$spacer-xs;
      margin-bottom:$spacer-m;
    }

    a {
      padding:$spacer-xs;
      opacity:$semi-opacity;
      white-space: nowrap;

      &:hover {
        opacity:1;
      }

      @include respond-above(sm) {
        &:first-of-type {
          margin-left:-$spacer-xs;
        }
      }
    }
  }
}


/*
Bottom
*/
.site-footer-bottom {

  @include respond-below(md) {
    // background:none;
    margin-top:0;
    order:3;
  }

  background:$colour-orange;
  padding:$spacer-s - 5 0;
  color:$colour-white;

  .copyright {
    @extend %grid-container;

    color:$colour-white;
    font-size:$font-size-s;
    opacity:$semi-opacity;
    margin-bottom:0;
  }
}
