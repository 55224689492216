/*
Globals
*/
/*
Functions
*/
/*
Variables
*/
/*
Globals
*/
/*
Colours
@see http://chir.ag/projects/name-that-color/
*/
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;1,300;1,400;1,500&display=swap");
:root {
  --colour-black:   #000000;
  --colour-white:   #ffffff;
  --colour-grey:    #E7EDEC;
  --colour-orange:  #ED5436;
  --colour-blue:    #1D314D;
  --colour-red:     #c0c0c0;
  --colour-green:   #5A8080; }

/*
Spacing
*/
/*
Breakpoints
*/
/*
Motion
*/
/*
Style
*/
/*
Effects
*/
/*
Mixins and Functions
*/
/*
Calulate grid size equivalent
*/
/*
Generate long-form grid CSS to help autoprefixer
*/
/*
Set column areas
*/
/*
Set a column to vertically center it's child elements via FlexBox
*/
/*
Used for applying specific styles based off the .page-style-<value> definition
- Typically used for light on dark contrast
*/
/*
Used for applying specific styles to block level elements
- Typically used for light on dark contrast
*/
/*
Short-hand for applying specific styles to display within styled blocks
*/
/*
Add glow to element
*/
/*
Add custom underline
*/
/*
Apply standard absolute position syntax
*/
/*
Remove focus highlight on specific fields
*/
/*
Image cropping by ratio configuration
*/
/*
Embed fontface
@author https://github.com/magnetikonline/sass-boilerplate/blob/master/fontface.scss
*/
*,
*::before,
*::after {
  box-sizing: border-box; }

a {
  text-decoration: none;
  color: inherit;
  cursor: pointer; }

button {
  background-color: transparent;
  color: inherit;
  border-width: 0;
  padding: 0;
  cursor: pointer; }

figure {
  margin: 0; }

input::-moz-focus-inner {
  border: 0;
  padding: 0;
  margin: 0; }

ul, ol, dd {
  margin: 0;
  padding: 0;
  list-style: none; }

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  font-size: inherit;
  font-weight: inherit; }

p {
  margin: 0; }

cite {
  font-style: normal; }

fieldset {
  border-width: 0;
  padding: 0;
  margin: 0; }

/*
Animations
*/
/*****
Global animations
****/
.site-header h1 {
  opacity: 0;
  animation: slidedown-and-fade-in 1.5s normal forwards .3s ease-in-out; }

.site-header .hero {
  opacity: 0;
  animation: fade-in 1.8s normal forwards 1s ease-in-out; }
  .site-header .hero:nth-of-type(2) {
    animation: fade-in 1.8s normal forwards 1.7s ease-in-out; }

.site-header .summary,
.site-header .introduction {
  opacity: 0;
  animation: fade-in 1s normal forwards 1.5s ease-in-out; }

.site-header .fold-hint {
  opacity: 0;
  animation: slidedown-and-fade-in 1s normal forwards 2s ease-in-out; }

.control-next {
  opacity: 0;
  animation: slidein-from-right 1s normal forwards 2.5s ease-in-out; }

.control-prev {
  opacity: 0;
  animation: slidein-from-left 1s normal forwards 2.5s ease-in-out; }

.site-header[class*='halo-']::before {
  opacity: 0;
  animation: slidedown-and-fade-in 4s normal forwards .5s ease-in-out; }

.block .content, .block .top {
  opacity: 0; }

.block.block-enter .top, .block.block-enter .content {
  animation: fade-in .8s normal forwards ease-in-out; }

@keyframes fade-in {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fade-out {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@keyframes slideup-and-fade-in {
  from {
    opacity: 0;
    transform: translateY(40px); }
  to {
    opacity: 1;
    transform: translateY(0px); } }

@keyframes slideback-and-fade-out {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    display: none; } }

@keyframes slidedown-and-fade-in {
  from {
    opacity: 0;
    transform: translateY(-40px); }
  to {
    opacity: 1;
    transform: translateY(0px); } }

@keyframes slideout-to-right {
  from {
    opacity: 1;
    transform: translateX(0%); }
  to {
    opacity: 0;
    transform: translateX(150%); } }

@keyframes slidein-from-right {
  from {
    opacity: 0;
    transform: translateX(150%); }
  to {
    opacity: 1;
    transform: translateX(0); } }

@keyframes slidein-from-left {
  from {
    opacity: 0;
    transform: translateX(-150%); }
  to {
    opacity: 1;
    transform: translateX(0); } }

@keyframes loading-pulse {
  to {
    opacity: .7; }
  from {
    opacity: 1; } }

@keyframes loading-text-pulse {
  to {
    color: rgba(255, 255, 255, 0.3); }
  from {
    color: white; } }

/*
Breakpoints
*/
/*
Grid
*/
html, body {
  height: 100%;
  margin: 0; }

.block .top, .block .content, .block .bottom, .site-footer-top,
.site-footer-middle, .site-footer-bottom .copyright, .site-header .inner,
.grid-container {
  width: calc(100% - 160px);
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto; }
  @media (min-width: 768px) {
    .block .top, .block .content, .block .bottom, .site-footer-top,
    .site-footer-middle, .site-footer-bottom .copyright, .site-header .inner,
    .grid-container {
      padding-left: 25px;
      padding-right: 25px; } }
  @media (max-width: 1199px) {
    .block .top, .block .content, .block .bottom, .site-footer-top,
    .site-footer-middle, .site-footer-bottom .copyright, .site-header .inner,
    .grid-container {
      width: calc(100% - 80px); } }
  @media (max-width: 991px) {
    .block .top, .block .content, .block .bottom, .site-footer-top,
    .site-footer-middle, .site-footer-bottom .copyright, .site-header .inner,
    .grid-container {
      width: calc(100% - 60px); } }

@media (min-width: 768px) {
  .block-four-column .content, .block.block-investment-grid .top .inner, .block-investment-promoter .content, .block-jobs-promoter .content, .block.block-news-grid .top .inner, .block-one-column.o-size-narrow .content, .block.block-team-grid .top .inner, .block-three-column .content, .block.block-timeline .content, .block-two-column .content, .block .top, .block .content, .block .bottom, .site-footer-top,
  .site-footer-middle, .site-header-top, .page-header .content {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: auto;
    grid-auto-flow: row;
    grid-column-gap: 25px;
    grid-row-gap: 0px; } }

@media (min-width: 768px) {
  .overlay > .outer > .inner, .overlay.person .insights {
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    grid-template-rows: auto;
    grid-auto-flow: row;
    grid-column-gap: 25px;
    grid-row-gap: 0px; } }

/*
Typography
*/
/*
Import fonts
*/
@font-face {
  font-family: "BwGradual-Regular";
  src: url("/assets/media/fonts/bw-gradual//eot/BwGradual-Regular.eot");
  src: url("/assets/media/fonts/bw-gradual//eot/BwGradual-Regular.eot?#iefix") format("embedded-opentype"), url("/assets/media/fonts/bw-gradual//woff/BwGradual-Regular.woff") format("woff"), url("/assets/media/fonts/bw-gradual//woff2/BwGradual-Regular.woff2") format("woff2"), url("/assets/media/fonts/bw-gradual//ttf/BwGradual-Regular.ttf") format("truetype");
  font-style: normal;
  font-weight: normal; }

/*
Baseline definitions
*/
html, body {
  font-size: 16px;
  color: #1D314D; }

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }
  * ::selection {
    color: #ffffff;
    background: #ed5436; }

/*
Auto-overwrite
*/
h6 {
  font-family: "Roboto", arial, sans-serif;
  color: #1D314D;
  text-align: left;
  line-height: 1.5em; }

h5 {
  font-family: "Roboto", arial, sans-serif;
  color: #1D314D;
  text-align: left;
  line-height: 1.5em; }

h4 {
  font-family: "Roboto", arial, sans-serif;
  color: #1D314D;
  text-align: left;
  line-height: 1.5em; }

h3 {
  font-family: "Roboto", arial, sans-serif;
  color: #1D314D;
  text-align: left;
  line-height: 1.5em; }

p {
  font-family: "Roboto", arial, sans-serif;
  color: #1D314D;
  text-align: left;
  line-height: 1.5em; }

a {
  font-family: "Roboto", arial, sans-serif;
  color: #1D314D;
  text-align: left;
  line-height: 1.5em; }

button {
  font-family: "Roboto", arial, sans-serif;
  color: #1D314D;
  text-align: left;
  line-height: 1.5em; }

li {
  font-family: "Roboto", arial, sans-serif;
  color: #1D314D;
  text-align: left;
  line-height: 1.5em; }

td {
  font-family: "Roboto", arial, sans-serif;
  color: #1D314D;
  text-align: left;
  line-height: 1.5em; }

th {
  font-family: "Roboto", arial, sans-serif;
  color: #1D314D;
  text-align: left;
  line-height: 1.5em; }

nav {
  font-family: "Roboto", arial, sans-serif;
  color: #1D314D;
  text-align: left;
  line-height: 1.5em; }

input {
  font-family: "Roboto", arial, sans-serif;
  color: #1D314D;
  text-align: left;
  line-height: 1.5em; }

label {
  font-family: "Roboto", arial, sans-serif;
  color: #1D314D;
  text-align: left;
  line-height: 1.5em; }

select {
  font-family: "Roboto", arial, sans-serif;
  color: #1D314D;
  text-align: left;
  line-height: 1.5em; }

textarea {
  font-family: "Roboto", arial, sans-serif;
  color: #1D314D;
  text-align: left;
  line-height: 1.5em; }

blockquote {
  font-family: "Roboto", arial, sans-serif;
  color: #1D314D;
  text-align: left;
  line-height: 1.5em; }

cite {
  font-family: "Roboto", arial, sans-serif;
  color: #1D314D;
  text-align: left;
  line-height: 1.5em; }

span {
  font-family: "Roboto", arial, sans-serif;
  color: #1D314D;
  text-align: left;
  line-height: 1.5em; }

div {
  font-family: "Roboto", arial, sans-serif;
  color: #1D314D;
  text-align: left;
  line-height: 1.5em; }

/*
Text styles (sans colour)
*/
a {
  text-decoration: underline;
  transition: color 600ms cubic-bezier(0.19, 1, 0.22, 1); }
  a:hover {
    color: #ED5436;
    text-decoration: none; }
  a[rel="external"] {
    padding-right: 20px; }
    a[rel="external"]::after {
      background-image: url(get-icon(arrow, "orange"));
      right: 0 !important;
      width: 18px !important;
      height: 18px !important;
      background-size: contain; }
  a:not(.btn-primary)[href*="@"] {
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-all;
    word-break: break-word;
    hyphens: auto; }

h1,
.h1,
.fund-thumb .fund-figure {
  font-family: "BwGradual-Regular", arial, sans-serif;
  font-size: 3.125rem;
  font-weight: 400;
  letter-spacing: 0.7px;
  line-height: 3.9375rem;
  color: #ED5436;
  margin-bottom: 80px; }
  @media (max-width: 767px) {
    h1,
    .h1,
    .fund-thumb .fund-figure {
      font-size: 2.5rem;
      line-height: 3.125rem; } }

h2,
.h2,
p.large,
.normal.large,
.field,
.form-quick-search input.large.search-input,
.block.block-team-grid .top .inner .toggle-team a.large,
.quote blockquote,
.news-article-thumb .title,
.investment-thumb .title,
.block.block-image-gallery .carousel-cell .title,
.page-header .summary,
.overlay.person .statement {
  font-family: "BwGradual-Regular", arial, sans-serif;
  font-size: 1.6875rem;
  font-weight: 400;
  letter-spacing: 0.63px;
  line-height: 2.25rem;
  margin-bottom: 40px; }

h3,
.h3,
.quote blockquote cite,
.site-header.type-investment h1[data-status]::before,
.overlay.person .interest .question {
  font-size: 1.1875rem;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 0.7px;
  line-height: 1.875rem;
  margin-bottom: 40px; }

h4,
.h4,
address.contact .name,
.site-footer-top .footer-nav-link a {
  font-size: 1.1875rem;
  font-weight: 400;
  line-height: 1.6875rem;
  letter-spacing: 0;
  margin-bottom: 20px; }

h5,
.h5,
a[class*='control-'] {
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: 0;
  color: #ED5436 !important;
  margin-bottom: 10px; }

h6,
.h6 {
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: 0;
  margin-bottom: 20px; }

p,
.normal,
.form-quick-search input.search-input,
.block.block-team-grid .top .inner .toggle-team a {
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 1.5rem;
  margin-bottom: 20px; }
  p.medium,
  .normal.medium,
  .form-quick-search input.medium.search-input,
  .block.block-team-grid .top .inner .toggle-team a.medium {
    font-family: "BwGradual-Regular", arial, sans-serif;
    font-size: 1.1875rem; }
  p.large.highlight,
  .normal.large.highlight,
  .highlight.field,
  .form-quick-search input.large.highlight.search-input,
  .block.block-team-grid .top .inner .toggle-team a.large.highlight {
    color: #ED5436 !important;
    margin-top: 70px;
    margin-bottom: 0; }

b,
.heavy {
  font-weight: 500; }

@media (max-width: 1199px) {
  ol,
  ul {
    columns: 1 !important; } }

ol + h2,
ol h3,
ol h4,
ol h5,
ul + h2,
ul h3,
ul h4,
ul h5 {
  margin-top: 20px; }

ul {
  list-style: none;
  list-style-position: inside;
  margin-top: 40px;
  padding: 0; }
  ul.no-cols li {
    display: block; }
  ul:not(.no-cols) {
    columns: 2;
    break-inside: avoid-column; }
    ul:not(.no-cols) li {
      break-inside: avoid-column; }
  ul li {
    margin: 0 0 10px 20px;
    position: relative;
    padding-left: 10px;
    display: inline-block; }
  ul li::before {
    content: "\2022";
    display: inline-block;
    font-size: 40px;
    margin: 0 20px 0 -34px;
    transform: translateX(5px) translateY(10px);
    color: #ED5436; }

h3 + ul,
h3 ol,
h4 + ul,
h4 ol,
h5 + ul,
h5 ol {
  margin-top: 10px; }

address {
  font-style: normal; }

small,
.small,
.back-to-top::before,
.news-article-thumb .category,
.news-article-thumb .date,
.investment-thumb .sector,
.investment-thumb .status,
.site-footer-top p:not(.h1):not(.xsmall):not(.stat-definition),
.site-footer-top a,
.site-footer-middle p:not(.h1):not(.xsmall):not(.stat-definition),
.site-footer-middle a {
  font-size: 0.875rem;
  line-height: 1.3125rem;
  letter-spacing: 0;
  margin-bottom: 20px; }

.xsmall {
  font-size: 0.75rem;
  letter-spacing: 0;
  line-height: 1.125rem;
  margin-bottom: 0; }

.label, .btn-inline-search {
  font-weight: 400;
  margin-bottom: 20px; }

.line {
  display: block;
  color: #ED5436 !important;
  position: relative;
  width: calc(100% - 20px);
  overflow-x: hidden;
  margin-bottom: 20px; }
  .o-style-blue .line,
  .o-style-green .line {
    color: #E7EDEC !important; }
    .o-style-blue .line::after,
    .o-style-green .line::after {
      background: #E7EDEC; }
  .line::after {
    opacity: 0.7;
    display: inline;
    background: #ED5436;
    content: "";
    height: 2px;
    width: inherit;
    position: absolute;
    top: 45%;
    transform: translateX(20px); }

.figure-large {
  font-size: 4.6875rem;
  font-weight: 300;
  line-height: 5rem; }

.stat-figure {
  font-size: 3.125rem;
  font-weight: 400;
  color: #ED5436 !important; }
  @media (min-width: 768px) and (max-width: 1199px) {
    .stat-figure {
      font-size: 6vw !important; } }
  @media (max-width: 767px) {
    .stat-figure {
      font-size: 17vw !important; } }

@media (min-width: 992px) {
  .link, .nav-item a {
    color: #1D314D;
    position: relative; }
    .link::after, .nav-item a::after {
      position: absolute;
      content: "";
      display: block;
      bottom: 3px;
      left: 10px;
      right: 10px;
      height: 1px;
      width: calc(100% - (10px * 2));
      border: 1px solid #ED5436;
      opacity: 1;
      transition: all 600ms cubic-bezier(0.19, 1, 0.22, 1); } }

.nav-item .nav-sub-menu-link {
  font-family: "Roboto", arial, sans-serif;
  font-size: 1.3em !important; }

@media (min-width: 992px) {
  .nav-item a {
    font-family: "Roboto", arial, sans-serif;
    font-weight: 500;
    font-size: 0.875rem;
    letter-spacing: 0.52px;
    line-height: 23px;
    color: #1D314D; } }

@media (max-width: 991px) {
  .nav-item a {
    font-family: "BwGradual-Regular", arial, sans-serif;
    font-size: 1.6875rem;
    color: #ffffff;
    text-decoration: none; } }

blockquote:not([class]) {
  color: rgba(237, 84, 54, 0.7) !important;
  padding: 0;
  margin-left: 0 !important; }
  blockquote:not([class])::before {
    content: "";
    display: block;
    height: 60px;
    width: 85px;
    margin: 20px 0;
    background: url("/assets/media/graphics/quotes-orange.svg") no-repeat top left;
    background-size: contain; }

.quote.o-large blockquote {
  font-size: 2.5rem;
  line-height: 1.1em; }
  .quote.o-large blockquote cite {
    font-size: 1rem; }

.quote blockquote {
  margin: 0;
  quotes: none;
  opacity: 1; }
  .quote blockquote::before {
    content: "";
    display: block;
    height: 150px;
    width: 210px;
    background: url("/assets/media/graphics/quotes-orange.svg") no-repeat top left;
    background-size: auto;
    margin-bottom: 0;
    position: relative;
    top: 65px;
    left: -95px; }
  .quote blockquote cite {
    display: block;
    margin-top: 40px;
    color: #ED5436; }

hr {
  margin: 40px 0 40px 0;
  border: 0;
  height: 1px;
  background: transparent;
  background-image: linear-gradient(to right, #E7EDEC, #E7EDEC); }

.site-logo {
  background: transparent url("/assets/media/logos/logo-blue.svg") no-repeat center center;
  background-size: contain;
  display: block;
  width: 178px;
  height: 48.31429px; }
  @media (max-width: 767px) {
    .site-logo {
      width: 128px;
      height: 34.74286px; } }
  .site-logo.o-light {
    background-image: url("/assets/media/logos/logo-white.svg"); }
  .site-header .site-logo {
    grid-column-start: 1;
    grid-column-end: 4;
    grid-row-start: 1;
    grid-row-end: 1; }

/*
Icon
*/
.i-arrow, .block.block-accordian .accordian-title {
  background-size: contain; }
  .i-arrow::after, .block.block-accordian .accordian-title::after, .i-arrow::after.o-dark, .block.block-accordian .accordian-title::after.o-dark {
    background: url("/assets/media/graphics/arrow-dark.svg") no-repeat center center; }
  .i-arrow::after.o-light, .block.block-accordian .accordian-title::after.o-light {
    background-image: url("/assets/media/graphics/arrow-light.svg"); }
  .i-arrow::after.o-orange, .block.block-accordian .accordian-title::after.o-orange {
    background-image: url("/assets/media/graphics/arrow-orange.svg"); }

.i-close-circle {
  background-size: contain; }
  .i-close-circle, .i-close-circle.o-dark {
    background: url("/assets/media/graphics/close-circle-dark.svg") no-repeat center center; }
  .i-close-circle.o-light {
    background-image: url("/assets/media/graphics/close-circle-light.svg"); }
  .i-close-circle.o-orange {
    background-image: url("/assets/media/graphics/close-circle-orange.svg"); }

.i-close, .filter.active .filter-value::after {
  background-size: contain; }
  .i-close, .filter.active .filter-value::after, .i-close.o-dark, .filter.active .o-dark.filter-value::after {
    background: url("/assets/media/graphics/close-dark.svg") no-repeat center center; }
  .i-close.o-light, .filter.active .o-light.filter-value::after {
    background-image: url("/assets/media/graphics/close-light.svg"); }
  .i-close.o-orange, .filter.active .o-orange.filter-value::after {
    background-image: url("/assets/media/graphics/close-orange.svg"); }

.i-search, .form-quick-search::before {
  background-size: contain; }
  .i-search, .form-quick-search::before, .i-search.o-dark, .o-dark.form-quick-search::before, .page-style-blue .nav-search a:hover, .page-style-green .nav-search a:hover {
    background: url("/assets/media/graphics/search-dark.svg") no-repeat center center; }
  .i-search.o-light, .o-light.form-quick-search::before, .page-style-blue .nav-search a, .page-style-green .nav-search a {
    background-image: url("/assets/media/graphics/search-light.svg"); }
  .i-search.o-orange, .o-orange.form-quick-search::before, .nav-search a:hover {
    background-image: url("/assets/media/graphics/search-orange.svg"); }

.i-user {
  background-size: contain; }
  .i-user, .i-user.o-dark, .page-style-blue .nav-member a:hover, .page-style-green .nav-member a:hover {
    background: url("/assets/media/graphics/user-dark.svg") no-repeat center center; }
  .i-user.o-light, .page-style-blue .nav-member a, .page-style-green .nav-member a {
    background-image: url("/assets/media/graphics/user-light.svg"); }
  .i-user.o-orange, .nav-member a:hover {
    background-image: url("/assets/media/graphics/user-orange.svg"); }

.i-arrow-circle {
  background-size: contain; }
  .i-arrow-circle, .i-arrow-circle.o-dark {
    background: url("/assets/media/graphics/arrow-circle-dark.svg") no-repeat center center; }
  .i-arrow-circle.o-light {
    background-image: url("/assets/media/graphics/arrow-circle-light.svg"); }
  .i-arrow-circle.o-orange {
    background-image: url("/assets/media/graphics/arrow-circle-orange.svg"); }

.i-point {
  background-size: contain; }
  .i-point, .i-point.o-dark {
    background: url("/assets/media/graphics/point-dark.svg") no-repeat center center; }
  .i-point.o-light {
    background-image: url("/assets/media/graphics/point-light.svg"); }
  .i-point.o-orange {
    background-image: url("/assets/media/graphics/point-orange.svg"); }

.i-menu {
  background-size: contain; }
  .i-menu, .i-menu.o-dark {
    background: url("/assets/media/graphics/menu-dark.svg") no-repeat center center; }
  .i-menu.o-light, .page-style-green .nav-small-screen-button,
  .page-style-blue .nav-small-screen-button {
    background-image: url("/assets/media/graphics/menu-light.svg"); }
  .i-menu.o-orange {
    background-image: url("/assets/media/graphics/menu-orange.svg"); }

.is-mob {
  background-image: url(/assets/media/graphics/arrow-light.svg), url(/assets/media/graphics/arrow-dark.svg), url(/assets/media/graphics/arrow-orange.svg), url(/assets/media/graphics/close-circle-light.svg), url(/assets/media/graphics/close-circle-dark.svg), url(/assets/media/graphics/close-circle-orange.svg), url(/assets/media/graphics/close-light.svg), url(/assets/media/graphics/close-dark.svg), url(/assets/media/graphics/close-orange.svg), url(/assets/media/graphics/search-light.svg), url(/assets/media/graphics/search-dark.svg), url(/assets/media/graphics/search-orange.svg), url(/assets/media/graphics/user-light.svg), url(/assets/media/graphics/user-dark.svg), url(/assets/media/graphics/user-orange.svg), url(/assets/media/graphics/arrow-circle-light.svg), url(/assets/media/graphics/arrow-circle-dark.svg), url(/assets/media/graphics/arrow-circle-orange.svg), url(/assets/media/graphics/point-light.svg), url(/assets/media/graphics/point-dark.svg), url(/assets/media/graphics/point-orange.svg), url(/assets/media/graphics/menu-light.svg), url(/assets/media/graphics/menu-dark.svg), url(/assets/media/graphics/menu-orange.svg); }

.i-arrow, .block.block-accordian .accordian-title,
a[rel="external"],
a.btn-primary,
p.btn-primary > a:only-child,
.btn-sec,
.fund-thumb .fund-bottom .fund-resource {
  position: relative;
  /*
  Variations
  */ }
  .i-arrow::after, .block.block-accordian .accordian-title::after,
  a[rel="external"]::after,
  a.btn-primary::after,
  p.btn-primary > a:only-child::after,
  .btn-sec::after,
  .fund-thumb .fund-bottom .fund-resource::after {
    content: "";
    position: absolute;
    display: inline-block;
    height: 20px;
    width: 20px;
    right: 13px;
    top: calc(30%);
    background-image: url("/assets/media/graphics/arrow-orange.svg");
    background-repeat: no-repeat; }
  .i-arrow.o-no-arrow, .block.block-accordian .o-no-arrow.accordian-title,
  a.o-no-arrow[rel="external"],
  a.o-no-arrow.btn-primary,
  p.btn-primary > a.o-no-arrow:only-child,
  .o-no-arrow.btn-sec,
  .fund-thumb .fund-bottom .o-no-arrow.fund-resource {
    padding-right: 37px; }
    .i-arrow.o-no-arrow::after, .block.block-accordian .o-no-arrow.accordian-title::after,
    a.o-no-arrow[rel="external"]::after,
    a.o-no-arrow.btn-primary::after,
    p.btn-primary > a.o-no-arrow:only-child::after,
    .o-no-arrow.btn-sec::after,
    .fund-thumb .fund-bottom .o-no-arrow.fund-resource::after {
      display: none; }
  .i-arrow.o-down::after, a[href*=".pdf"][class*="btn-"]::after, a[href*=".doc"][class*="btn-"]::after, a[href*=".png"][class*="btn-"]::after, a[href*=".zip"][class*="btn-"]::after, a[href*=".jpg"][class*="btn-"]::after, a[href*=".pptx"][class*="btn-"]::after, .block.block-accordian .accordian-title::after,
  a.o-down[rel="external"]::after,
  .block.block-accordian a.accordian-title[rel="external"]::after,
  a.o-down.btn-primary::after,
  .block.block-accordian a.btn-primary.accordian-title::after,
  p.btn-primary > a.o-down:only-child::after,
  .block.block-accordian p.btn-primary > a.accordian-title:only-child::after,
  .o-down.btn-sec::after,
  .block.block-accordian .btn-sec.accordian-title::after,
  .fund-thumb .fund-bottom .o-down.fund-resource::after,
  .fund-thumb .fund-bottom .block.block-accordian .fund-resource.accordian-title::after,
  .block.block-accordian .fund-thumb .fund-bottom .fund-resource.accordian-title::after {
    transform: rotate(90deg) translateX(-50%);
    top: 50%; }
  .i-arrow.o-up::after, .block.block-accordian .accordian-section.active .accordian-title::after, .block.block-accordian .o-up.accordian-title::after,
  a.o-up[rel="external"]::after,
  .block.block-accordian .accordian-section.active a.accordian-title[rel="external"]::after,
  a.o-up.btn-primary::after,
  .block.block-accordian .accordian-section.active a.btn-primary.accordian-title::after,
  p.btn-primary > a.o-up:only-child::after,
  .block.block-accordian .accordian-section.active p.btn-primary > a.accordian-title:only-child::after,
  .o-up.btn-sec::after,
  .block.block-accordian .accordian-section.active .btn-sec.accordian-title::after,
  .fund-thumb .fund-bottom .o-up.fund-resource::after,
  .fund-thumb .fund-bottom .block.block-accordian .accordian-section.active .fund-resource.accordian-title::after,
  .block.block-accordian .accordian-section.active .fund-thumb .fund-bottom .fund-resource.accordian-title::after {
    transform: rotate(-90deg) translateX(50%);
    top: calc(50% - 1px); }
  .i-arrow.o-up-right::after, .block.block-accordian .o-up-right.accordian-title::after,
  a.o-up-right[rel="external"]::after,
  a.o-up-right.btn-primary::after,
  p.btn-primary > a.o-up-right:only-child::after,
  .o-up-right.btn-sec::after,
  .fund-thumb .fund-bottom .o-up-right.fund-resource::after {
    transform: rotate(-45deg) translateX(50%);
    top: calc(50% - 1px); }
  .i-arrow.o-plus::after, .block.block-accordian .o-plus.accordian-title::after,
  a.o-plus[rel="external"]::after,
  a.o-plus.btn-primary::after,
  p.btn-primary > a.o-plus:only-child::after,
  .o-plus.btn-sec::after,
  .fund-thumb .fund-bottom .o-plus.fund-resource::after {
    background-image: url("/assets/media/graphics/plus-dark.svg"); }
    .i-arrow.o-plus::after.o-light, .block.block-accordian .o-plus.accordian-title::after.o-light,
    a.o-plus::after.o-light[rel="external"],
    a.o-plus.btn-primary::after.o-light,
    p.btn-primary > a.o-plus::after.o-light:only-child,
    .o-plus.btn-sec::after.o-light,
    .fund-thumb .fund-bottom .o-plus.fund-resource::after.o-light {
      background-image: url("/assets/media/graphics/plus-light.svg"); }
    .i-arrow.o-plus::after.o-orange, .block.block-accordian .o-plus.accordian-title::after.o-orange,
    a.o-plus::after.o-orange[rel="external"],
    a.o-plus.btn-primary::after.o-orange,
    p.btn-primary > a.o-plus::after.o-orange:only-child,
    .o-plus.btn-sec::after.o-orange,
    .fund-thumb .fund-bottom .o-plus.fund-resource::after.o-orange {
      background-image: url("/assets/media/graphics/plus-orange.svg"); }

.i-point {
  height: 32px;
  width: 19px; }
  .i-point.o-down, .block.block-accordian .i-point.accordian-title {
    transform: rotate(90deg); }

/*
Buttons
*/
[class*='btn-'].btn-inline {
  display: inline-block;
  margin-right: 20px; }

/*
Primary Buttons
*/
a.btn-primary,
p.btn-primary > a:only-child {
  text-decoration: none;
  display: inline-block;
  font-weight: 400;
  letter-spacing: .6px;
  padding: 10px 60px 10px 15px;
  border-right: 1px solid #1D314D;
  border-bottom: 1px solid #1D314D;
  margin-right: 40px;
  background: #ffffff;
  transform-style: preserve-3d;
  transition: all 0.3s ease-in-out; }
  @media (max-width: 767px) {
    a.btn-primary:only-of-type,
    p.btn-primary > a:only-child:only-of-type {
      margin-right: 0; } }
  a.btn-primary::before,
  p.btn-primary > a:only-child::before {
    display: block;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    right: -10px;
    bottom: -10px;
    left: 25%;
    width: 80%;
    height: 50%;
    top: 22px;
    transform: translateZ(-1px);
    background: #ED5436;
    opacity: 0;
    border-radius: 50px;
    transition: all 0.3s ease-in-out;
    filter: blur(10px); }
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      a.btn-primary::before,
      p.btn-primary > a:only-child::before {
        display: none; } }
  a.btn-primary::after,
  p.btn-primary > a:only-child::after {
    transition: background 0.3s ease-in-out;
    background-image: url("/assets/media/graphics/arrow-dark.svg"); }
  a.btn-primary:hover, a.btn-primary.o-white:hover, .block.o-style-blue a.btn-primary:hover, .block.o-style-green a.btn-primary:hover, .page-style-green .block.o-style-none a.btn-primary:hover, .page-style-blue .block.o-style-none a.btn-primary:hover, a.btn-primary.o-white.active, .block.o-style-blue a.active.btn-primary, .block.o-style-green a.active.btn-primary, .page-style-green .block.o-style-none a.active.btn-primary, .page-style-blue .block.o-style-none a.active.btn-primary,
  p.btn-primary > a.o-white:only-child:hover,
  p.btn-primary > a.o-white.active:only-child, a.btn-primary.o-grey:hover, a.btn-primary.o-grey.active,
  p.btn-primary > a.o-grey:only-child:hover,
  p.btn-primary > a.o-grey.active:only-child, a.btn-primary.o-orange:hover, a.btn-primary.o-orange.active,
  p.btn-primary > a.o-orange:only-child:hover,
  p.btn-primary > a.o-orange.active:only-child, a.btn-primary.active, a.btn-primary.loading,
  p.btn-primary > a:only-child:hover,
  p.btn-primary > a:only-child.active,
  p.btn-primary > a:only-child.loading {
    border-color: transparent;
    color: #ED5436; }
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      a.btn-primary:hover, a.btn-primary.o-white:hover, .block.o-style-blue a.btn-primary:hover, .block.o-style-green a.btn-primary:hover, .page-style-green .block.o-style-none a.btn-primary:hover, .page-style-blue .block.o-style-none a.btn-primary:hover, a.btn-primary.o-white.active, .block.o-style-blue a.active.btn-primary, .block.o-style-green a.active.btn-primary, .page-style-green .block.o-style-none a.active.btn-primary, .page-style-blue .block.o-style-none a.active.btn-primary,
      p.btn-primary > a.o-white:only-child:hover,
      p.btn-primary > a.o-white.active:only-child, a.btn-primary.o-grey:hover, a.btn-primary.o-grey.active,
      p.btn-primary > a.o-grey:only-child:hover,
      p.btn-primary > a.o-grey.active:only-child, a.btn-primary.o-orange:hover, a.btn-primary.o-orange.active,
      p.btn-primary > a.o-orange:only-child:hover,
      p.btn-primary > a.o-orange.active:only-child, a.btn-primary.active, a.btn-primary.loading,
      p.btn-primary > a:only-child:hover,
      p.btn-primary > a:only-child.active,
      p.btn-primary > a:only-child.loading {
        background-color: #ED5436 !important;
        color: #ffffff; } }
    a.btn-primary:hover:before, a.btn-primary.o-white:hover:before, .block.o-style-blue a.btn-primary:hover:before, .block.o-style-green a.btn-primary:hover:before, .page-style-green .block.o-style-none a.btn-primary:hover:before, .page-style-blue .block.o-style-none a.btn-primary:hover:before, a.btn-primary.o-white.active:before, .block.o-style-blue a.active.btn-primary:before, .block.o-style-green a.active.btn-primary:before, .page-style-green .block.o-style-none a.active.btn-primary:before, .page-style-blue .block.o-style-none a.active.btn-primary:before,
    p.btn-primary > a.o-white:hover:only-child:before,
    p.btn-primary > a.o-white.active:only-child:before, a.btn-primary.o-grey:hover:before, a.btn-primary.o-grey.active:before,
    p.btn-primary > a.o-grey:hover:only-child:before,
    p.btn-primary > a.o-grey.active:only-child:before, a.btn-primary.o-orange:hover:before, a.btn-primary.o-orange.active:before,
    p.btn-primary > a.o-orange:hover:only-child:before,
    p.btn-primary > a.o-orange.active:only-child:before, a.btn-primary.active:before, a.btn-primary.loading:before,
    p.btn-primary > a:only-child:hover:before,
    p.btn-primary > a:only-child.active:before,
    p.btn-primary > a:only-child.loading:before {
      opacity: .6;
      height: 75%; }
    a.btn-primary:hover::after, a.btn-primary.o-white:hover::after, .block.o-style-blue a.btn-primary:hover::after, .block.o-style-green a.btn-primary:hover::after, .page-style-green .block.o-style-none a.btn-primary:hover::after, .page-style-blue .block.o-style-none a.btn-primary:hover::after, a.btn-primary.o-white.active::after, .block.o-style-blue a.active.btn-primary::after, .block.o-style-green a.active.btn-primary::after, .page-style-green .block.o-style-none a.active.btn-primary::after, .page-style-blue .block.o-style-none a.active.btn-primary::after,
    p.btn-primary > a.o-white:hover:only-child::after,
    p.btn-primary > a.o-white.active:only-child::after, a.btn-primary.o-grey:hover::after, a.btn-primary.o-grey.active::after,
    p.btn-primary > a.o-grey:hover:only-child::after,
    p.btn-primary > a.o-grey.active:only-child::after, a.btn-primary.o-orange:hover::after, a.btn-primary.o-orange.active::after,
    p.btn-primary > a.o-orange:hover:only-child::after,
    p.btn-primary > a.o-orange.active:only-child::after, a.btn-primary.active::after, a.btn-primary.loading::after,
    p.btn-primary > a:only-child:hover::after,
    p.btn-primary > a:only-child.active::after,
    p.btn-primary > a:only-child.loading::after {
      background-image: url("/assets/media/graphics/arrow-orange.svg"); }
  a.btn-primary.loading::before,
  p.btn-primary > a:only-child.loading::before {
    animation: loading-pulse 2s infinite alternate; }
  .page-style-blue .block.o-style-none a.btn-primary,
  .page-style-blue .site-header.type-investment a.btn-primary,
  .block.o-style-blue a.btn-primary, .page-style-blue .block.o-style-none
  p.btn-primary > a:only-child,
  .page-style-blue .site-header.type-investment
  p.btn-primary > a:only-child,
  .block.o-style-blue
  p.btn-primary > a:only-child {
    background: #1D314D; }
  .page-style-grey .block.o-style-none a.btn-primary,
  .block.o-style-grey a.btn-primary, .page-style-grey .block.o-style-none
  p.btn-primary > a:only-child,
  .block.o-style-grey
  p.btn-primary > a:only-child {
    background: #E7EDEC; }
  .page-style-green .block.o-style-none a.btn-primary,
  .block.o-style-green a.btn-primary, .page-style-green .block.o-style-none
  p.btn-primary > a:only-child,
  .block.o-style-green
  p.btn-primary > a:only-child {
    background: #5A8080; }
    .page-style-green .block.o-style-none a.btn-primary::before,
    .block.o-style-green a.btn-primary::before, .page-style-green .block.o-style-none
    p.btn-primary > a:only-child::before,
    .block.o-style-green
    p.btn-primary > a:only-child::before {
      background-color: #1D314D; }
    .page-style-green .block.o-style-none a.btn-primary:hover, .page-style-green .block.o-style-none a.active.btn-primary,
    .page-style-green .block.o-style-none p.btn-primary > a.o-white:only-child:hover,
    .page-style-green .block.o-style-none p.btn-primary > a.o-white.active:only-child,
    .page-style-green .block.o-style-none p.btn-primary > a.o-grey:only-child:hover,
    .page-style-green .block.o-style-none p.btn-primary > a.o-grey.active:only-child,
    .page-style-green .block.o-style-none p.btn-primary > a.o-orange:only-child:hover,
    .page-style-green .block.o-style-none p.btn-primary > a.o-orange.active:only-child,
    .block.o-style-green a.btn-primary:hover,
    .block.o-style-green a.active.btn-primary,
    .block.o-style-green p.btn-primary > a.o-white:only-child:hover,
    .block.o-style-green p.btn-primary > a.o-white.active:only-child,
    .block.o-style-green p.btn-primary > a.o-grey:only-child:hover,
    .block.o-style-green p.btn-primary > a.o-grey.active:only-child,
    .block.o-style-green p.btn-primary > a.o-orange:only-child:hover,
    .block.o-style-green p.btn-primary > a.o-orange.active:only-child, .page-style-green .block.o-style-none
    p.btn-primary > a:only-child:hover,
    .block.o-style-green
    p.btn-primary > a:only-child:hover {
      color: #1D314D; }
      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        .page-style-green .block.o-style-none a.btn-primary:hover, .page-style-green .block.o-style-none a.active.btn-primary,
        .page-style-green .block.o-style-none p.btn-primary > a.o-white:only-child:hover,
        .page-style-green .block.o-style-none p.btn-primary > a.o-white.active:only-child,
        .page-style-green .block.o-style-none p.btn-primary > a.o-grey:only-child:hover,
        .page-style-green .block.o-style-none p.btn-primary > a.o-grey.active:only-child,
        .page-style-green .block.o-style-none p.btn-primary > a.o-orange:only-child:hover,
        .page-style-green .block.o-style-none p.btn-primary > a.o-orange.active:only-child,
        .block.o-style-green a.btn-primary:hover,
        .block.o-style-green a.active.btn-primary,
        .block.o-style-green p.btn-primary > a.o-white:only-child:hover,
        .block.o-style-green p.btn-primary > a.o-white.active:only-child,
        .block.o-style-green p.btn-primary > a.o-grey:only-child:hover,
        .block.o-style-green p.btn-primary > a.o-grey.active:only-child,
        .block.o-style-green p.btn-primary > a.o-orange:only-child:hover,
        .block.o-style-green p.btn-primary > a.o-orange.active:only-child, .page-style-green .block.o-style-none
        p.btn-primary > a:only-child:hover,
        .block.o-style-green
        p.btn-primary > a:only-child:hover {
          color: #ffffff; } }
      .page-style-green .block.o-style-none a.btn-primary:hover::after, .page-style-green .block.o-style-none a.active.btn-primary::after,
      .page-style-green .block.o-style-none p.btn-primary > a.o-white:hover:only-child::after,
      .page-style-green .block.o-style-none p.btn-primary > a.o-white.active:only-child::after,
      .page-style-green .block.o-style-none p.btn-primary > a.o-grey:hover:only-child::after,
      .page-style-green .block.o-style-none p.btn-primary > a.o-grey.active:only-child::after,
      .page-style-green .block.o-style-none p.btn-primary > a.o-orange:hover:only-child::after,
      .page-style-green .block.o-style-none p.btn-primary > a.o-orange.active:only-child::after,
      .block.o-style-green a.btn-primary:hover::after,
      .block.o-style-green a.active.btn-primary::after,
      .block.o-style-green p.btn-primary > a.o-white:hover:only-child::after,
      .block.o-style-green p.btn-primary > a.o-white.active:only-child::after,
      .block.o-style-green p.btn-primary > a.o-grey:hover:only-child::after,
      .block.o-style-green p.btn-primary > a.o-grey.active:only-child::after,
      .block.o-style-green p.btn-primary > a.o-orange:hover:only-child::after,
      .block.o-style-green p.btn-primary > a.o-orange.active:only-child::after, .page-style-green .block.o-style-none
      p.btn-primary > a:only-child:hover::after,
      .block.o-style-green
      p.btn-primary > a:only-child:hover::after {
        background-image: url("/assets/media/graphics/arrow-dark.svg"); }
  a.btn-primary.o-white, .block.o-style-blue a.btn-primary, .block.o-style-green a.btn-primary, .page-style-green .block.o-style-none a.btn-primary, .page-style-blue .block.o-style-none a.btn-primary,
  p.btn-primary > a:only-child.o-white {
    border-color: #ffffff;
    color: #ffffff; }
    a.btn-primary.o-white::after, .block.o-style-blue a.btn-primary::after, .block.o-style-green a.btn-primary::after, .page-style-green .block.o-style-none a.btn-primary::after, .page-style-blue .block.o-style-none a.btn-primary::after,
    p.btn-primary > a:only-child.o-white::after {
      background-image: url("/assets/media/graphics/arrow-light.svg"); }
  a.btn-primary.o-grey,
  p.btn-primary > a:only-child.o-grey {
    border-color: #1D314D;
    color: #1D314D;
    background: #E7EDEC; }
    a.btn-primary.o-grey::after,
    p.btn-primary > a:only-child.o-grey::after {
      background-image: url("/assets/media/graphics/arrow-dark.svg"); }
  a.btn-primary.o-orange,
  p.btn-primary > a:only-child.o-orange {
    border-color: #ED5436;
    color: #ED5436; }
    a.btn-primary.o-orange::after,
    p.btn-primary > a:only-child.o-orange::after {
      background-image: url("/assets/media/graphics/arrow-orange.svg"); }

/*
Secondary Buttons
*/
.btn-sec {
  display: block;
  width: max-content;
  margin-right: 10px;
  font-weight: 500;
  padding: 10px 0;
  padding-right: 40px; }
  .btn-sec::after {
    transition: all 0.3s ease-in-out;
    transform: translateX(0);
    right: 10px;
    background-image: url("/assets/media/graphics/arrow-dark.svg"); }
  .btn-sec:hover::after {
    transform: translateX(10px);
    background-image: url("/assets/media/graphics/arrow-orange.svg"); }
  .btn-sec.o-white, .block.o-style-blue .btn-sec, .block.o-style-green .btn-sec, .page-style-green .block.o-style-none .btn-sec, .page-style-blue .block.o-style-none .btn-sec {
    color: #ffffff; }
    .btn-sec.o-white::after, .block.o-style-blue .btn-sec::after, .block.o-style-green .btn-sec::after, .page-style-green .block.o-style-none .btn-sec::after, .page-style-blue .block.o-style-none .btn-sec::after {
      background-image: url("/assets/media/graphics/arrow-light.svg"); }

/*
Download buttons
*/
/*
Scroll
*/
.btn-page, .block.block-image-gallery .flickity-button {
  width: 65px;
  height: 65px;
  position: absolute;
  display: block;
  justify-content: center;
  align-items: center;
  align-content: center;
  text-align: center;
  z-index: 1;
  top: 40%;
  cursor: pointer;
  background-image: url("/assets/media/graphics/arrow-circle-orange.svg");
  background-repeat: no-repeat;
  background-size: contain;
  transition: opacity 0.3s ease-in-out; }
  .btn-page[role="Previous"], .block.block-image-gallery .flickity-button[role="Previous"], .btn-page[aria-label="Previous"], .block.block-image-gallery .flickity-button[aria-label="Previous"] {
    transform: translateY(-50%) scaleX(-1) !important; }
  .btn-page:hover, .block.block-image-gallery .flickity-button:hover {
    opacity: .5; }
  .btn-page.o-white, .block.block-image-gallery .o-white.flickity-button, .block.o-style-blue .btn-page, .block.o-style-blue .block.block-image-gallery .flickity-button, .block.block-image-gallery .block.o-style-blue .flickity-button, .block.o-style-green .btn-page, .block.o-style-green .block.block-image-gallery .flickity-button, .block.block-image-gallery .block.o-style-green .flickity-button, .page-style-green .block.o-style-none .btn-page, .page-style-green .block.o-style-none .block.block-image-gallery .flickity-button, .block.block-image-gallery .page-style-green .block.o-style-none .flickity-button, .page-style-blue .block.o-style-none .btn-page, .page-style-blue .block.o-style-none .block.block-image-gallery .flickity-button, .block.block-image-gallery .page-style-blue .block.o-style-none .flickity-button {
    color: #ffffff;
    background-image: url("/assets/media/graphics/arrow-circle-light.svg"); }
  .btn-page.o-orange, .block.block-image-gallery .o-orange.flickity-button {
    color: #ED5436 !important;
    background-image: url("/assets/media/graphics/arrow-circle-orange.svg") !important; }
  .btn-page.o-dark, .block.block-image-gallery .o-dark.flickity-button {
    color: #1D314D !important;
    background-image: url("/assets/media/graphics/arrow-circle-dark.svg") !important; }

/*
Inline search
*/
.btn-inline-search {
  background: rgba(29, 49, 77, 0.2);
  color: #1D314D;
  padding: 10px 20px;
  transition: all 0.3s ease-in-out; }
  .btn-inline-search:hover {
    background: #ED5436;
    color: #ffffff; }

/*
Halo
*/
.site-header[class*='halo-']::before {
  display: block;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  width: 100vw;
  height: 100%;
  z-index: 2; }

.site-header[class*='halo-'].halo-o-orange::before {
  background-image: url("/assets/media/shapes/shape-o-orange.svg");
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  mask-image: linear-gradient(to top, transparent 10%, black 20%);
  background-position: 50% 220px; }
  @media (max-width: 991px) {
    .site-header[class*='halo-'].halo-o-orange::before {
      background-size: 120%; } }

.site-header[class*='halo-'].halo-o-offwhite::before {
  background-image: url("/assets/media/shapes/shape-o-offwhite.svg");
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  mask-image: linear-gradient(to top, transparent 10%, black 20%);
  background-position: 50% 220px; }
  @media (max-width: 991px) {
    .site-header[class*='halo-'].halo-o-offwhite::before {
      background-size: 120%; } }

.site-header[class*='halo-'].halo-o-blue::before {
  background-image: url("/assets/media/shapes/shape-o-blue.svg");
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  mask-image: linear-gradient(to top, transparent 10%, black 20%);
  background-position: 50% 220px; }
  @media (max-width: 991px) {
    .site-header[class*='halo-'].halo-o-blue::before {
      background-size: 120%; } }

.site-header[class*='halo-'].halo-o-green::before {
  background-image: url("/assets/media/shapes/shape-o-green.svg");
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  mask-image: linear-gradient(to top, transparent 10%, black 20%);
  background-position: 50% 220px; }
  @media (max-width: 991px) {
    .site-header[class*='halo-'].halo-o-green::before {
      background-size: 120%; } }

.site-header[class*='halo-'].halo-o-black::before {
  background-image: url("/assets/media/shapes/shape-o-black.svg");
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  mask-image: linear-gradient(to top, transparent 10%, black 20%);
  background-position: 50% 220px; }
  @media (max-width: 991px) {
    .site-header[class*='halo-'].halo-o-black::before {
      background-size: 120%; } }

.site-header[class*='halo-'].halo-t-orange::before {
  background-image: url("/assets/media/shapes/shape-t-orange.svg");
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  mask-image: linear-gradient(to top, transparent 10%, black 20%); }

.site-header[class*='halo-'].halo-t-offwhite::before {
  background-image: url("/assets/media/shapes/shape-t-offwhite.svg");
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  mask-image: linear-gradient(to top, transparent 10%, black 20%); }

.site-header[class*='halo-'].halo-t-blue::before {
  background-image: url("/assets/media/shapes/shape-t-blue.svg");
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  mask-image: linear-gradient(to top, transparent 10%, black 20%); }

.site-header[class*='halo-'].halo-t-green::before {
  background-image: url("/assets/media/shapes/shape-t-green.svg");
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  mask-image: linear-gradient(to top, transparent 10%, black 20%); }

.site-header[class*='halo-'].halo-t-black::before {
  background-image: url("/assets/media/shapes/shape-t-black.svg");
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  mask-image: linear-gradient(to top, transparent 10%, black 20%); }

.site-header[class*='halo-'].halo-g-orange::before {
  background-image: url("/assets/media/shapes/shape-g-orange.svg");
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  mask-image: linear-gradient(to top, transparent 10%, black 20%);
  background-size: contain;
  background-position: 120% 100%;
  height: 85%; }
  @media (max-width: 991px) {
    .site-header[class*='halo-'].halo-g-orange::before {
      height: 100%; } }
  @media (max-width: 767px) {
    .site-header[class*='halo-'].halo-g-orange::before {
      background-position: 50% 100%;
      background-size: auto 100%; } }

.site-header[class*='halo-'].halo-g-offwhite::before {
  background-image: url("/assets/media/shapes/shape-g-offwhite.svg");
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  mask-image: linear-gradient(to top, transparent 10%, black 20%);
  background-size: contain;
  background-position: 120% 100%;
  height: 85%; }
  @media (max-width: 991px) {
    .site-header[class*='halo-'].halo-g-offwhite::before {
      height: 100%; } }
  @media (max-width: 767px) {
    .site-header[class*='halo-'].halo-g-offwhite::before {
      background-position: 50% 100%;
      background-size: auto 100%; } }

.site-header[class*='halo-'].halo-g-blue::before {
  background-image: url("/assets/media/shapes/shape-g-blue.svg");
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  mask-image: linear-gradient(to top, transparent 10%, black 20%);
  background-size: contain;
  background-position: 120% 100%;
  height: 85%; }
  @media (max-width: 991px) {
    .site-header[class*='halo-'].halo-g-blue::before {
      height: 100%; } }
  @media (max-width: 767px) {
    .site-header[class*='halo-'].halo-g-blue::before {
      background-position: 50% 100%;
      background-size: auto 100%; } }

.site-header[class*='halo-'].halo-g-green::before {
  background-image: url("/assets/media/shapes/shape-g-green.svg");
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  mask-image: linear-gradient(to top, transparent 10%, black 20%);
  background-size: contain;
  background-position: 120% 100%;
  height: 85%; }
  @media (max-width: 991px) {
    .site-header[class*='halo-'].halo-g-green::before {
      height: 100%; } }
  @media (max-width: 767px) {
    .site-header[class*='halo-'].halo-g-green::before {
      background-position: 50% 100%;
      background-size: auto 100%; } }

.site-header[class*='halo-'].halo-g-black::before {
  background-image: url("/assets/media/shapes/shape-g-black.svg");
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  mask-image: linear-gradient(to top, transparent 10%, black 20%);
  background-size: contain;
  background-position: 120% 100%;
  height: 85%; }
  @media (max-width: 991px) {
    .site-header[class*='halo-'].halo-g-black::before {
      height: 100%; } }
  @media (max-width: 767px) {
    .site-header[class*='halo-'].halo-g-black::before {
      background-position: 50% 100%;
      background-size: auto 100%; } }

.site-header[class*='halo-'].halo-s-orange::before {
  background-image: url("/assets/media/shapes/shape-s-orange.svg");
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  mask-image: linear-gradient(to top, transparent 10%, black 20%);
  background-size: calc(100% - 100px) 100%;
  background-position: top right; }
  @media (max-width: 991px) {
    .site-header[class*='halo-'].halo-s-orange::before {
      background-size: cover; } }

.site-header[class*='halo-'].halo-s-offwhite::before {
  background-image: url("/assets/media/shapes/shape-s-offwhite.svg");
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  mask-image: linear-gradient(to top, transparent 10%, black 20%);
  background-size: calc(100% - 100px) 100%;
  background-position: top right; }
  @media (max-width: 991px) {
    .site-header[class*='halo-'].halo-s-offwhite::before {
      background-size: cover; } }

.site-header[class*='halo-'].halo-s-blue::before {
  background-image: url("/assets/media/shapes/shape-s-blue.svg");
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  mask-image: linear-gradient(to top, transparent 10%, black 20%);
  background-size: calc(100% - 100px) 100%;
  background-position: top right; }
  @media (max-width: 991px) {
    .site-header[class*='halo-'].halo-s-blue::before {
      background-size: cover; } }

.site-header[class*='halo-'].halo-s-green::before {
  background-image: url("/assets/media/shapes/shape-s-green.svg");
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  mask-image: linear-gradient(to top, transparent 10%, black 20%);
  background-size: calc(100% - 100px) 100%;
  background-position: top right; }
  @media (max-width: 991px) {
    .site-header[class*='halo-'].halo-s-green::before {
      background-size: cover; } }

.site-header[class*='halo-'].halo-s-black::before {
  background-image: url("/assets/media/shapes/shape-s-black.svg");
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  mask-image: linear-gradient(to top, transparent 10%, black 20%);
  background-size: calc(100% - 100px) 100%;
  background-position: top right; }
  @media (max-width: 991px) {
    .site-header[class*='halo-'].halo-s-black::before {
      background-size: cover; } }

.site-header[class*='halo-'].halo-r-orange::before {
  background-image: url("/assets/media/shapes/shape-r-orange.svg");
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  mask-image: linear-gradient(to top, transparent 10%, black 20%);
  background-size: cover;
  background-position: top left; }

.site-header[class*='halo-'].halo-r-offwhite::before {
  background-image: url("/assets/media/shapes/shape-r-offwhite.svg");
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  mask-image: linear-gradient(to top, transparent 10%, black 20%);
  background-size: cover;
  background-position: top left; }

.site-header[class*='halo-'].halo-r-blue::before {
  background-image: url("/assets/media/shapes/shape-r-blue.svg");
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  mask-image: linear-gradient(to top, transparent 10%, black 20%);
  background-size: cover;
  background-position: top left; }

.site-header[class*='halo-'].halo-r-green::before {
  background-image: url("/assets/media/shapes/shape-r-green.svg");
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  mask-image: linear-gradient(to top, transparent 10%, black 20%);
  background-size: cover;
  background-position: top left; }

.site-header[class*='halo-'].halo-r-black::before {
  background-image: url("/assets/media/shapes/shape-r-black.svg");
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  mask-image: linear-gradient(to top, transparent 10%, black 20%);
  background-size: cover;
  background-position: top left; }

table {
  table-layout: fixed;
  border-collapse: collapse;
  margin-top: 80px; }
  table tr {
    border-top: 1px solid #1D314D; }
    .o-style-blue table tr,
    .o-style-green table tr {
      border-top-color: #ffffff; }
  table th, table td {
    vertical-align: top;
    padding: 20px 80px 20px 0; }
    table th:last-of-type, table td:last-of-type {
      padding-right: 0; }
  table td {
    min-width: 100px; }

/*
Forms
*/
.field {
  display: block;
  border: none;
  border-bottom: 1px solid #ffffff;
  padding: 20px 20px 10px 0;
  background: none;
  font-size: 1rem;
  margin-bottom: 20px;
  color: #ffffff;
  transition: all 0.3s ease-in-out; }
  .field:focus {
    outline: none; }
  .field::placeholder {
    color: rgba(255, 255, 255, 0.5); }
  .field:invalid, .field.state-invalid {
    border-color: #c0c0c0;
    color: #c0c0c0;
    font-weight: 500; }

.form-quick-search {
  border-bottom: 1px solid #D3D3D3;
  position: relative; }
  .form-quick-search::before {
    background-size: contain;
    display: block;
    position: absolute;
    top: 35px;
    opacity: .7;
    left: 0;
    content: "";
    width: 22px;
    height: 22px;
    z-index: 1; }
  .form-quick-search input.search-input {
    background: transparent;
    margin-left: 20px;
    padding: 10px;
    border: 0;
    display: block;
    margin-bottom: 0;
    width: 100%; }
    .form-quick-search input.search-input:focus {
      outline: none; }

.page-error {
  background: #E7EDEC;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  background-image: url("/assets/media/shapes/shape-g-orange.svg");
  background-repeat: no-repeat;
  background-position: center; }
  .page-error .error-message {
    min-width: 300px;
    width: 45vw;
    background: #ffffff;
    padding: 50px;
    opacity: 0;
    animation: slideup-and-fade-in 4s cubic-bezier(0.19, 1, 0.22, 1) 0.5s forwards; }
    @media (max-width: 1199px) {
      .page-error .error-message {
        width: 65vw; } }
    @media (max-width: 991px) {
      .page-error .error-message {
        width: 80vw; } }
    .page-error .error-message .site-logo {
      display: block;
      width: 120px;
      margin-bottom: 20px; }

/*
Redactor fixes
*/
p:empty {
  display: none; }

/*
Body
*/
body {
  overflow-x: hidden; }

/*
No scrolling
*/
body.no-scroll {
  overflow: hidden !important;
  position: sticky !important; }

/*
Sniffer for mobile
*/
.is-mob {
  height: 0px;
  width: 0px;
  position: absolute;
  left: -1000px;
  display: none; }
  @media (max-width: 991px) {
    .is-mob {
      display: block; } }

/*
SVG Filter
*/
.svg-filter {
  position: absolute;
  left: -10000px; }

/*
Skip to content
*/
.skip-to-content {
  background: #E7EDEC;
  color: #1D314D;
  height: 30px;
  left: 50%;
  padding: 8px;
  position: absolute;
  transform: translateY(-100%);
  transition: transform 0.3s; }

.skip-to-content:focus {
  transform: translateY(0%); }

/*
Back to top
*/
.back-to-top {
  background: #ED5436;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  text-decoration: none; }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .back-to-top img {
      width: auto; } }
  .back-to-top::before {
    transition: opacity 800ms cubic-bezier(0.19, 1, 0.22, 1);
    visibility: hidden;
    opacity: 0;
    position: absolute;
    content: "Back to top";
    width: max-content;
    right: 55px;
    top: 9px;
    color: #1D314D; }
  .back-to-top:hover {
    background: #1D314D; }
    .back-to-top:hover::before {
      visibility: visible;
      opacity: 1; }
  @media (max-width: 991px) {
    .back-to-top::before {
      visibility: visible !important;
      opacity: 1;
      color: #ED5436; } }
  .back-to-top img {
    transform: rotate(-90deg); }

/*
Packages
*/
/*
News Article Thumbnail
*/
.news-article-thumb {
  display: flex; }
  .o-style-blue .news-article-thumb .inner, .news-article-thumb.o-style-white .inner {
    background-color: #ffffff !important; }
  .o-style-blue .news-article-thumb.no-image .inner, .news-article-thumb.o-style-white.no-image .inner {
    background-image: url("/assets/media/shapes/shape-g-green.svg"); }
  .o-style-blue .news-article-thumb .title, .o-style-blue .news-article-thumb .category, .o-style-blue .news-article-thumb .date, .news-article-thumb.o-style-white .title, .news-article-thumb.o-style-white .category, .news-article-thumb.o-style-white .date {
    color: #1D314D !important; }
  .news-article-thumb.o-style-green .inner {
    background-color: #5A8080 !important; }
  .news-article-thumb.o-style-green.no-image .inner {
    background-image: url("/assets/media/shapes/shape-g-blue.svg"); }
  .news-article-thumb.o-style-green .title, .news-article-thumb.o-style-green .category, .news-article-thumb.o-style-green .date {
    color: #ffffff !important; }
  .news-article-thumb.no-image .inner {
    padding-top: 206px;
    background-image: url("/assets/media/shapes/shape-g-orange.svg");
    background-repeat: no-repeat;
    background-position: 50px 0;
    background-size: 110%; }
  .news-article-thumb a {
    flex: 1;
    display: flex;
    flex-direction: column;
    text-decoration: none;
    transition: box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out; }
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      .news-article-thumb a {
        display: block; } }
    .news-article-thumb a .title, .news-article-thumb a .category, .news-article-thumb a .date {
      transition: all 0.5s cubic-bezier(0.19, 1, 0.22, 1); }
    .news-article-thumb a:hover {
      color: inherit;
      box-shadow: 0px 6.4px 7.6px -26px rgba(237, 84, 54, 0.047), 15px 11.2px 14.1px -26px rgba(237, 84, 54, 0.098), 10px 15.2px 19.4px -26px rgba(237, 84, 54, 0.15), 15px 19.1px 24px -26px rgba(237, 84, 54, 0.196), 20px 25px 29px -26px rgba(237, 84, 54, 0.227), 25px 44px 39px -26px rgba(237, 84, 54, 0.24);
      transform: matrix3d(1, 0, 0, 0, 0, 1, 0, -0.00005, 0, 0, 1, 0, 0, 0, 0, 1); }
      .news-article-thumb a:hover .title, .news-article-thumb a:hover .category, .news-article-thumb a:hover .date {
        color: #ED5436 !important;
        opacity: 1; }
  .news-article-thumb .image img {
    display: block; }
  .news-article-thumb .inner {
    position: relative;
    background: #1D314D;
    height: 100%;
    padding: 20px;
    padding-bottom: 40px; }
  .news-article-thumb .title {
    color: #ffffff !important; }
  .news-article-thumb .category, .news-article-thumb .date {
    opacity: .5;
    color: #ffffff;
    bottom: 20px;
    position: absolute;
    margin-bottom: 0; }
  .news-article-thumb .category {
    text-align: right;
    right: 20px; }
  .news-article-thumb .date {
    text-transform: uppercase; }

address.contact {
  border-bottom: 1px solid #ED5436;
  border-right: 1px solid #ED5436;
  padding: 20px; }
  address.contact .name {
    display: block;
    margin-bottom: 10px; }
  address.contact a[href] {
    text-decoration: none;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block; }

a[class*='control-'] {
  color: #ED5436;
  position: fixed;
  z-index: 50;
  top: 45vh;
  margin: 0;
  text-decoration: none;
  border-left: 2px solid #ED5436;
  text-align: center;
  max-height: 300px;
  transition: all 0.3s ease-in-out !important; }
  @media (max-width: 767px) {
    a[class*='control-'] {
      display: none !important; } }
  @media (max-width: 991px) {
    a[class*='control-'] {
      display: none; } }
  a[class*='control-'].control-next {
    right: 20px;
    writing-mode: vertical-rl;
    writing-mode: tb-rl; }
    @media (max-width: 1199px) {
      a[class*='control-'].control-next {
        right: 10px; } }
    @media (max-width: 991px) {
      a[class*='control-'].control-next {
        right: 6px;
        border-left: 0; } }
  a[class*='control-'].control-prev {
    transform: rotate(-180deg);
    left: 20px;
    writing-mode: tb;
    writing-mode: vertical-rl; }
    @media (max-width: 1199px) {
      a[class*='control-'].control-prev {
        left: 10px; } }
    @media (max-width: 991px) {
      a[class*='control-'].control-prev {
        left: 6px;
        border-left: 0; } }
  a[class*='control-']:hover {
    opacity: 0.5 !important; }

/*
Filter
- Used to filter a variety of data interfaces
*/
.filter {
  border-bottom: 1px solid #D3D3D3; }
  .filter.active .filter-panel {
    display: block;
    animation: fade-in .5s normal forwards ease-in-out;
    transform-style: preserve-3d;
    z-index: 100; }
  .filter.active .filter-value:hover::after {
    background-image: url("/assets/media/graphics/close-orange.svg");
    background-size: contain; }
  .filter .filter-value {
    width: 100%;
    cursor: pointer; }
    .filter .filter-value.btn-sec::after {
      transform: rotate(90deg) translateX(-50%) !important; }
  .filter .filter-panel {
    display: none;
    position: absolute;
    left: 0;
    width: 100%;
    background: #1D314D;
    background-color: #1D314D;
    padding: 20px 0 30px 0; }
    .filter .filter-panel::before {
      display: block;
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 200vw;
      background: #1D314D;
      z-index: -1;
      left: -50vw; }
      @media (max-width: 991px) {
        .filter .filter-panel::before {
          width: 100vw;
          left: -30px; } }
    @media (max-width: 767px) {
      .filter .filter-panel {
        left: -30px;
        padding-left: 10px; } }
  .filter .filter-options {
    display: flex;
    justify-content: flex-end;
    max-width: 1200px;
    margin: auto; }
    @media (max-width: 991px) {
      .filter .filter-options {
        justify-content: center; } }
    @media (max-width: 767px) {
      .filter .filter-options {
        display: block; } }
    .filter .filter-options .small, .filter .filter-options .back-to-top::before, .filter .filter-options .news-article-thumb .category, .news-article-thumb .filter .filter-options .category, .filter .filter-options .news-article-thumb .date, .news-article-thumb .filter .filter-options .date, .filter .filter-options .investment-thumb .sector, .investment-thumb .filter .filter-options .sector,
    .filter .filter-options .investment-thumb .status, .investment-thumb .filter .filter-options .status, .filter .filter-options .site-footer-top p:not(.h1):not(.xsmall):not(.stat-definition), .site-footer-top .filter .filter-options p:not(.h1):not(.xsmall):not(.stat-definition), .filter .filter-options .site-footer-top a, .site-footer-top .filter .filter-options a,
    .filter .filter-options .site-footer-middle p:not(.h1):not(.xsmall):not(.stat-definition), .site-footer-middle .filter .filter-options p:not(.h1):not(.xsmall):not(.stat-definition),
    .filter .filter-options .site-footer-middle a, .site-footer-middle .filter .filter-options a {
      color: rgba(255, 255, 255, 0.5);
      padding: 10px 0;
      line-height: 1.1875rem;
      margin-bottom: 0 !important;
      margin-left: 20px; }
      .filter .filter-options .small:not(:first-of-type), .filter .filter-options .back-to-top:not(:first-of-type)::before, .filter .filter-options .news-article-thumb .category:not(:first-of-type), .news-article-thumb .filter .filter-options .category:not(:first-of-type), .filter .filter-options .news-article-thumb .date:not(:first-of-type), .news-article-thumb .filter .filter-options .date:not(:first-of-type), .filter .filter-options .investment-thumb .sector:not(:first-of-type), .investment-thumb .filter .filter-options .sector:not(:first-of-type),
      .filter .filter-options .investment-thumb .status:not(:first-of-type), .investment-thumb .filter .filter-options .status:not(:first-of-type), .filter .filter-options .site-footer-top p:not(:first-of-type):not(.h1):not(.xsmall):not(.stat-definition), .site-footer-top .filter .filter-options p:not(:first-of-type):not(.h1):not(.xsmall):not(.stat-definition), .filter .filter-options .site-footer-top a:not(:first-of-type), .site-footer-top .filter .filter-options a:not(:first-of-type),
      .filter .filter-options .site-footer-middle p:not(:first-of-type):not(.h1):not(.xsmall):not(.stat-definition), .site-footer-middle .filter .filter-options p:not(:first-of-type):not(.h1):not(.xsmall):not(.stat-definition),
      .filter .filter-options .site-footer-middle a:not(:first-of-type), .site-footer-middle .filter .filter-options a:not(:first-of-type) {
        margin-top: 10px; }
  .filter .filter-group {
    margin-right: 80px; }
    .filter .filter-group[data-cols='2'] .filter-group-options {
      columns: 2; }
    .filter .filter-group[data-cols='3'] .filter-group-options {
      columns: 3; }
    @media (max-width: 991px) {
      .filter .filter-group .filter-group-options {
        columns: 1 !important; } }
    @media (max-width: 767px) {
      .filter .filter-group:not(:last-of-type) {
        margin-bottom: 20px; } }
  .filter a.filter-item {
    display: block;
    position: relative;
    text-decoration: none;
    padding: 5px 0;
    margin-left: 20px;
    color: #ffffff;
    transition: color 600ms cubic-bezier(0.19, 1, 0.22, 1); }
    .filter a.filter-item:before {
      display: inline-block;
      margin-right: 10px;
      transform: translateY(4px);
      content: "";
      height: 20px;
      width: 20px;
      border-radius: 50%;
      transition: all 600ms cubic-bezier(0.19, 1, 0.22, 1);
      border: 1px solid rgba(255, 255, 255, 0.4);
      background: rgba(255, 255, 255, 0.3); }
    .filter a.filter-item:hover, .filter a.filter-item.active {
      color: #ED5436; }
      .filter a.filter-item:hover::before, .filter a.filter-item.active::before {
        border: 1px solid rgba(237, 84, 54, 0.6);
        background: rgba(237, 84, 54, 0.7); }
    .filter a.filter-item.active::before {
      background: #ED5436; }

.fund-thumb {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 100%;
  justify-content: flex-start;
  padding: 0 40px 80px 0;
  margin-right: 40px; }
  .fund-thumb.active .fund-figure {
    color: #ED5436; }
  .fund-thumb.active .fund-graphic::before {
    background: #ED5436;
    opacity: 0.6;
    top: 25%;
    height: 75%; }
  .fund-thumb .fund-year {
    margin-bottom: 10px;
    color: #ED5436 !important; }
    .fund-thumb .fund-year.line {
      overflow: visible; }
      .fund-thumb .fund-year.line::after {
        background: #ED5436 !important;
        width: calc(100%); }
  .fund-thumb .fund-title {
    margin-bottom: 40px;
    min-height: 72px; }
  .fund-thumb .fund-figure {
    margin: 0;
    transition: 0.3s color 0.3s ease-in-out; }
  .fund-thumb .fund-bottom {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start; }
    .fund-thumb .fund-bottom .fund-phase {
      margin-top: 40px;
      margin-bottom: 20px; }
    .fund-thumb .fund-bottom .fund-management {
      opacity: 0.5; }
    .fund-thumb .fund-bottom .fund-more,
    .fund-thumb .fund-bottom .fund-resource {
      margin-top: 20px;
      -webkit-transform-style: preserve-3d;
      -webkit-backface-visibility: hidden;
      margin-right: 0; }
    .fund-thumb .fund-bottom .fund-resources {
      margin-top: 10px; }
    .fund-thumb .fund-bottom .fund-resource {
      color: white;
      text-decoration: none;
      font-size: 0.875rem;
      padding-right: 30px;
      display: block; }
      .fund-thumb .fund-bottom .fund-resource::after {
        transition: background 0.3s ease-in-out;
        background-image: url("/assets/media/graphics/arrow-light.svg"); }
      .fund-thumb .fund-bottom .fund-resource:hover {
        color: #ED5436; }
        .fund-thumb .fund-bottom .fund-resource:hover::after {
          background-image: url("/assets/media/graphics/arrow-orange.svg"); }
  .fund-thumb .fund-graphic-wrapper {
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-transform-style: preserve-3d;
    -webkit-backface-visibility: hidden; }
  .fund-thumb .fund-label {
    color: white;
    position: absolute;
    text-align: center;
    top: calc(50% - 65px);
    text-transform: uppercase; }
  .fund-thumb .fund-graphic {
    -webkit-transform-style: preserve-3d;
    -webkit-backface-visibility: hidden;
    cursor: pointer;
    background: #1D314D;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    width: calc(var(--fund-size) * 4);
    min-width: 30%;
    position: relative;
    transform-style: preserve-3d;
    transition: all 0.6s 0.3s ease-in-out; }
    @media (max-width: 767px) {
      .fund-thumb .fund-graphic {
        width: calc(10% + var(--fund-size)); } }
    .fund-thumb .fund-graphic::after {
      content: "";
      display: block;
      padding-top: 100%; }
    .fund-thumb .fund-graphic::before {
      display: block;
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      transition: all 0.5s 0.3s ease-in-out;
      right: 40px;
      left: 25%;
      width: 80%;
      height: 100%;
      bottom: 0;
      transform: translateZ(-1px);
      background: #000000;
      background-blend-mode: multiply;
      opacity: 0.4;
      border-radius: 100%;
      filter: blur(13px); }
    .fund-thumb .fund-graphic::after {
      transition: filter 0.3s ease-in-out; }
    .fund-thumb .fund-graphic:hover {
      border-color: transparent;
      color: #ED5436; }
      .fund-thumb .fund-graphic:hover .fund-figure {
        color: #ED5436; }

/*
Image
*/
@keyframes fix-safari-fx {
  from {
    filter: url(#gradientFilter); }
  to {
    filter: url(#gradientFilter) contrast(1.0001); } }

figure.fx-gradient img {
  filter: url(#gradientFilter);
  animation: fix-safari-fx .1s .1s; }
  @supports (-ms-ime-align: auto) {
    figure.fx-gradient img {
      animation: none;
      filter: brightness(0.86) contrast(0.76) opacity(0.92) sepia(1) hue-rotate(175deg); } }

figure {
  width: 100%;
  /*
	Round borders
	*/ }
  @supports (-ms-ime-align: auto) {
    figure video {
      filter: brightness(0.86) contrast(0.86) opacity(0.92) sepia(1) hue-rotate(186deg); } }
  figure img {
    width: 100%;
    height: auto; }
  figure.round, .inline-block.people-highlight .person-thumb figure.portrait, .block.block-people-highlight .person-thumb figure.portrait {
    border-radius: 100%;
    overflow: hidden; }
  figure[data-ratio] {
    height: auto;
    position: relative; }
    figure[data-ratio] img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover; }

/*
Investment Thumbnail
*/
.investment-thumb {
  display: flex; }
  .investment-thumb a {
    flex: 1;
    display: flex;
    flex-direction: column;
    text-decoration: none;
    transition: box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out; }
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      .investment-thumb a {
        display: block;
        height: auto !important; } }
    .investment-thumb a .title,
    .investment-thumb a .desc,
    .investment-thumb a .sector,
    .investment-thumb a .invested,
    .investment-thumb a .status,
    .investment-thumb a .goals {
      transition: all 0.5s cubic-bezier(0.19, 1, 0.22, 1); }
    .investment-thumb a:hover {
      box-shadow: 0px 6.4px 7.6px -26px rgba(237, 84, 54, 0.047), 15px 11.2px 14.1px -26px rgba(237, 84, 54, 0.098), 10px 15.2px 19.4px -26px rgba(237, 84, 54, 0.15), 15px 19.1px 24px -26px rgba(237, 84, 54, 0.196), 20px 25px 29px -26px rgba(237, 84, 54, 0.227), 25px 44px 39px -26px rgba(237, 84, 54, 0.24);
      transform: matrix3d(1, 0, 0, 0, 0, 1, 0, -0.00005, 0, 0, 1, 0, 0, 0, 0, 1);
      color: inherit; }
      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        .investment-thumb a:hover {
          transform: none; } }
      .investment-thumb a:hover .title,
      .investment-thumb a:hover .desc,
      .investment-thumb a:hover .sector,
      .investment-thumb a:hover .status {
        color: #ED5436;
        opacity: 1; }
      .investment-thumb a:hover .invested,
      .investment-thumb a:hover .goals {
        color: #ED5436; }
  .investment-thumb .image img {
    display: block; }
  .investment-thumb .inner {
    display: flex;
    flex-direction: column;
    position: relative;
    background: #ffffff;
    padding: 10px;
    padding-bottom: 70px;
    flex: 1;
    justify-content: flex-start; }
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      .investment-thumb .inner {
        height: auto !important; } }
  .investment-thumb .title {
    margin-bottom: 10px;
    flex-shrink: 1; }
  .investment-thumb .invested,
  .investment-thumb .goals {
    opacity: 0.5; }
  .investment-thumb .sdg-wrapper {
    margin-top: auto; }
  .investment-thumb .sdg-goals {
    display: flex;
    flex-wrap: wrap;
    width: 100%; }
    @media (max-width: 767px) {
      .investment-thumb .sdg-goals {
        justify-content: flex-start; } }
    .investment-thumb .sdg-goals .image {
      width: calc(33% - 26.66667px);
      margin-right: 40px; }
      .investment-thumb .sdg-goals .image:nth-of-type(3n) {
        margin-right: 0; }
      .investment-thumb .sdg-goals .image:nth-of-type(n + 4) {
        margin-top: 10px; }
      @media (max-width: 767px) {
        .investment-thumb .sdg-goals .image {
          width: calc(33% - 26.66667px);
          max-width: 70px; } }
  .investment-thumb .sector,
  .investment-thumb .status {
    color: #1D314D;
    position: absolute;
    bottom: 10px;
    margin-bottom: 0; }
    @media (max-width: 767px) {
      .investment-thumb .sector,
      .investment-thumb .status {
        bottom: 10px; } }
  .investment-thumb .status {
    left: 10px;
    text-transform: uppercase; }
    @media (min-width: 768px) and (max-width: 991px) {
      .investment-thumb .status {
        bottom: 32px; } }
    @media (max-width: 767px) {
      .investment-thumb .status {
        left: 10px; } }
  .investment-thumb .sector {
    text-align: right;
    right: 10px; }
    @media (min-width: 768px) and (max-width: 991px) {
      .investment-thumb .sector {
        right: auto;
        left: 10px; } }
    @media (max-width: 767px) {
      .investment-thumb .sector {
        right: 20px; } }
  .investment-thumb[data-show-information="false"] a:hover {
    box-shadow: none; }
    .investment-thumb[data-show-information="false"] a:hover .image {
      box-shadow: 0px 6.4px 7.6px -26px rgba(237, 84, 54, 0.047), 15px 11.2px 14.1px -26px rgba(237, 84, 54, 0.098), 10px 15.2px 19.4px -26px rgba(237, 84, 54, 0.15), 15px 19.1px 24px -26px rgba(237, 84, 54, 0.196), 20px 25px 29px -26px rgba(237, 84, 54, 0.227), 25px 44px 39px -26px rgba(237, 84, 54, 0.24);
      transform: matrix3d(1, 0, 0, 0, 0, 1, 0, -0.00005, 0, 0, 1, 0, 0, 0, 0, 1); }
  .investment-thumb[data-show-information="false"] .desc,
  .investment-thumb[data-show-information="false"] .status,
  .investment-thumb[data-show-information="false"] .invested,
  .investment-thumb[data-show-information="false"] .sdg-wrapper,
  .investment-thumb[data-show-information="false"] .sector {
    display: none; }
  .investment-thumb[data-show-information="false"] .image {
    height: auto;
    transition: box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out; }
  .investment-thumb[data-show-information="false"] .inner {
    background: none;
    padding-bottom: 20px; }

/*
Job Tile
*/
.job-tile {
  padding: 30px; }
  .job-tile .office {
    text-transform: uppercase; }
  .job-tile.o-style-grey {
    background: #E7EDEC; }
    .job-tile.o-style-grey .title,
    .job-tile.o-style-grey .office,
    .job-tile.o-style-grey .summary,
    .job-tile.o-style-grey .btn-sec,
    .job-tile.o-style-grey a {
      color: #000000; }
    .job-tile.o-style-grey .btn-sec::after {
      background-image: url("/assets/media/graphics/arrow-dark.svg"); }

/*
Office Thumb
*/
.office-thumb h2 {
  margin-bottom: 20px;
  width: 100%; }
  .office-thumb h2 a {
    color: #ED5436;
    text-decoration: none;
    font-family: inherit; }

.office-thumb .inner {
  padding: 15px 20px;
  display: flex;
  flex-wrap: wrap; }
  @media (max-width: 767px) {
    .office-thumb .inner {
      flex-direction: column; } }

.office-thumb h2 {
  flex-basis: 100%; }

.office-thumb address {
  display: inline-block;
  width: calc(60% - 40px); }
  @media (max-width: 767px) {
    .office-thumb address {
      width: 100%; } }

.office-thumb .contact {
  margin-left: 20px; }
  @media (max-width: 767px) {
    .office-thumb .contact {
      margin-left: 0; } }
  .office-thumb .contact .label, .office-thumb .contact .btn-inline-search {
    color: #1D314D !important; }
  .office-thumb .contact a {
    display: block;
    margin-bottom: 20px;
    color: #ED5436; }

/*
Person Thumb
*/
.person-thumb:hover .portrait {
  box-shadow: 0px 6.4px 7.6px -26px rgba(237, 84, 54, 0.047), 15px 11.2px 14.1px -26px rgba(237, 84, 54, 0.098), 10px 15.2px 19.4px -26px rgba(237, 84, 54, 0.15), 15px 19.1px 24px -26px rgba(237, 84, 54, 0.196), 20px 25px 29px -26px rgba(237, 84, 54, 0.227), 25px 44px 39px -26px rgba(237, 84, 54, 0.24);
  transform: matrix3d(1, 0, 0, 0, 0, 1, 0, -0.00005, 0, 0, 1, 0, 0, 0, 0, 1); }
  .person-thumb:hover .portrait.fx-gradient img {
    opacity: 0; }

.person-thumb:hover .name {
  color: #ED5436; }

.person-thumb.loading .image {
  position: relative; }
  .person-thumb.loading .image::before {
    display: block;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    content: "Loading";
    color: #ffffff;
    background: rgba(237, 84, 54, 0.7);
    animation: fade-in 1s normal forwards ease-in-out, loading-text-pulse 2s infinite alternate ease-in-out;
    z-index: 2; }

.person-thumb .portrait {
  box-shadow: none;
  transition: box-shadow 600ms cubic-bezier(0.19, 1, 0.22, 1); }
  .person-thumb .portrait.o-silhouette img {
    background-color: #E7EDEC;
    display: block; }
  .person-thumb .portrait.fx-gradient {
    background-size: cover; }
    .person-thumb .portrait.fx-gradient img {
      transition: opacity 600ms cubic-bezier(0.19, 1, 0.22, 1);
      display: block; }

.person-thumb .inner {
  padding: 10px; }
  .person-thumb .inner a {
    text-decoration: none; }

.person-thumb .name {
  font-weight: 500;
  transition: color 600ms cubic-bezier(0.19, 1, 0.22, 1);
  display: block; }

.person-thumb .role {
  display: block;
  color: #1D314D;
  opacity: .7; }

.block.o-style-blue .person-thumb .name, .block.o-style-blue .person-thumb .role {
  color: #ffffff; }

/*
Stat
*/
.stat .stat-fact {
  margin-bottom: 55px; }

.stat .stat-figure {
  font-family: "BwGradual-Regular", arial, sans-serif;
  color: #ED5436;
  font-size: 4.6875rem; }

.stat .stat-details {
  margin-top: 120px;
  margin-bottom: 0; }

/*
Video
*/
.inline-block.people-highlight {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap; }
  .block.block-one-column.o-size-narrow .inline-block.people-highlight {
    grid-column-start: 2;
    grid-column-end: 12;
    grid-row-start: 1;
    grid-row-end: 1; }
  .inline-block.people-highlight[data-no-people="1"] .person-thumb, .inline-block.people-highlight[data-no-people="2"] .person-thumb {
    max-width: calc(50% - 80px); }
  .inline-block.people-highlight .person-thumb {
    display: flex;
    align-items: center;
    align-content: flex-start;
    margin: 40px 80px 0 0;
    max-width: calc(33% - 80px);
    width: max-content; }
    .inline-block.people-highlight .person-thumb:nth-of-type(-n+3) {
      margin-top: 0; }
    .inline-block.people-highlight .person-thumb .portrait {
      width: 75px;
      height: 75px; }
      .inline-block.people-highlight .person-thumb .portrait.fx-gradient {
        background-position: center center; }
      .inline-block.people-highlight .person-thumb .portrait img {
        width: auto;
        height: 100%;
        width: 100%;
        object-fit: cover; }
    .inline-block.people-highlight .person-thumb .name, .inline-block.people-highlight .person-thumb .role {
      color: #ffffff;
      margin-left: 10px; }

/*
Accordian
*/
.block.block-accordian.o-size-normal .accordian {
  grid-column-start: 1;
  grid-column-end: 13;
  grid-row-start: 1;
  grid-row-end: 1; }

.block.block-accordian.o-size-narrow .top .inner {
  grid-column-start: 2; }

.block.block-accordian.o-size-narrow .accordian {
  grid-column-start: 2;
  grid-column-end: 12;
  grid-row-start: 1;
  grid-row-end: 1; }

.block.block-accordian .accordian-section {
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  margin-bottom: 20px; }
  .block.block-accordian .accordian-section.active .accordian-column {
    display: block; }
  .block.block-accordian .accordian-section.active .accordian-title::after {
    right: 3px;
    top: 23px; }

.block.block-accordian .accordian-title {
  position: relative;
  padding-bottom: 20px;
  margin-bottom: 0;
  padding-top: 10px;
  cursor: pointer;
  padding-right: 160px; }
  @media (max-width: 767px) {
    .block.block-accordian .accordian-title {
      padding-right: 80px; } }
  .block.block-accordian .accordian-title:hover::after {
    opacity: 1; }
  .block.block-accordian .accordian-title::after {
    display: block;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url("/assets/media/graphics/arrow-light.svg");
    transition: opacity 600ms cubic-bezier(0.19, 1, 0.22, 1);
    opacity: 0.7;
    right: 5px;
    top: 25px;
    left: auto; }

.block.block-accordian .accordian-content {
  margin-top: 0;
  display: flex;
  flex-direction: row; }
  @media (max-width: 767px) {
    .block.block-accordian .accordian-content {
      display: block; } }

.block.block-accordian .accordian-column {
  padding: 40px 0;
  display: none;
  opacity: 0;
  transition: opacity 600ms cubic-bezier(0.19, 1, 0.22, 1);
  flex-basis: 50%;
  margin-right: 80px; }
  .block.block-accordian .accordian-column li {
    display: block; }
  @media (max-width: 767px) {
    .block.block-accordian .accordian-column {
      margin-right: 0; } }
  .block.block-accordian .accordian-column ul,
  .block.block-accordian .accordian-column ol {
    columns: 1; }
  .block.block-accordian .accordian-column:only-of-type {
    flex-basis: 75%; }

/*
Breaking News
*/
.block.block-data-graph.o-size-normal .information {
  grid-column-start: 1;
  grid-column-end: 6;
  grid-row-start: 1;
  grid-row-end: 1; }

.block.block-data-graph.o-size-normal .graph {
  grid-column-start: 8;
  grid-column-end: 12;
  grid-row-start: 1;
  grid-row-end: 1; }

.block.block-data-graph.o-size-narrow .information {
  grid-column-start: 2;
  grid-column-end: 6;
  grid-row-start: 1;
  grid-row-end: 1; }
  @media (max-width: 1199px) {
    .block.block-data-graph.o-size-narrow .information {
      grid-column-start: 1;
      grid-column-end: 6;
      grid-row-start: 1;
      grid-row-end: 1; } }

.block.block-data-graph.o-size-narrow .graph {
  grid-column-start: 8;
  grid-column-end: 12;
  grid-row-start: 1;
  grid-row-end: 1; }
  @media (max-width: 1199px) {
    .block.block-data-graph.o-size-narrow .graph {
      grid-column-start: 8;
      grid-column-end: 13;
      grid-row-start: 1;
      grid-row-end: 1; } }

.block.block-data-graph .information .data-dot {
  display: inline-block;
  height: 10px;
  width: 10px;
  border-radius: 100%;
  margin-right: 10px; }

.block.block-data-graph .information .data-label, .block.block-data-graph .information .data-value {
  color: #ffffff; }

.block.block-data-graph .information .data-value {
  display: inline-block;
  min-width: max-content;
  width: 40px; }

.block.block-data-graph .information .data-label {
  word-wrap: break-word;
  overflow-wrap: break-word; }

.block.block-data-graph .graph {
  padding-right: 80px;
  position: relative;
  height: max-content; }
  .block.block-data-graph .graph canvas {
    cursor: pointer; }
  @media (max-width: 767px) {
    .block.block-data-graph .graph {
      margin: 70px 40px 20px 40px;
      padding-right: 0; } }

.block.block-data-graph .graph-tooltip {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  height: auto;
  width: calc(100% - 80px);
  pointer-events: none;
  opacity: 0; }
  @media (max-width: 767px) {
    .block.block-data-graph .graph-tooltip {
      width: 100%; } }
  .block.block-data-graph .graph-tooltip .stat-figure {
    margin-bottom: 10px; }
    @media (min-width: 768px) and (max-width: 1199px) {
      .block.block-data-graph .graph-tooltip .stat-figure {
        font-size: 3em !important; } }
    @media (max-width: 767px) {
      .block.block-data-graph .graph-tooltip .stat-figure {
        margin-bottom: 50px; } }
  .block.block-data-graph .graph-tooltip .stat-definition {
    color: #ffffff;
    max-width: 60%;
    text-align: center !important; }

/*
Featured Articles
*/
.block.block-featured-news .content {
  padding-top: 40px !important; }

.block.block-featured-news .news-article-thumb {
  align-self: start; }
  @media (max-width: 767px) {
    .block.block-featured-news .news-article-thumb {
      margin-bottom: 25px; } }
  .block.block-featured-news .news-article-thumb:nth-of-type(1) {
    grid-column-start: 1;
    grid-column-end: 6;
    grid-row-start: 1;
    grid-row-end: 1; }
    @media (max-width: 991px) {
      .block.block-featured-news .news-article-thumb:nth-of-type(1) {
        grid-column-start: 1;
        grid-column-end: 7;
        grid-row-start: 1;
        grid-row-end: 1; } }
  .block.block-featured-news .news-article-thumb:nth-of-type(2) {
    grid-column-start: 8;
    grid-column-end: 13;
    grid-row-start: 1;
    grid-row-end: 1; }
    @media (max-width: 991px) {
      .block.block-featured-news .news-article-thumb:nth-of-type(2) {
        grid-column-start: 7;
        grid-column-end: 13;
        grid-row-start: 1;
        grid-row-end: 1; } }
  .block.block-featured-news .news-article-thumb a, .block.block-featured-news .news-article-thumb .inner {
    display: block;
    height: auto; }
  .block.block-featured-news .news-article-thumb:not(.no-image) .inner {
    background: transparent; }
  .block.block-featured-news .news-article-thumb:not(.no-image) .title, .block.block-featured-news .news-article-thumb:not(.no-image) .category, .block.block-featured-news .news-article-thumb:not(.no-image) .date {
    color: #1D314D; }

/*
Four Column
*/
.block-four-column {
  /*
  Options
  */
  /*
  Grid
  */ }
  .block-four-column ul, .block-four-column ol {
    columns: 1; }
  .block-four-column .content figure + h2, .block-four-column .content figure + h3, .block-four-column .content figure + h4 {
    margin-top: 20px; }
  .block-four-column .top {
    padding-bottom: 40px; }
  .block-four-column.o-alignment-center .introduction,
  .block-four-column.o-alignment-center .column {
    text-align: center; }
  .block-four-column.o-size-normal .column {
    grid-column-start: auto;
    grid-column-end: span 3;
    grid-row-start: 1;
    grid-row-end: 1; }
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      .block-four-column.o-size-normal .column {
        padding-right: 25px; }
        .block-four-column.o-size-normal .column:nth-of-type(1) {
          grid-column-start: 1;
          grid-column-end: 4;
          grid-row-start: 1;
          grid-row-end: 1; }
        .block-four-column.o-size-normal .column:nth-of-type(2) {
          grid-column-start: 4;
          grid-column-end: 7;
          grid-row-start: 1;
          grid-row-end: 1; }
        .block-four-column.o-size-normal .column:nth-of-type(3) {
          grid-column-start: 7;
          grid-column-end: 10;
          grid-row-start: 1;
          grid-row-end: 1; }
        .block-four-column.o-size-normal .column:nth-of-type(4) {
          grid-column-start: 10;
          grid-column-end: 13;
          grid-row-start: 1;
          grid-row-end: 1; } }
  .block-four-column.o-size-narrow .top .inner {
    grid-column-start: 2;
    grid-column-end: 7;
    grid-row-start: 1;
    grid-row-end: 1; }
  .block-four-column.o-size-narrow .column:nth-of-type(1) {
    grid-column-start: 2;
    grid-column-end: span 2;
    grid-row-start: 1;
    grid-row-end: 1; }
  .block-four-column.o-size-narrow .column:nth-of-type(2) {
    grid-column-start: 4;
    grid-column-end: span 2;
    grid-row-start: 1;
    grid-row-end: 1; }
  .block-four-column.o-size-narrow .column:nth-of-type(3) {
    grid-column-start: 6;
    grid-column-end: span 2;
    grid-row-start: 1;
    grid-row-end: 1; }
  .block-four-column.o-size-narrow .column:nth-of-type(4) {
    grid-column-start: 8;
    grid-column-end: span 2;
    grid-row-start: 1;
    grid-row-end: 1; }
  @media (min-width: 768px) and (max-width: 991px) {
    .block-four-column.o-size-normal .column, .block-four-column.o-size-narrow .column {
      grid-column-start: auto;
      grid-column-end: span 6;
      grid-row-start: auto;
      grid-row-end: auto;
      margin-bottom: 40px; } }

/*
Funds Timeline
*/
.block.block-funds-timeline {
  position: relative;
  overflow-x: hidden; }
  .block.block-funds-timeline .content {
    display: block; }
  .block.block-funds-timeline .top {
    margin-bottom: 40px;
    position: relative; }
  .block.block-funds-timeline .timeline {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    width: max-content;
    overflow-y: visible; }
  .block.block-funds-timeline .fund-thumb {
    flex: 1;
    max-width: 300px;
    width: 300px;
    height: auto; }
    @media (max-width: 767px) {
      .block.block-funds-timeline .fund-thumb {
        width: calc(100vw - 40px);
        max-width: calc(100vw - 40px); } }
  .block.block-funds-timeline .fund-thumb:first-of-type {
    padding-left: 0; }
  .block.block-funds-timeline .btn-page, .block.block-funds-timeline .block.block-image-gallery .flickity-button, .block.block-image-gallery .block.block-funds-timeline .flickity-button {
    position: absolute;
    bottom: 40px;
    cursor: pointer; }
    @media (max-width: 767px) {
      .block.block-funds-timeline .btn-page, .block.block-funds-timeline .block.block-image-gallery .flickity-button, .block.block-image-gallery .block.block-funds-timeline .flickity-button {
        position: static; } }
    .block.block-funds-timeline .btn-page[role="Next"], .block.block-funds-timeline .block.block-image-gallery .flickity-button[role="Next"], .block.block-image-gallery .block.block-funds-timeline .flickity-button[role="Next"] {
      right: 0; }
    .block.block-funds-timeline .btn-page[role="Previous"], .block.block-funds-timeline .block.block-image-gallery .flickity-button[role="Previous"], .block.block-image-gallery .block.block-funds-timeline .flickity-button[role="Previous"] {
      right: 120px;
      transform: scaleX(-1) !important; }
  @media (max-width: 767px) {
    .block.block-funds-timeline .controls {
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      margin-right: 20px; } }
  .block.block-funds-timeline .investments {
    display: none;
    background-color: #ED5436;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0) 15%);
    text-align: center;
    padding-bottom: 40px;
    margin-left: -50vw;
    margin-right: -50vw; }
    @media (max-width: 991px) {
      .block.block-funds-timeline .investments {
        background: #ED5436;
        margin-left: auto;
        margin-right: auto; } }
    .block.block-funds-timeline .investments.active {
      display: block; }
    .block.block-funds-timeline .investments .btn-primary {
      margin-bottom: 40px;
      background: #ED5436; }
      .block.block-funds-timeline .investments .btn-primary:hover, .block.block-funds-timeline .investments a.btn-primary.o-white.active, .block.block-funds-timeline .investments .block.o-style-blue a.active.btn-primary, .block.o-style-blue .block.block-funds-timeline .investments a.active.btn-primary, .block.block-funds-timeline .investments .block.o-style-green a.active.btn-primary, .block.o-style-green .block.block-funds-timeline .investments a.active.btn-primary, .block.block-funds-timeline .investments .page-style-green .block.o-style-none a.active.btn-primary, .page-style-green .block.o-style-none .block.block-funds-timeline .investments a.active.btn-primary, .block.block-funds-timeline .investments .page-style-blue .block.o-style-none a.active.btn-primary, .page-style-blue .block.o-style-none .block.block-funds-timeline .investments a.active.btn-primary,
      .block.block-funds-timeline .investments p.btn-primary > a.o-white:only-child:hover,
      .block.block-funds-timeline .investments p.btn-primary > a.o-white.active:only-child, .block.block-funds-timeline .investments a.btn-primary.o-grey.active,
      .block.block-funds-timeline .investments p.btn-primary > a.o-grey:only-child:hover,
      .block.block-funds-timeline .investments p.btn-primary > a.o-grey.active:only-child, .block.block-funds-timeline .investments a.btn-primary.o-orange.active,
      .block.block-funds-timeline .investments p.btn-primary > a.o-orange:only-child:hover,
      .block.block-funds-timeline .investments p.btn-primary > a.o-orange.active:only-child {
        border-color: #1D314D;
        color: #1D314D; }
        .block.block-funds-timeline .investments .btn-primary:hover::after, .block.block-funds-timeline .investments a.btn-primary.o-white.active::after, .block.block-funds-timeline .investments .block.o-style-blue a.active.btn-primary::after, .block.o-style-blue .block.block-funds-timeline .investments a.active.btn-primary::after, .block.block-funds-timeline .investments .block.o-style-green a.active.btn-primary::after, .block.o-style-green .block.block-funds-timeline .investments a.active.btn-primary::after, .block.block-funds-timeline .investments .page-style-green .block.o-style-none a.active.btn-primary::after, .page-style-green .block.o-style-none .block.block-funds-timeline .investments a.active.btn-primary::after, .block.block-funds-timeline .investments .page-style-blue .block.o-style-none a.active.btn-primary::after, .page-style-blue .block.o-style-none .block.block-funds-timeline .investments a.active.btn-primary::after,
        .block.block-funds-timeline .investments p.btn-primary > a.o-white:hover:only-child::after,
        .block.block-funds-timeline .investments p.btn-primary > a.o-white.active:only-child::after, .block.block-funds-timeline .investments a.btn-primary.o-grey.active::after,
        .block.block-funds-timeline .investments p.btn-primary > a.o-grey:hover:only-child::after,
        .block.block-funds-timeline .investments p.btn-primary > a.o-grey.active:only-child::after, .block.block-funds-timeline .investments a.btn-primary.o-orange.active::after,
        .block.block-funds-timeline .investments p.btn-primary > a.o-orange:hover:only-child::after,
        .block.block-funds-timeline .investments p.btn-primary > a.o-orange.active:only-child::after {
          background-image: url("/assets/media/graphics/arrow-dark.svg"); }
  .block.block-funds-timeline .investments-inner {
    position: relative;
    padding: 80px;
    padding-bottom: 0;
    max-width: 1200px;
    margin: 0 auto; }
    @media (max-width: 991px) {
      .block.block-funds-timeline .investments-inner {
        padding: 80px 40px 40px 40px; } }
    @media (max-width: 767px) {
      .block.block-funds-timeline .investments-inner {
        padding: 40px 20px 20px 20px; } }
    .block.block-funds-timeline .investments-inner::before {
      content: "";
      display: block;
      height: 47px;
      width: 47px;
      background: #1D314D;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-75%) translateY(-70%) rotate(45deg); }
    .block.block-funds-timeline .investments-inner::after {
      display: block;
      z-index: -1;
      content: "";
      position: absolute;
      transform: translateX(-50vw);
      top: 0;
      height: calc(100% + 80px * 2);
      width: 200vw;
      background: #ED5436; }
  .block.block-funds-timeline .fund-investments {
    display: none;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    padding-bottom: 80px; }
    .block.block-funds-timeline .fund-investments.active {
      display: flex; }
    @media (min-width: 768px) and (max-width: 991px) {
      .block.block-funds-timeline .fund-investments {
        justify-content: space-between; } }
    @media (max-width: 767px) {
      .block.block-funds-timeline .fund-investments {
        padding-top: 40px; } }
  .block.block-funds-timeline .fund-investment {
    display: inline-block;
    position: relative;
    width: 300px;
    height: calc((300px/16)*9);
    background: #ffffff;
    text-decoration: none;
    margin-right: 40px;
    margin-bottom: 80px; }
    .block.block-funds-timeline .fund-investment:nth-of-type(even) {
      margin-right: 26.66667px; }
    @media (min-width: 768px) and (max-width: 991px) {
      .block.block-funds-timeline .fund-investment {
        width: calc(50% - 25px);
        margin-right: 0 !important; } }
    @media (max-width: 767px) {
      .block.block-funds-timeline .fund-investment {
        width: calc(100%);
        margin-right: 0 !important; } }
    .block.block-funds-timeline .fund-investment figure {
      padding: 40px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      text-align: center; }
      .block.block-funds-timeline .fund-investment figure img {
        max-height: calc((300px/16)*8); }
        .block.block-funds-timeline .fund-investment figure img[data-orientation="portrait"] {
          max-height: calc(((300px/16)*8) - 40px);
          width: auto;
          margin: 0 auto; }
    .block.block-funds-timeline .fund-investment .status {
      position: absolute;
      bottom: -30px;
      color: #ffffff;
      padding-top: 10px; }

/*
Global Feature
*/
@media (min-width: 992px) {
  .block.block-global-stats.o-bg-blue {
    background-image: linear-gradient(to bottom, #1D314D 0%, #1D314D 70%, #1D314D 70%, #1D314D 100%); } }

@media (min-width: 992px) {
  .block.block-global-stats.o-bg-grey {
    background-image: linear-gradient(to bottom, #1D314D 0%, #1D314D 70%, #E7EDEC 70%, #E7EDEC 100%); } }

.block.block-global-stats.o-bg-white {
  background-image: linear-gradient(to bottom, #1D314D 0%, #1D314D 70%, #ffffff 70%, #ffffff 100%); }

.block.block-global-stats .global-offices {
  position: absolute;
  bottom: 10%;
  left: 50%;
  transform: translateX(-50%); }
  @media (max-width: 991px) {
    .block.block-global-stats .global-offices {
      transform: translateX(-50%) scale(0.8); } }
  @media (max-width: 767px) {
    .block.block-global-stats .global-offices {
      display: none; } }

.block.block-global-stats .banner {
  display: flex;
  text-align: center;
  align-items: center; }
  .block.block-global-stats .banner::before, .block.block-global-stats .banner::after {
    display: block;
    content: "";
    width: 50%;
    background: rgba(255, 255, 255, 0.2);
    height: 1px; }
  .block.block-global-stats .banner img {
    width: 30px;
    margin: 0 20px; }
    @media (max-width: 991px) {
      .block.block-global-stats .banner img {
        width: 15px; } }

.block.block-global-stats .cities {
  list-style-type: none;
  padding: 0;
  margin: 20px auto 0 auto;
  display: flex;
  justify-content: center;
  align-items: center; }
  .block.block-global-stats .cities li {
    display: flex;
    padding: 0;
    margin: 0 5px;
    white-space: nowrap; }
    .block.block-global-stats .cities li:first-of-type {
      margin-left: 0; }
      .block.block-global-stats .cities li:first-of-type::before {
        display: none; }
    .block.block-global-stats .cities li:last-of-type {
      margin-right: 0; }
    .block.block-global-stats .cities li::before {
      display: block;
      margin: 0 10px 0 0;
      color: #ffffff;
      transform: none;
      font-size: 20px; }

.block.block-global-stats .start-stats .global-stats {
  opacity: 1; }

.block.block-global-stats .global-stats-wrapper {
  position: relative;
  grid-column-start: 1;
  grid-column-end: 13;
  grid-row-start: 1;
  grid-row-end: 1; }
  @media (max-width: 991px) {
    .block.block-global-stats .global-stats-wrapper {
      margin: -35px;
      margin-top: 20px; } }

@media (max-width: 767px) {
  .block.block-global-stats .image {
    position: absolute;
    z-index: -1;
    height: 100%;
    width: 100vw;
    overflow: hidden; }
    .block.block-global-stats .image img {
      height: 100%;
      width: auto;
      object-fit: cover;
      object-position: center; } }

.block.block-global-stats .global-stats {
  transition: opacity 5s cubic-bezier(0.19, 1, 0.22, 1);
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  text-align: center;
  z-index: 20;
  display: flex;
  flex-direction: row;
  align-content: stretch;
  align-items: stretch;
  width: calc(100% - 40px); }
  @media (max-width: 767px) {
    .block.block-global-stats .global-stats {
      position: static;
      transform: none;
      display: block;
      text-align: center;
      margin: 0 auto;
      width: auto;
      padding: 40px 0; } }

.block.block-global-stats .global-stat {
  text-align: center;
  flex-grow: 1;
  width: 33%; }
  @media (max-width: 767px) {
    .block.block-global-stats .global-stat {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: auto; }
      .block.block-global-stats .global-stat:last-of-type {
        margin-bottom: 0; } }
  .block.block-global-stats .global-stat .stat-headline {
    color: #ffffff;
    display: block;
    margin: 0 auto 10px auto;
    text-align: center;
    white-space: nowrap; }
    @media (min-width: 992px) and (max-width: 1199px) {
      .block.block-global-stats .global-stat .stat-headline {
        font-size: 1.5vw; } }
  .block.block-global-stats .global-stat .stat-figure {
    text-align: center;
    display: block; }
    @media (min-width: 992px) and (max-width: 1199px) {
      .block.block-global-stats .global-stat .stat-figure {
        font-size: 6vw !important; } }

/*
Image Gallery
*/
.block.block-image-gallery .top {
  margin-bottom: 40px; }

.block.block-image-gallery .top .inner {
  grid-column-start: 2;
  grid-column-end: 6;
  grid-row-start: 1;
  grid-row-end: 1; }
  .block.block-image-gallery .top .inner p {
    width: 100%; }

.block.block-image-gallery .content {
  display: block;
  width: 100vw;
  max-width: 100vw;
  overflow-x: hidden;
  margin-left: 0;
  margin-right: 0; }

.block.block-image-gallery .flickity-button {
  top: 50%;
  background-color: transparent; }
  .block.block-image-gallery .flickity-button.flickity-prev-next-button.previous {
    left: 20px; }
  .block.block-image-gallery .flickity-button.flickity-prev-next-button.next {
    right: 30px; }
  .block.block-image-gallery .flickity-button svg {
    display: none; }

.block.block-image-gallery .carousel-cell {
  width: 100%;
  max-width: 1200px;
  margin-right: 25px; }
  .block.block-image-gallery .carousel-cell .title {
    text-align: center;
    opacity: 0;
    margin-top: 40px;
    transition: all 600ms cubic-bezier(0.19, 1, 0.22, 1);
    display: none; }
  .block.block-image-gallery .carousel-cell.is-selected .title {
    opacity: 1; }

/*
Images -> Grid
*/
.block.block-images-grid .content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start; }
  @media (max-width: 767px) {
    .block.block-images-grid .content {
      display: block; } }

.block.block-images-grid[data-grid-columns="1"] .item {
  width: calc((100% / 1) - 20px); }
  @media (max-width: 991px) {
    .block.block-images-grid[data-grid-columns="1"] .item {
      width: calc(50% - (20px / 2)); } }
  @media (max-width: 767px) {
    .block.block-images-grid[data-grid-columns="1"] .item {
      width: 100%; } }

.block.block-images-grid[data-grid-columns="2"] .item {
  width: calc((100% / 2) - 20px); }
  @media (max-width: 991px) {
    .block.block-images-grid[data-grid-columns="2"] .item {
      width: calc(50% - (20px / 2)); } }
  @media (max-width: 767px) {
    .block.block-images-grid[data-grid-columns="2"] .item {
      width: 100%; } }

.block.block-images-grid[data-grid-columns="3"] .item {
  width: calc((100% / 3) - 20px); }
  @media (max-width: 991px) {
    .block.block-images-grid[data-grid-columns="3"] .item {
      width: calc(50% - (20px / 2)); } }
  @media (max-width: 767px) {
    .block.block-images-grid[data-grid-columns="3"] .item {
      width: 100%; } }

.block.block-images-grid[data-grid-columns="4"] .item {
  width: calc((100% / 4) - 20px); }
  @media (max-width: 991px) {
    .block.block-images-grid[data-grid-columns="4"] .item {
      width: calc(50% - (20px / 2)); } }
  @media (max-width: 767px) {
    .block.block-images-grid[data-grid-columns="4"] .item {
      width: 100%; } }

.block.block-images-grid[data-grid-columns="5"] .item {
  width: calc((100% / 5) - 20px); }
  @media (max-width: 991px) {
    .block.block-images-grid[data-grid-columns="5"] .item {
      width: calc(50% - (20px / 2)); } }
  @media (max-width: 767px) {
    .block.block-images-grid[data-grid-columns="5"] .item {
      width: 100%; } }

.block.block-images-grid .item {
  margin-bottom: 40px; }

/*
In Numbers
*/
.block.block-in-numbers {
  position: relative; }
  .block.block-in-numbers::before {
    display: block;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    width: 100%;
    height: 70%;
    z-index: 0;
    top: 0;
    opacity: .3;
    background-position: 50% 20%;
    background-repeat: no-repeat;
    background-size: 80%;
    animation: halo-in 2s cubic-bezier(0.19, 1, 0.22, 1) 1s forwards;
    border-radius: 100%; }
  .block.block-in-numbers.o-style-grey::before {
    background-image: url("/assets/media/shapes/shape-arch-blue.svg"); }
  .block.block-in-numbers.o-style-green::before {
    background-image: url("/assets/media/shapes/shape-arch-blue.svg"); }
  .block.block-in-numbers.o-style-green .stat-figure {
    color: #ffffff !important; }
  .block.block-in-numbers.o-style-blue::before {
    opacity: .7;
    background-image: url("/assets/media/shapes/shape-arch-orange.svg"); }
  @media (max-width: 767px) {
    .block.block-in-numbers {
      padding-top: 80px; }
      .block.block-in-numbers::before {
        background-size: contain; } }
  .block.block-in-numbers .content {
    padding-bottom: 160px !important; }
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      .block.block-in-numbers .content {
        display: flex;
        justify-content: space-between; } }
    @media (min-width: 992px) {
      .block.block-in-numbers .content:nth-child(1) {
        padding-top: 160px !important; } }
    @media (max-width: 991px) {
      .block.block-in-numbers .content {
        padding-bottom: 80px !important; } }
    @media (max-width: 767px) {
      .block.block-in-numbers .content {
        padding-bottom: 20px !important; } }
  .block.block-in-numbers .column {
    grid-column-start: auto;
    grid-column-end: span 3;
    grid-row-start: 1;
    grid-row-end: 1; }
  .block.block-in-numbers .top {
    margin-bottom: 20px; }
    .block.block-in-numbers .top + .content .column {
      grid-column-start: auto;
      grid-column-end: span 4;
      grid-row-start: 2;
      grid-row-end: 2; }
    .block.block-in-numbers .top + .content .stat {
      border-left: 2px solid #ED5436;
      padding-left: 30px; }
    .block.block-in-numbers .top + .content .stat-fact {
      text-transform: uppercase; }
    .block.block-in-numbers .top + .content .stat-details {
      padding-right: 60px; }
  @media (min-width: 768px) and (max-width: 991px) {
    .block.block-in-numbers .stat {
      display: flex;
      flex-wrap: wrap; }
    .block.block-in-numbers .stat-figure {
      align-self: flex-end;
      text-align: left;
      width: 100%; }
    .block.block-in-numbers .stat-fact {
      align-self: flex-start;
      margin-bottom: 40px; } }
  @media (max-width: 767px) {
    .block.block-in-numbers .title {
      padding-bottom: 20px; }
    .block.block-in-numbers .stat {
      display: flex;
      margin-bottom: 40px;
      align-items: center; }
    .block.block-in-numbers .stat-figure {
      flex-basis: 60% !important;
      margin-left: 20px;
      margin-bottom: 0; } }
  @media (max-width: 767px) and (max-width: 767px) {
    .block.block-in-numbers .stat-figure {
      font-size: 7vw !important; } }
  @media (max-width: 767px) {
    .block.block-in-numbers .stat-fact {
      flex: 1;
      margin-bottom: 0; } }

.block.block-investment-grid .top {
  display: block;
  position: relative;
  z-index: 1; }
  .block.block-investment-grid .top .inner {
    margin-bottom: 40px; }
  .block.block-investment-grid .top .result-count {
    grid-column-start: 1;
    grid-column-end: 6;
    grid-row-start: 2;
    grid-row-end: 2;
    align-self: flex-end;
    opacity: .5;
    margin-bottom: 8px; }
  .block.block-investment-grid .top .filter {
    grid-column-start: 10;
    grid-column-end: 13;
    grid-row-start: 2;
    grid-row-end: 2; }

.block.block-investment-grid .content {
  grid-row-gap: 40px; }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .block.block-investment-grid .content {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between; } }

.block.block-investment-grid .investment-thumb {
  grid-column-start: auto;
  grid-column-end: span 3;
  grid-row-start: auto;
  grid-row-end: auto; }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .block.block-investment-grid .investment-thumb {
      margin-bottom: 40px; } }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .block.block-investment-grid .investment-thumb {
      width: calc(100% / 4 - 25px); } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .block.block-investment-grid .investment-thumb {
      grid-column-start: auto;
      grid-column-end: span 4;
      grid-row-start: auto;
      grid-row-end: auto; } }
  @media all and (min-width: 992px) and (max-width: 1199px) and (-ms-high-contrast: none), (min-width: 992px) and (max-width: 1199px) and (-ms-high-contrast: active) {
    .block.block-investment-grid .investment-thumb {
      width: calc(100% / 3 - 25px); } }
  @media (min-width: 768px) and (max-width: 991px) {
    .block.block-investment-grid .investment-thumb {
      grid-column-start: auto;
      grid-column-end: span 6;
      grid-row-start: auto;
      grid-row-end: auto; } }
  @media all and (min-width: 768px) and (max-width: 991px) and (-ms-high-contrast: none), (min-width: 768px) and (max-width: 991px) and (-ms-high-contrast: active) {
    .block.block-investment-grid .investment-thumb {
      width: calc(100% / 6 - 25px); } }
  @media (max-width: 767px) {
    .block.block-investment-grid .investment-thumb {
      margin-bottom: 25px; } }
  .block.block-investment-grid .investment-thumb, .block.block-investment-grid .investment-thumb a, .block.block-investment-grid .investment-thumb .inner {
    height: 100%; }

.block.block-investment-grid .bottom {
  margin-top: 80px;
  padding-bottom: 80px; }

/*
Investment Map
*/
.block.block-investment-map .map-states {
  grid-column-start: 1;
  grid-column-end: 4;
  grid-row-start: 1;
  grid-row-end: 1; }
  @media (max-width: 991px) {
    .block.block-investment-map .map-states {
      grid-column-start: 1;
      grid-column-end: 13;
      grid-row-start: 1;
      grid-row-end: 1;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-top: 20px;
      margin-bottom: 20px; } }

.block.block-investment-map .map-state {
  opacity: .5;
  cursor: pointer;
  transition: opacity 0.3s ease-in-out; }
  @media (max-width: 991px) {
    .block.block-investment-map .map-state {
      width: calc(50% - 20px); } }
  .block.block-investment-map .map-state:hover, .block.block-investment-map .map-state.active {
    opacity: 1; }
  .block.block-investment-map .map-state:not(:last-of-type) {
    margin-bottom: 40px; }
  .block.block-investment-map .map-state .title {
    color: #ED5436;
    margin-bottom: 10px; }

.block.block-investment-map .map-display {
  grid-column-start: 5;
  grid-column-end: 13;
  grid-row-start: 1;
  grid-row-end: 1;
  height: 700px; }
  @media (max-width: 991px) {
    .block.block-investment-map .map-display {
      grid-column-start: 1;
      grid-column-end: 13;
      grid-row-start: 2;
      grid-row-end: 2; } }

/*
Investment Promoter
*/
.block-investment-promoter .content .column {
  grid-column-start: 1;
  grid-column-end: 4;
  grid-row-start: 1;
  grid-row-end: 1;
  padding-right: 20px; }
  @media (max-width: 1199px) {
    .block-investment-promoter .content .column {
      grid-column-start: 1;
      grid-column-end: 13;
      grid-row-start: 1;
      grid-row-end: 1;
      margin-bottom: 20px; } }

.block-investment-promoter .investment-list {
  grid-column-start: 4;
  grid-column-end: 13;
  grid-row-start: 1;
  grid-row-end: 1; }
  @media (max-width: 1199px) {
    .block-investment-promoter .investment-list {
      grid-column-start: 1;
      grid-column-end: 13;
      grid-row-start: 2;
      grid-row-end: 2; } }
  @media (max-width: 767px) {
    .block-investment-promoter .investment-list {
      margin-top: 70px; } }
  .block-investment-promoter .investment-list .carousel-panes {
    margin-bottom: 40px; }
  .block-investment-promoter .investment-list .carousel-pane.active {
    display: flex;
    flex-direction: row;
    animation: slideup-and-fade-in 3s normal forwards cubic-bezier(0.19, 1, 0.22, 1); }
    @media (max-width: 767px) {
      .block-investment-promoter .investment-list .carousel-pane.active {
        display: block; } }
    .block-investment-promoter .investment-list .carousel-pane.active.hide {
      animation: fade-out 0.5s normal forwards cubic-bezier(0.19, 1, 0.22, 1); }
  .block-investment-promoter .investment-list[data-display-num="2"] .investment-thumb:nth-of-type(2n) {
    margin-right: 0; }
  .block-investment-promoter .investment-list[data-display-num="2"] .investment-thumb:nth-of-type(n + 3) {
    display: none;
    opacity: 0; }
  .block-investment-promoter .investment-list[data-display-num="3"] .investment-thumb:nth-of-type(3n) {
    margin-right: 0; }
  .block-investment-promoter .investment-list[data-display-num="3"] .investment-thumb:nth-of-type(n + 4) {
    display: none;
    opacity: 0; }
  .block-investment-promoter .investment-list .investment-thumb {
    flex: 1;
    margin-right: 25px; }
    .block-investment-promoter .investment-list .investment-thumb:only-of-type {
      max-width: 50%; }
    @media (max-width: 767px) {
      .block-investment-promoter .investment-list .investment-thumb {
        margin-right: 0; } }
    @media (max-width: 767px) {
      .block-investment-promoter .investment-list .investment-thumb {
        margin-bottom: 20px; } }
  .block-investment-promoter .investment-list .carousel-pagination {
    width: 50%; }
    @media (max-width: 767px) {
      .block-investment-promoter .investment-list .carousel-pagination {
        width: 100%; } }

/*
Jobs Promoter
*/
.block-jobs-promoter .content .no-jobs {
  grid-column-start: 1;
  grid-column-end: 7;
  grid-row-start: 1;
  grid-row-end: 1; }

.block-jobs-promoter .job-tile {
  grid-column-start: auto;
  grid-column-end: span 4;
  grid-row-start: auto;
  grid-row-end: auto; }

/*
Key Data
*/
.block.block-key-data .content {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  align-content: flex-start;
  padding-top: 60px !important;
  padding-bottom: 60px !important; }
  @media (max-width: 1199px) {
    .block.block-key-data .content {
      padding-left: 40px !important; } }
  @media (max-width: 767px) {
    .block.block-key-data .content {
      display: block; } }

.block.block-key-data .intro {
  min-width: 250px; }
  .block.block-key-data .intro h2 {
    margin-bottom: 20px; }
  .block.block-key-data .intro .logo {
    width: 75%; }
    .block.block-key-data .intro .logo img {
      max-height: 100px;
      max-width: 100%;
      width: auto; }
  @media (max-width: 767px) {
    .block.block-key-data .intro {
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding-bottom: 40px;
      margin-bottom: 40px;
      border-bottom: 1px solid rgba(29, 49, 77, 0.2); }
      .block.block-key-data .intro .logo {
        width: 50%; } }

.block.block-key-data .data-items {
  width: 100%;
  display: flex;
  flex-wrap: wrap; }

.block.block-key-data .data-item {
  width: 20%;
  min-width: max-content;
  margin-bottom: 20px; }
  .block.block-key-data .data-item .title, .block.block-key-data .data-item .value {
    white-space: nowrap;
    margin-bottom: 10px; }
  .block.block-key-data .data-item .value {
    font-size: 1.1875rem; }
  @media (min-width: 768px) and (max-width: 991px) {
    .block.block-key-data .data-item {
      width: 50%; } }
  @media (min-width: 768px) {
    .block.block-key-data .data-item {
      padding: 0 30px;
      border-left: 1px solid rgba(29, 49, 77, 0.2); } }
  @media (max-width: 767px) {
    .block.block-key-data .data-item {
      display: inline-block;
      margin-bottom: 40px;
      width: 50%; }
      .block.block-key-data .data-item .title {
        margin-bottom: 10px; } }

.block.block-logo-gallery .logos {
  grid-column-start: 1;
  grid-column-end: 13;
  grid-row-start: auto;
  grid-row-end: auto;
  display: flex;
  flex-wrap: wrap; }
  @media (max-width: 767px) {
    .block.block-logo-gallery .logos {
      justify-content: space-between; } }

.block.block-logo-gallery .logo {
  width: calc(100% / 5);
  margin-right: 40px; }
  @media (max-width: 767px) {
    .block.block-logo-gallery .logo {
      width: calc(48%);
      margin-right: 0;
      margin-bottom: 20px; } }

.block.block-logo-gallery .title {
  opacity: .5; }

.block.block-news-grid .top {
  display: block;
  position: relative;
  z-index: 1; }
  .block.block-news-grid .top h3 {
    grid-column-start: 1;
    grid-column-end: 4;
    grid-row-start: 1;
    grid-row-end: 1;
    margin-bottom: 0; }
  .block.block-news-grid .top .result-count {
    grid-column-start: 1;
    grid-column-end: 6;
    grid-row-start: 2;
    grid-row-end: 2;
    align-self: flex-end;
    opacity: .5;
    margin-bottom: 8px; }
  .block.block-news-grid .top .filter {
    grid-column-start: 9;
    grid-column-end: 13;
    grid-row-start: 2;
    grid-row-end: 2; }

.block.block-news-grid .content {
  margin-bottom: 60px;
  padding-top: 40px !important; }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .block.block-news-grid .content {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between; } }

.block.block-news-grid .news-article-thumb {
  margin-bottom: 25px;
  grid-column-start: auto;
  grid-column-end: span 4;
  grid-row-start: auto;
  grid-row-end: auto; }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .block.block-news-grid .news-article-thumb {
      width: calc(100% / 3 - 25px); } }
  @media (max-width: 991px) {
    .block.block-news-grid .news-article-thumb {
      grid-column-start: auto;
      grid-column-end: span 6;
      grid-row-start: auto;
      grid-row-end: auto; } }
  @media all and (max-width: 991px) and (-ms-high-contrast: none), (max-width: 991px) and (-ms-high-contrast: active) {
    .block.block-news-grid .news-article-thumb {
      width: calc(100% / 2 - 25px); } }
  .block.block-news-grid .news-article-thumb .title {
    color: #ffffff; }

.block.block-news-grid .news-article-thumb.no-image:nth-of-type(4n-6) .inner {
  background-image: url("/assets/media/shapes/shape-t-green.svg") !important;
  background-size: 210%;
  background-position: 92% 55%; }

.block.block-news-grid .news-article-thumb.no-image:nth-of-type(11n-6) .inner {
  background-image: url("/assets/media/shapes/shape-o-offwhite.svg") !important;
  background-size: 200%;
  background-position: 50% 50px; }

/*
News Promoter
*/
.block-news-promoter .top, .block-news-promoter .content {
  padding-left: 0;
  padding-right: 0; }

.block-news-promoter .bottom {
  padding-top: 80px; }
  @media (max-width: 767px) {
    .block-news-promoter .bottom {
      padding-top: 0; } }

.block-news-promoter .news-article-thumb {
  grid-column-start: auto;
  grid-column-end: span 4;
  grid-row-start: 1;
  grid-row-end: 1; }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .block-news-promoter .news-article-thumb {
      margin-right: 25px; }
      .block-news-promoter .news-article-thumb:nth-of-type(1) {
        grid-column-start: 1;
        grid-column-end: 5;
        grid-row-start: 1;
        grid-row-end: 1; }
      .block-news-promoter .news-article-thumb:nth-of-type(2) {
        grid-column-start: 5;
        grid-column-end: 9;
        grid-row-start: 1;
        grid-row-end: 1; }
      .block-news-promoter .news-article-thumb:nth-of-type(3) {
        grid-column-start: 9;
        grid-column-end: 13;
        grid-row-start: 1;
        grid-row-end: 1; } }
  @media (max-width: 991px) {
    .block-news-promoter .news-article-thumb {
      grid-column-start: auto;
      grid-column-end: span 6;
      grid-row-start: 1;
      grid-row-end: 1; } }
  @media all and (max-width: 991px) and (-ms-high-contrast: none), (max-width: 991px) and (-ms-high-contrast: active) {
    .block-news-promoter .news-article-thumb {
      margin-right: 25px; }
      .block-news-promoter .news-article-thumb:nth-of-type(1) {
        grid-column-start: 1;
        grid-column-end: 7;
        grid-row-start: 1;
        grid-row-end: 1; }
      .block-news-promoter .news-article-thumb:nth-of-type(2) {
        grid-column-start: 7;
        grid-column-end: 13;
        grid-row-start: 1;
        grid-row-end: 1; } }
  @media (max-width: 991px) {
      .block-news-promoter .news-article-thumb:nth-of-type(3) {
        display: none; } }
  @media (max-width: 767px) {
    .block-news-promoter .news-article-thumb {
      margin-bottom: 20px; } }

.block.block-office-tiles {
  background-image: linear-gradient(white, white);
  background-repeat: no-repeat;
  background-position: 0% 160px;
  background-size: 100% calc(100% + 80px); }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .block.block-office-tiles .content {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between; } }
  .block.block-office-tiles .office-thumb {
    margin-bottom: 0; }
    @media (max-width: 767px) {
      .block.block-office-tiles .office-thumb {
        margin-bottom: 40px; } }
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      .block.block-office-tiles .office-thumb {
        width: 40%;
        margin-top: 0 !important;
        margin-bottom: 80px; }
        .block.block-office-tiles .office-thumb:nth-of-type(even) {
          margin-top: 120px !important; } }
    .block.block-office-tiles .office-thumb:nth-of-type(odd) {
      grid-column-start: 1;
      grid-column-end: 6;
      grid-row-start: auto;
      grid-row-end: auto; }
      @media (max-width: 991px) {
        .block.block-office-tiles .office-thumb:nth-of-type(odd) {
          grid-column-start: 1;
          grid-column-end: 7;
          grid-row-start: auto;
          grid-row-end: auto; } }
      @media (min-width: 768px) {
        .block.block-office-tiles .office-thumb:nth-of-type(odd):last-child {
          margin-top: -240px; } }
    .block.block-office-tiles .office-thumb:nth-of-type(even) {
      grid-column-start: 8;
      grid-column-end: 13;
      grid-row-start: auto;
      grid-row-end: auto; }
      @media (max-width: 991px) {
        .block.block-office-tiles .office-thumb:nth-of-type(even) {
          grid-column-start: 7;
          grid-column-end: 13;
          grid-row-start: auto;
          grid-row-end: auto; } }
      @media (min-width: 768px) {
        .block.block-office-tiles .office-thumb:nth-of-type(even) {
          margin-top: 25%; }
          .block.block-office-tiles .office-thumb:nth-of-type(even):nth-of-type(2) {
            margin-top: 50%; } }

/*
One Column
*/
.block-one-column .content {
  display: block; }

.block-one-column.o-size-normal .large {
  width: 75%; }

.block-one-column.o-size-narrow .top {
  margin-bottom: 40px; }
  .block-one-column.o-size-narrow .top .inner {
    grid-column-start: 2;
    grid-column-end: 10;
    grid-row-start: auto;
    grid-row-end: auto; }

.block-one-column.o-size-narrow .content * {
  grid-column-start: 2;
  grid-column-end: 10;
  grid-row-start: auto;
  grid-row-end: auto; }
  @media (max-width: 991px) {
    .block-one-column.o-size-narrow .content * {
      grid-column-start: 2;
      grid-column-end: 12;
      grid-row-start: auto;
      grid-row-end: auto; } }

.block.block-people-highlight.o-size-narrow .top .inner {
  grid-column-start: 2;
  grid-column-end: 12;
  grid-row-start: 1;
  grid-row-end: 1; }

.block.block-people-highlight.o-size-narrow .people {
  grid-column-start: 2;
  grid-column-end: 12;
  grid-row-start: 1;
  grid-row-end: 1; }

.block.block-people-highlight .people {
  grid-column-start: 1;
  grid-column-end: 13;
  grid-row-start: 1;
  grid-row-end: 1;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap; }

.block.block-people-highlight .person-thumb {
  display: flex;
  align-items: center;
  align-content: flex-start;
  margin: 40px 80px 20px 0;
  width: max-content; }
  @media (max-width: 991px) {
    .block.block-people-highlight .person-thumb {
      max-width: none;
      width: 50%; } }
  @media (max-width: 767px) {
    .block.block-people-highlight .person-thumb {
      max-width: none;
      width: 100%; } }
  .block.block-people-highlight .person-thumb:nth-of-type(-n+3) {
    margin-top: 0; }
  .block.block-people-highlight .person-thumb .portrait {
    overflow: hidden;
    width: 75px;
    height: 75px;
    webkit-mask-image: -webkit-radial-gradient(white, black); }
    .block.block-people-highlight .person-thumb .portrait.fx-gradient {
      background-position: center center; }
    .block.block-people-highlight .person-thumb .portrait img {
      width: auto;
      height: 100%;
      width: 100%;
      overflow: hidden;
      object-fit: cover;
      webkit-mask-image: -webkit-radial-gradient(white, black); }
      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        .block.block-people-highlight .person-thumb .portrait img {
          width: auto;
          transform: translateX(-16%); } }
  .block.block-people-highlight .person-thumb .name, .block.block-people-highlight .person-thumb .role {
    color: #ffffff;
    margin-left: 10px; }
  .block.block-people-highlight .person-thumb .name {
    white-space: nowrap; }

/*
Pre-Footer
*/
.block-pre-footer .previous {
  grid-column-start: 2;
  grid-column-end: 6;
  grid-row-start: 1;
  grid-row-end: 1; }
  @media (max-width: 991px) {
    .block-pre-footer .previous {
      grid-column-start: 2;
      grid-column-end: 7;
      grid-row-start: 1;
      grid-row-end: 1; } }
  @media (max-width: 767px) {
    .block-pre-footer .previous {
      margin-bottom: 80px; } }

.block-pre-footer .next {
  grid-column-start: 8;
  grid-column-end: 12;
  grid-row-start: 1;
  grid-row-end: 1; }
  @media (max-width: 991px) {
    .block-pre-footer .next {
      grid-column-start: 7;
      grid-column-end: 12;
      grid-row-start: 1;
      grid-row-end: 1; } }

.block.block-press-contacts .contact-group {
  grid-column-start: 1;
  grid-column-end: 13;
  grid-row-start: auto;
  grid-row-end: auto;
  display: flex; }
  .block.block-press-contacts .contact-group .title {
    width: 40%;
    margin-right: 25px; }
  .block.block-press-contacts .contact-group:not(:first-of-type) {
    padding-top: 40px;
    border-top: 1px solid #D3D3D3; }

.block.block-press-contacts .group-contacts {
  display: flex;
  flex-wrap: wrap;
  width: 60%;
  margin-bottom: 40px; }
  @media (max-width: 767px) {
    .block.block-press-contacts .group-contacts {
      flex-direction: column; } }

.block.block-press-contacts .group-contact {
  width: calc(50% - 25px);
  margin-right: 25px;
  margin-top: 40px; }
  @media (max-width: 767px) {
    .block.block-press-contacts .group-contact {
      width: 100%; }
      .block.block-press-contacts .group-contact:not(:last-of-type) {
        margin-bottom: 40px; } }
  .block.block-press-contacts .group-contact:nth-of-type(-n+2) {
    margin-top: 0; }
  .block.block-press-contacts .group-contact:nth-of-type(even) {
    margin-right: 0; }
  .block.block-press-contacts .group-contact a {
    display: block; }

.block.block-press-information .information-section {
  margin-bottom: 80px; }
  .block.block-press-information .information-section:nth-of-type(odd) {
    grid-column-start: 2;
    grid-column-end: span 4;
    grid-row-start: auto;
    grid-row-end: auto; }
  .block.block-press-information .information-section:nth-of-type(even) {
    grid-column-start: 7;
    grid-column-end: span 4;
    grid-row-start: auto;
    grid-row-end: auto; }
  @media (max-width: 1199px) {
    .block.block-press-information .information-section {
      grid-column-start: 2!important;
      grid-column-end: 12!important;
      grid-row-start: auto!important;
      grid-row-end: auto!important; } }

/*
Quote
*/
.block.block-quote .content {
  position: relative; }
  .block.block-quote .content .carousel-control {
    position: absolute;
    top: 50%;
    transform: translateY(-50%); }
    .block.block-quote .content .carousel-control[role="Next"] {
      right: 0; }
    .block.block-quote .content .carousel-control[role="Previous"] {
      left: 0; }

.block.block-quote .quote.carousel-pane {
  display: none; }
  .block.block-quote .quote.carousel-pane.active {
    display: flex;
    animation: slideup-and-fade-in 2s normal forwards cubic-bezier(0.19, 1, 0.22, 1); }
    .block.block-quote .quote.carousel-pane.active.hide {
      animation: fade-out 0.5s normal forwards cubic-bezier(0.19, 1, 0.22, 1); }

.block.block-quote.o-style-blue .quote cite .role,
.block.block-quote.o-style-blue .quote blockquote {
  color: #ffffff !important; }

.block.block-quote .quotes {
  grid-column-start: 3;
  grid-column-end: 11;
  grid-row-start: 1;
  grid-row-end: 1; }
  @media (max-width: 767px) {
    .block.block-quote .quotes {
      width: 65%;
      margin: 0 auto; } }
  .block.block-quote .quotes blockquote {
    color: #1D314D !important; }
  .block.block-quote .quotes cite a {
    text-decoration: none;
    color: inherit;
    display: inline-block; }
    .block.block-quote .quotes cite a:not(.portrait) {
      transform: translateY(-20px); }
  .block.block-quote .quotes cite .portrait {
    display: inline-block;
    width: 80px;
    height: 80px;
    margin-right: 25px; }
    .block.block-quote .quotes cite .portrait .o-silhouette {
      background: #E7EDEC; }
      .block.block-quote .quotes cite .portrait .o-silhouette img {
        width: auto;
        height: 80px;
        transform: translateX(-20px); }
    .block.block-quote .quotes cite .portrait img {
      vertical-align: bottom; }
    @media (max-width: 767px) {
      .block.block-quote .quotes cite .portrait {
        margin-bottom: 40px; } }
  .block.block-quote .quotes cite .name, .block.block-quote .quotes cite .role {
    display: block;
    font-size: 1rem; }
  .block.block-quote .quotes cite .name {
    font-size: 1.1875rem;
    color: #ED5436;
    text-transform: uppercase; }
  .block.block-quote .quotes cite .role {
    color: #1D314D;
    text-transform: capitalize; }
  .block.block-quote .quotes .carousel-controls {
    left: 50%;
    bottom: 0; }

/*
SDG List
*/
.block.block-sdg-list {
  background: #ED5436 !important; }
  .block.block-sdg-list .content {
    display: flex;
    justify-content: flex-start;
    align-content: flex-start;
    align-items: center;
    padding-top: 60px !important;
    padding-bottom: 60px !important; }
    @media (max-width: 1199px) {
      .block.block-sdg-list .content {
        padding-left: 40px !important; } }
    @media (max-width: 767px) {
      .block.block-sdg-list .content {
        display: block; } }
  .block.block-sdg-list .intro {
    min-width: 220px; }
    @media (max-width: 767px) {
      .block.block-sdg-list .intro {
        width: calc(100% - 20px);
        padding-bottom: 20px;
        margin-bottom: 40px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.2); } }
    .block.block-sdg-list .intro .large {
      color: #ffffff;
      margin: 0; }
      @media (max-width: 767px) {
        .block.block-sdg-list .intro .large {
          margin-bottom: 10px; } }
  .block.block-sdg-list .sdg-goals {
    width: 100%;
    display: flex; }
  .block.block-sdg-list .sdg {
    max-width: 110px;
    margin-bottom: 0;
    margin-right: 20px; }
    .block.block-sdg-list .sdg img {
      display: block; }

.block-team-and-news h3 {
  margin-bottom: 40px; }

.block-team-and-news .the-team {
  grid-column-start: 1;
  grid-column-end: 6;
  grid-row-start: 1;
  grid-row-end: 1; }
  .block-team-and-news .the-team:only-child {
    grid-column-start: 3;
    grid-column-end: 11;
    grid-row-start: 1;
    grid-row-end: 1; }
  .block-team-and-news .the-team .people {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; }
  .block-team-and-news .the-team .person-thumb {
    margin-bottom: 25px;
    color: #ffffff;
    width: calc(100% / 2 - 25px); }
    @media (max-width: 991px) {
      .block-team-and-news .the-team .person-thumb {
        width: 100%; } }
    .block-team-and-news .the-team .person-thumb .role {
      display: none; }

.block-team-and-news .related-news {
  grid-column-start: 7;
  grid-column-end: 13;
  grid-row-start: 1;
  grid-row-end: 1; }
  @media (max-width: 767px) {
    .block-team-and-news .related-news {
      margin-top: 80px; } }
  .block-team-and-news .related-news .articles {
    margin-bottom: 25px; }
    @media (min-width: 992px) {
      .block-team-and-news .related-news .articles[data-num-articles="2"] {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 25px; } }
    @media (min-width: 768px) and (max-width: 991px) {
      .block-team-and-news .related-news .articles .news-article-thumb:nth-child(2) {
        display: none; } }

.block.block-team-grid {
  margin-top: -80px;
  background-image: linear-gradient(white, white);
  background-repeat: no-repeat;
  background-position: 0% 200px;
  background-size: 100% calc(100% + 80px); }
  @media (max-width: 991px) {
    .block.block-team-grid {
      background-position: 0% 320px; } }
  .block.block-team-grid .top {
    display: block;
    position: relative;
    z-index: 1; }
  .block.block-team-grid .top .inner {
    margin-bottom: 40px; }
    @media (max-width: 991px) {
      .block.block-team-grid .top .inner .toggle-team, .block.block-team-grid .top .inner .search-team {
        margin-bottom: 20px; } }
    .block.block-team-grid .top .inner .toggle-team {
      grid-column-start: 1;
      grid-column-end: 4;
      grid-row-start: 1;
      grid-row-end: 1;
      display: flex;
      flex-direction: column; }
      .block.block-team-grid .top .inner .toggle-team a {
        text-decoration: none;
        font-weight: 500; }
        .block.block-team-grid .top .inner .toggle-team a.active {
          color: #ED5436; }
      .block.block-team-grid .top .inner .toggle-team a:first-of-type::after {
        display: inline-block;
        content: "|";
        margin: 0 10px;
        color: #D3D3D3; }
    .block.block-team-grid .top .inner .search-team {
      grid-column-start: 4;
      grid-column-end: 10;
      grid-row-start: 1;
      grid-row-end: 1;
      position: relative; }
      .block.block-team-grid .top .inner .search-team .search-input {
        -webkit-appearance: none; }
        .block.block-team-grid .top .inner .search-team .search-input::-webkit-search-cancel-button {
          -webkit-appearance: none;
          cursor: pointer;
          height: 20px;
          width: 20px;
          margin-right: 6.2em;
          transform: translateY(-5px);
          background-image: url("/assets/media/graphics/close-dark.svg");
          background-size: contain;
          transition: opacity 0.3s ease-in-out; }
          .block.block-team-grid .top .inner .search-team .search-input::-webkit-search-cancel-button:hover {
            opacity: .5; }
      .block.block-team-grid .top .inner .search-team .btn-inline-search {
        position: absolute;
        right: 0;
        bottom: -12px;
        opacity: 0; }
      .block.block-team-grid .top .inner .search-team .btn-inline-search:hover,
      .block.block-team-grid .top .inner .search-team .btn-inline-search:focus,
      .block.block-team-grid .top .inner .search-team input:focus + .btn-inline-search,
      .block.block-team-grid .top .inner .search-team input:hover + .btn-inline-search {
        opacity: 1; }
    .block.block-team-grid .top .inner .filter-team {
      grid-column-start: 10;
      grid-column-end: 13;
      grid-row-start: 1;
      grid-row-end: 1; }
      @media (max-width: 991px) {
        .block.block-team-grid .top .inner .filter-team {
          margin-bottom: 20px; } }
      .block.block-team-grid .top .inner .filter-team.o-disabled {
        opacity: .4;
        pointer-events: none;
        cursor: no-drop; }
  .block.block-team-grid .content {
    grid-row-gap: 40px; }
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      .block.block-team-grid .content {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between; } }
  .block.block-team-grid .search-no-results {
    grid-column-start: 1;
    grid-column-end: span 6;
    grid-row-start: 1;
    grid-row-end: 1; }
  .block.block-team-grid .person-thumb {
    grid-column-start: auto;
    grid-column-end: span 3;
    grid-row-start: auto;
    grid-row-end: auto; }
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      .block.block-team-grid .person-thumb {
        width: calc(100% / 4 - 25px);
        margin-bottom: 20px; } }
    @media (max-width: 1199px) {
      .block.block-team-grid .person-thumb {
        grid-column-start: auto;
        grid-column-end: span 4;
        grid-row-start: auto;
        grid-row-end: auto; } }
  @media all and (max-width: 1199px) and (-ms-high-contrast: none), (max-width: 1199px) and (-ms-high-contrast: active) {
    .block.block-team-grid .person-thumb {
      width: calc(100% / 3 - 25px); } }
    @media (max-width: 991px) {
      .block.block-team-grid .person-thumb {
        grid-column-start: auto;
        grid-column-end: span 6;
        grid-row-start: auto;
        grid-row-end: auto; } }
  @media all and (max-width: 991px) and (-ms-high-contrast: none), (max-width: 991px) and (-ms-high-contrast: active) {
    .block.block-team-grid .person-thumb {
      width: calc(100% / 2 - 25px); } }
    @media (max-width: 767px) {
      .block.block-team-grid .person-thumb {
        margin-bottom: 40px; } }
  .block.block-team-grid .bottom {
    margin-top: 80px; }
  .block.block-team-grid .btn-primary {
    background: #ffffff !important;
    margin-bottom: 80px; }

/*
Three Column
*/
@media (max-width: 767px) {
  .block-three-column .column:not(:last-of-type) {
    margin-bottom: 40px; } }

.block-three-column ul, .block-three-column ol {
  columns: 1; }

.block-three-column.o-alignment-center .introduction,
.block-three-column.o-alignment-center .column {
  text-align: center; }

.block-three-column.o-size-normal .column {
  grid-column-start: auto;
  grid-column-end: span 4;
  grid-row-start: 1;
  grid-row-end: 1; }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .block-three-column.o-size-normal .column {
      padding-right: 25px; }
      .block-three-column.o-size-normal .column:nth-of-type(1) {
        grid-column-start: 1;
        grid-column-end: 5;
        grid-row-start: 1;
        grid-row-end: 1; }
      .block-three-column.o-size-normal .column:nth-of-type(2) {
        grid-column-start: 5;
        grid-column-end: 9;
        grid-row-start: 1;
        grid-row-end: 1; }
      .block-three-column.o-size-normal .column:nth-of-type(3) {
        grid-column-start: 9;
        grid-column-end: 13;
        grid-row-start: 1;
        grid-row-end: 1; } }

.block-three-column.o-size-narrow .top .inner {
  grid-column-start: 2;
  grid-column-end: 7;
  grid-row-start: 1;
  grid-row-end: 1; }

.block-three-column.o-size-narrow .column:nth-of-type(1) {
  grid-column-start: 2;
  grid-column-end: span 3;
  grid-row-start: 1;
  grid-row-end: 1; }

.block-three-column.o-size-narrow .column:nth-of-type(2) {
  grid-column-start: 5;
  grid-column-end: span 3;
  grid-row-start: 1;
  grid-row-end: 1; }

.block-three-column.o-size-narrow .column:nth-of-type(3) {
  grid-column-start: 8;
  grid-column-end: span 3;
  grid-row-start: 1;
  grid-row-end: 1; }

/*
Investment Timeline
*/
.block.block-timeline .timeline-phase {
  grid-column-start: auto;
  grid-column-end: span 2;
  grid-row-start: auto;
  grid-row-end: auto; }
  .block.block-timeline .timeline-phase h3 {
    color: #ED5436 !important; }
    .block.block-timeline .timeline-phase h3::after {
      background: #ED5436; }

/*
Two Column
*/
.block-two-column {
  position: relative;
  overflow-y: hidden;
  /*
  Column Gap
  */
  /*
  Image block positions
  */
  /*
  Normal position
  */
  /*
  Side offset
  */
  /*
  Top offset
  */
  /*
  Top and Bottom offset
  */
  /*
  Vertical Align Center
  */
  /*
  Vertical Offset
  */ }
  .block-two-column .content {
    height: max-content; }
    .block-two-column .content .column p:only-child a.btn-primary {
      display: block;
      width: max-content;
      margin-right: 0;
      margin-left: auto; }
  .block-two-column.o-gap-narrow .column:nth-child(1) {
    grid-area: 1 / 2 / 1 / 7; }
  .block-two-column.o-gap-narrow .column:nth-child(2) {
    grid-area: 1 / 7 / 1 / 12; }
  .block-two-column.o-gap-normal .column:nth-child(1) {
    grid-area: 1 / 1 / 1 / 7; }
  .block-two-column.o-gap-normal .column:nth-child(2) {
    grid-area: 1 / 8 / 1 / 13; }
  .block-two-column.o-gap-normal.o-size-narrow .column:nth-child(1) {
    grid-area: 1 / 2 / 1 / 7; }
  .block-two-column.o-gap-normal.o-size-narrow .column:nth-child(2) {
    grid-area: 1 / 8 / 1 / 12; }
  @media (max-width: 1199px) {
    .block-two-column.o-gap-normal.o-size-narrow .column:nth-child(1) {
      grid-area: 1 / 1 / 1 / 7; }
    .block-two-column.o-gap-normal.o-size-narrow .column:nth-child(2) {
      grid-area: 1 / 8 / 1 / 13; } }
  .block-two-column.o-gap-normal .top .inner {
    grid-column-start: 1;
    grid-column-end: 7;
    grid-row-start: 1;
    grid-row-end: 1; }
  .block-two-column.o-gap-normal .bottom .inner {
    grid-column-start: 1;
    grid-column-end: 12;
    grid-row-start: 1;
    grid-row-end: 1; }
  .block-two-column.o-gap-normal.o-ratio-3070 .column:nth-child(1) {
    grid-area: 1 / 2 / 1 / 5; }
  .block-two-column.o-gap-normal.o-ratio-3070 .column:nth-child(2) {
    grid-area: 1 / 6 / 1 / 12; }
  .block-two-column.o-gap-normal.o-ratio-3070 .top .inner {
    grid-column-start: 2;
    grid-column-end: 9;
    grid-row-start: 1;
    grid-row-end: 1; }
  .block-two-column.o-gap-normal.o-ratio-7030 .column:nth-child(1) {
    grid-area: 1 / 2 / 1 / 9; }
  .block-two-column.o-gap-normal.o-ratio-7030 .column:nth-child(2) {
    grid-area: 1 / 9 / 1 / 13; }
  .block-two-column.o-gap-normal.o-ratio-7030 .top .inner {
    grid-column-start: 2;
    grid-column-end: 9;
    grid-row-start: 1;
    grid-row-end: 1; }
  .block-two-column.o-gap-wide .column:nth-child(1) {
    grid-area: 1 / 1 / 1 / 6; }
  .block-two-column.o-gap-wide .column:nth-child(2) {
    grid-area: 1 / 8 / 1 / 13; }
  @media (max-width: 991px) {
    .block-two-column.o-image-right .content, .block-two-column.o-image-left .content {
      display: block; }
    .block-two-column.o-image-right .image, .block-two-column.o-image-left .image {
      position: static !important;
      width: 100% !important;
      transform: none !important; }
      .block-two-column.o-image-right .image img[data-orientation="portrait"], .block-two-column.o-image-left .image img[data-orientation="portrait"] {
        display: none; } }
  @media (max-width: 991px) {
    .block-two-column.o-image-right .image, .block-two-column.o-image-right .video {
      margin-top: 40px !important; } }
  @media (max-width: 991px) {
    .block-two-column.o-image-left .image, .block-two-column.o-image-left .video {
      margin-bottom: 40px !important; } }
  .block-two-column.o-image-position-normal.o-image-right .content > .image, .block-two-column.o-image-right.o-image-position-offset-top .content > .image,
  .block-two-column.o-image-position-normal.o-image-right .content > .video,
  .block-two-column.o-image-right.o-image-position-offset-top .content > .video {
    grid-column-start: 8;
    grid-column-end: span 5;
    grid-row-start: 1;
    grid-row-end: 1; }
  .block-two-column.o-image-position-normal.o-image-left .content > .image, .block-two-column.o-image-left.o-image-position-offset-top .content > .image,
  .block-two-column.o-image-position-normal.o-image-left .content > .video,
  .block-two-column.o-image-left.o-image-position-offset-top .content > .video {
    grid-column-start: 1;
    grid-column-end: span 6;
    grid-row-start: 1;
    grid-row-end: 1; }
  .block-two-column.o-image-position-offset-side {
    position: relative; }
    @media (min-width: 768px) {
      .block-two-column.o-image-position-offset-side .content > .image,
      .block-two-column.o-image-position-offset-side .content > .video {
        position: absolute; } }
    @media (min-width: 768px) {
      .block-two-column.o-image-position-offset-side.o-image-right .content > .image,
      .block-two-column.o-image-position-offset-side.o-image-right .content > .video, .block-two-column.o-image-position-offset-side.o-image-left .content > .image,
      .block-two-column.o-image-position-offset-side.o-image-left .content > .video {
        width: calc(50% - (25px * 2)); } }
    .block-two-column.o-image-position-offset-side.o-image-right .content > .image:nth-child(1),
    .block-two-column.o-image-position-offset-side.o-image-right .content > .video:nth-child(1) {
      left: 0; }
    .block-two-column.o-image-position-offset-side.o-image-right .content > .image:nth-child(2),
    .block-two-column.o-image-position-offset-side.o-image-right .content > .video:nth-child(2) {
      right: 0; }
    .block-two-column.o-image-position-offset-side.o-image-left .content > .image:nth-child(1),
    .block-two-column.o-image-position-offset-side.o-image-left .content > .video:nth-child(1) {
      left: 0; }
    .block-two-column.o-image-position-offset-side.o-image-left .content > .image:nth-child(2),
    .block-two-column.o-image-position-offset-side.o-image-left .content > .video:nth-child(2) {
      right: 0; }
  .block-two-column.o-image-position-offset-top {
    overflow: visible;
    padding-bottom: 20px; }
    .block-two-column.o-image-position-offset-top .content > .image,
    .block-two-column.o-image-position-offset-top .content > .video {
      transform: translateY(-160px);
      margin-bottom: -160px; }
      @media (max-width: 991px) {
        .block-two-column.o-image-position-offset-top .content > .image,
        .block-two-column.o-image-position-offset-top .content > .video {
          transform: translateY(-106.66667px) !important;
          margin-bottom: 0 !important; } }
      @media (max-width: 767px) {
        .block-two-column.o-image-position-offset-top .content > .image,
        .block-two-column.o-image-position-offset-top .content > .video {
          transform: translateY(-80px) !important; } }
  .block-two-column.o-image-position-offset-top-bottom {
    overflow: visible; }
    .block-two-column.o-image-position-offset-top-bottom.o-image-left .content > .image,
    .block-two-column.o-image-position-offset-top-bottom.o-image-left .content > .video {
      grid-column-start: 1;
      grid-column-end: span 5;
      grid-row-start: 1;
      grid-row-end: 1; }
    .block-two-column.o-image-position-offset-top-bottom.o-image-right .content > .image,
    .block-two-column.o-image-position-offset-top-bottom.o-image-right .content > .video {
      grid-column-start: 8;
      grid-column-end: span 5;
      grid-row-start: 1;
      grid-row-end: 1; }
    .block-two-column.o-image-position-offset-top-bottom .content > .image,
    .block-two-column.o-image-position-offset-top-bottom .content > .video {
      height: 0;
      position: relative;
      top: -200px;
      height: calc(100% + 320px);
      margin-bottom: 0; }
      .block-two-column.o-image-position-offset-top-bottom .content > .image img,
      .block-two-column.o-image-position-offset-top-bottom .content > .video img {
        height: 100%;
        width: 100%;
        object-fit: cover; }
  .block-two-column.o-verticalalign-center .column {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center; }
  .block-two-column.o-verticaloffset {
    overflow-y: visible; }
    .block-two-column.o-verticaloffset .content {
      padding-bottom: 0 !important;
      height: 100%; }
    .block-two-column.o-verticaloffset .column:nth-of-type(1) {
      grid-column-start: 1;
      grid-column-end: 7;
      grid-row-start: 1;
      grid-row-end: 1;
      transform: translateY(-50%); }
    .block-two-column.o-verticaloffset .column:nth-of-type(2) {
      grid-column-start: 9;
      grid-column-end: 13;
      grid-row-start: 1;
      grid-row-end: 1;
      overflow-y: hidden; }

/*
Video
*/
.block-video .content > div {
  grid-column-start: 1;
  grid-column-end: 13;
  grid-row-start: 1;
  grid-row-end: 1; }

/*
Blocks
*/
/*
Base
*/
.block .top, .block .content, .block .bottom {
  padding-left: 0;
  padding-right: 0; }

.block .top .inner {
  grid-column-start: 1;
  grid-column-end: 8;
  grid-row-start: 1;
  grid-row-end: 1; }
  .block .top .inner h3:only-child {
    margin-bottom: 20px; }
  .block .top .inner h3 + .large:last-child {
    margin-bottom: 10px; }
  @media (max-width: 1199px) {
    .block .top .inner {
      grid-column-start: 1;
      grid-column-end: 9;
      grid-row-start: 1;
      grid-row-end: 1; } }
  @media (max-width: 991px) {
    .block .top .inner {
      grid-column-start: 1;
      grid-column-end: 10;
      grid-row-start: 1;
      grid-row-end: 1; } }
  @media (min-width: 768px) {
    .block .top .inner p:not(.large) {
      width: 70%; } }

.block .bottom .inner {
  grid-column-start: 4;
  grid-column-end: 10;
  grid-row-start: 1;
  grid-row-end: 1;
  text-align: center; }

.block .video {
  overflow: hidden; }
  .block .video video {
    height: 100%;
    width: 100%; }

.o-inset-first-block {
  position: relative; }
  .o-inset-first-block::before {
    display: block;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 160px; }
  .page-style-green .o-inset-first-block::before {
    background-color: #5A8080; }
  .page-style-blue .o-inset-first-block::before {
    background-color: #1D314D; }
  .page-style-grey .o-inset-first-block::before {
    background-color: #E7EDEC; }

/*
Additional bottom padding to manage offset image blocks
*/
.block.o-padding-bottom .content {
  padding-bottom: 160px !important; }

/*
Styles
*/
.block.o-style-blue {
  background-image: linear-gradient(#1D314D, #1D314D); }
  .block.o-style-blue td, .block.o-style-blue p, .block.o-style-blue li, .block.o-style-blue h1, .block.o-style-blue h2, .block.o-style-blue h3, .block.o-style-blue h4, .block.o-style-blue h5, .block.o-style-blue blockquote, .block.o-style-blue cite {
    color: #ffffff; }

.block.o-style-white {
  background-image: linear-gradient(#ffffff, #ffffff); }
  .block.o-style-white td, .block.o-style-white p, .block.o-style-white li, .block.o-style-white h1, .block.o-style-white h2, .block.o-style-white h3, .block.o-style-white h4, .block.o-style-white h5, .block.o-style-white blockquote, .block.o-style-white cite {
    color: #1D314D; }

.block.o-style-grey {
  background-image: linear-gradient(#E7EDEC, #E7EDEC); }
  .block.o-style-grey td, .block.o-style-grey p, .block.o-style-grey li, .block.o-style-grey h1, .block.o-style-grey h2, .block.o-style-grey h3, .block.o-style-grey h4, .block.o-style-grey h5, .block.o-style-grey blockquote, .block.o-style-grey cite {
    color: #1D314D; }

.block.o-style-green {
  background-image: linear-gradient(#5A8080, #5A8080); }
  .block.o-style-green td, .block.o-style-green p, .block.o-style-green li, .block.o-style-green h1, .block.o-style-green h2, .block.o-style-green h3, .block.o-style-green h4, .block.o-style-green h5, .block.o-style-green blockquote, .block.o-style-green cite {
    color: #ffffff; }

.page-style-green .block.o-style-none td, .page-style-green .block.o-style-none p, .page-style-green .block.o-style-none li, .page-style-green .block.o-style-none h1, .page-style-green .block.o-style-none h2, .page-style-green .block.o-style-none h3, .page-style-green .block.o-style-none h4, .page-style-green .block.o-style-none h5, .page-style-green .block.o-style-none blockquote, .page-style-green .block.o-style-none cite {
  color: #ffffff; }

.page-style-blue .block.o-style-none td, .page-style-blue .block.o-style-none p, .page-style-blue .block.o-style-none li, .page-style-blue .block.o-style-none h1, .page-style-blue .block.o-style-none h2, .page-style-blue .block.o-style-none h3, .page-style-blue .block.o-style-none h4, .page-style-blue .block.o-style-none h5, .page-style-blue .block.o-style-none blockquote, .page-style-blue .block.o-style-none cite {
  color: #ffffff; }

/*
Offset
*/
.block.o-bkg-offset-50, .block.block-video {
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: 100% 50%; }

.block.o-bkg-offset-60 {
  background-repeat: no-repeat;
  background-position: 40% 100%;
  background-size: 100% 60%; }

.block.o-bkg-offset-70 {
  background-repeat: no-repeat;
  background-position: 30% 100%;
  background-size: 100% 70%; }

/*
Margin
*/
.block.o-margin-bottom {
  margin-bottom: 160px; }

/*
Spacing
*/
.block[class*="o-spacing-"] .top {
  padding-top: 80px; }
  @media (max-width: 767px) {
    .block[class*="o-spacing-"] .top {
      padding-top: 40px; } }

.block[class*="o-spacing-"] .content:first-child:only-child {
  padding: 80px 0; }
  @media (max-width: 767px) {
    .block[class*="o-spacing-"] .content:first-child:only-child {
      padding: 40px 0; } }

.block[class*="o-spacing-"] .content:nth-child(2):last-child {
  padding-top: 40px;
  padding-bottom: 80px; }
  @media (max-width: 767px) {
    .block[class*="o-spacing-"] .content:nth-child(2):last-child {
      padding-bottom: 40px; } }

.block[class*="o-spacing-"] .content:nth-child(2):not(:last-child) {
  padding-top: 20px;
  padding-bottom: 40px; }

.block[class*="o-spacing-"] .content:first-child:not(:last-child) {
  padding-top: 80px; }
  @media (max-width: 767px) {
    .block[class*="o-spacing-"] .content:first-child:not(:last-child) {
      padding-top: 40px; } }

.block[class*="o-spacing-"] .bottom {
  padding-bottom: 80px; }
  @media (max-width: 767px) {
    .block[class*="o-spacing-"] .bottom {
      padding-bottom: 40px; } }

.block[class*="o-spacing-"].o-spacing-none .top,
.block[class*="o-spacing-"].o-spacing-none .content:first-child {
  padding-top: 0; }

.block[class*="o-spacing-"].o-spacing-none .bottom,
.block[class*="o-spacing-"].o-spacing-none .content:first-child {
  padding-bottom: 0; }

.block[class*="o-spacing-"].o-spacing-none-above .top,
.block[class*="o-spacing-"].o-spacing-none-above .content:first-child {
  padding-top: 0; }

.block[class*="o-spacing-"].o-spacing-none-below .content:last-child,
.block[class*="o-spacing-"].o-spacing-none-below .bottom {
  padding-bottom: 0; }
  .block[class*="o-spacing-"].o-spacing-none-below .content:last-child *:last-child,
  .block[class*="o-spacing-"].o-spacing-none-below .bottom *:last-child {
    margin-bottom: 0; }

/*
Sizing
*/
.block.o-size-narrow .top .inner {
  grid-column-start: 2;
  grid-column-end: 8;
  grid-row-start: 1;
  grid-row-end: 1; }
  @media (max-width: 1199px) {
    .block.o-size-narrow .top .inner {
      grid-column-start: 1;
      grid-column-end: 8;
      grid-row-start: 1;
      grid-row-end: 1; } }

/*
Vertical Offset
*/
.block.o-pre-verticaloffset {
  padding-bottom: 160px; }

/*
Header media
*/
.block .top .image,
.block .top .video {
  grid-column-start: 8;
  grid-column-end: 13;
  grid-row-start: 1;
  grid-row-end: 1; }

.block.o-header-image-offset-top {
  overflow: visible; }
  .block.o-header-image-offset-top .top .image,
  .block.o-header-image-offset-top .top .video {
    transform: translateY(-160px);
    margin-bottom: -160px; }
    @media (max-width: 767px) {
      .block.o-header-image-offset-top .top .image,
      .block.o-header-image-offset-top .top .video {
        margin-bottom: -110px; } }

/*
Carousel
*/
.carousel-panes .carousel-pane {
  display: none; }
  .carousel-panes .carousel-pane.active {
    display: block; }

.carousel-pagination {
  display: flex;
  align-items: flex-start;
  height: 3px; }
  .carousel-pagination .bar {
    display: block;
    cursor: pointer;
    padding-top: 10px;
    height: 15px;
    margin-right: 15px;
    width: 100%;
    transition: all 800ms cubic-bezier(0.19, 1, 0.22, 1);
    border-top: 2px solid rgba(0, 0, 0, 0.15); }
    .o-style-grey .carousel-pagination .bar,
    .page-style-grey .carousel-pagination .bar,
    .site-footer .carousel-pagination .bar {
      border-bottom-color: #D3D3D3; }
    .carousel-pagination .bar:hover, .carousel-pagination .bar.active {
      border-color: #ED5436;
      border-bottom-width: 2px; }
    .carousel-pagination .bar.active {
      width: 200%; }
    .carousel-pagination .bar:last-of-type {
      margin-right: 0; }

/*
Cookie Notice
*/
.cookie-notice {
  position: fixed;
  z-index: 400;
  background: #ED5436;
  padding: 70px;
  right: 0;
  width: calc(70vw);
  min-width: 400px;
  display: flex;
  align-items: center;
  box-shadow: -18px 18px 36px rgba(0, 0, 0, 0.1), 18px -18px 36px rgba(50, 50, 50, 0.05); }
  @media (max-width: 991px) {
    .cookie-notice {
      width: 100vw;
      padding: 45px; } }
  @media (max-width: 767px) {
    .cookie-notice {
      width: 100vw;
      display: block;
      padding: 35px; } }
  .cookie-notice .inner {
    padding-right: 80px; }
    .cookie-notice .inner p, .cookie-notice .inner a {
      margin-bottom: 0;
      color: #ffffff; }
    @media (max-width: 767px) {
      .cookie-notice .inner {
        margin-bottom: 20px;
        padding-right: 0; } }
  .cookie-notice .close.btn-primary {
    white-space: nowrap;
    background-color: #ED5436;
    border-color: #ffffff;
    color: #ffffff; }
    .cookie-notice .close.btn-primary::after {
      background-image: url("/assets/media/graphics/arrow-light.svg"); }

/*
Footer
*/
/*
Wrapper
*/
.site-footer {
  color: #1D314D;
  background: #ffffff;
  padding-top: 120px;
  position: relative; }
  @media (max-width: 767px) {
    .site-footer {
      display: flex;
      flex-direction: column;
      padding-top: 20px; } }

/*
Top
*/
.site-footer-top {
  align-content: space-between; }
  @media (max-width: 767px) {
    .site-footer-top {
      order: 2;
      margin-bottom: 40px; } }
  .site-footer-top .stat-carousel {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 1; }
    @media (max-width: 767px) {
      .site-footer-top .stat-carousel {
        margin-bottom: 80px;
        width: 30%; } }
    .site-footer-top .stat-carousel .stat-pane.active {
      animation: fade-in 1s normal forwards ease-in-out; }
    .site-footer-top .stat-carousel .stat-figure {
      margin-bottom: 40px; }
    .site-footer-top .stat-carousel .stat-definition {
      font-size: 1.125rem; }
  .site-footer-top .footer-nav-link {
    margin-bottom: 3px; }
    .site-footer-top .footer-nav-link a {
      text-decoration: none; }
  .site-footer-top .footer-col-one {
    grid-column-start: 5;
    grid-column-end: 7;
    grid-row-start: 1;
    grid-row-end: 1; }
    @media (max-width: 1199px) {
      .site-footer-top .footer-col-one {
        grid-column-start: 5;
        grid-column-end: 8;
        grid-row-start: 1;
        grid-row-end: 1; } }
    @media (max-width: 991px) {
      .site-footer-top .footer-col-one {
        grid-column-start: 5;
        grid-column-end: 10;
        grid-row-start: 1;
        grid-row-end: 1; } }
  .site-footer-top .footer-col-two {
    grid-column-start: 7;
    grid-column-end: 9;
    grid-row-start: 1;
    grid-row-end: 1; }
    @media (max-width: 1199px) {
      .site-footer-top .footer-col-two {
        grid-column-start: 9;
        grid-column-end: 12;
        grid-row-start: 1;
        grid-row-end: 1; } }
    @media (max-width: 991px) {
      .site-footer-top .footer-col-two {
        margin-top: 40px;
        grid-column-start: 5;
        grid-column-end: 12;
        grid-row-start: 2;
        grid-row-end: 2; } }
  .site-footer-top .footer-col-three {
    grid-column-start: 9;
    grid-column-end: 11;
    grid-row-start: 1;
    grid-row-end: 1; }
    @media (max-width: 1199px) {
      .site-footer-top .footer-col-three {
        margin-top: 80px;
        grid-column-start: 9;
        grid-column-end: 12;
        grid-row-start: 2;
        grid-row-end: 2; } }
    @media (max-width: 991px) {
      .site-footer-top .footer-col-three {
        margin-top: 40px;
        grid-column-start: 5;
        grid-column-end: 13;
        grid-row-start: 3;
        grid-row-end: 3; } }
  .site-footer-top .footer-follow {
    grid-column-start: 11;
    grid-column-end: 13;
    grid-row-start: 1;
    grid-row-end: 1; }
    @media (max-width: 1199px) {
      .site-footer-top .footer-follow {
        margin-top: 80px;
        grid-column-start: 5;
        grid-column-end: 8;
        grid-row-start: 2;
        grid-row-end: 2; } }
    @media (max-width: 991px) {
      .site-footer-top .footer-follow {
        margin-top: 0;
        grid-column-start: 9;
        grid-column-end: 13;
        grid-row-start: 1;
        grid-row-end: 1; } }
    .site-footer-top .footer-follow a {
      display: block;
      width: max-content;
      text-decoration: none;
      margin-bottom: 5px; }
  .site-footer-top .legaltext {
    grid-column-start: 5;
    grid-column-end: 13;
    grid-row-start: 2;
    grid-row-end: 2;
    opacity: 0.5;
    margin-top: 40px; }
    @media (max-width: 1199px) {
      .site-footer-top .legaltext {
        grid-column-start: 5;
        grid-column-end: 13;
        grid-row-start: 3;
        grid-row-end: 3; } }
    @media (max-width: 991px) {
      .site-footer-top .legaltext {
        grid-column-start: 5;
        grid-column-end: 13;
        grid-row-start: 4;
        grid-row-end: 4; } }
    @media (max-width: 767px) {
      .site-footer-top .legaltext {
        border-top: 1px solid #D3D3D3;
        padding-top: 40px; } }

/*
Middle
*/
.site-footer-middle {
  margin-top: 40px;
  margin-bottom: 20px; }
  @media (max-width: 767px) {
    .site-footer-middle {
      order: 1; } }
  .site-footer-middle .site-logo {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 1;
    width: 75%;
    margin-bottom: 0; }
    @media (max-width: 767px) {
      .site-footer-middle .site-logo {
        width: auto;
        background-position: top left;
        margin-bottom: 40px; } }
  .site-footer-middle .footer-nav {
    grid-column-start: 5;
    grid-column-end: 13;
    grid-row-start: 1;
    grid-row-end: 1;
    margin-top: 5px; }
    @media (max-width: 767px) {
      .site-footer-middle .footer-nav {
        margin-left: -10px;
        margin-bottom: 40px; } }
    .site-footer-middle .footer-nav a {
      padding: 10px;
      opacity: 0.5;
      white-space: nowrap; }
      .site-footer-middle .footer-nav a:hover {
        opacity: 1; }
      @media (min-width: 768px) {
        .site-footer-middle .footer-nav a:first-of-type {
          margin-left: -10px; } }

/*
Bottom
*/
.site-footer-bottom {
  background: #ED5436;
  padding: 15px 0;
  color: #ffffff; }
  @media (max-width: 991px) {
    .site-footer-bottom {
      margin-top: 0;
      order: 3; } }
  .site-footer-bottom .copyright {
    color: #ffffff;
    font-size: 0.875rem;
    opacity: 0.5;
    margin-bottom: 0; }

/*
Header
*/
.site-header {
  overflow-x: hidden;
  min-height: 50vh; }
  @media (max-width: 991px) {
    .site-header {
      position: relative; } }
  .nav-small-screen--open .site-header .site-logo {
    z-index: 300; }
  .page-style-green .site-header .site-logo,
  .page-style-blue .site-header .site-logo {
    background-image: url("/assets/media/logos/logo-white.svg"); }
  .page-style-green .site-header h1, .page-style-green .site-header .summary, .page-style-green .site-header .strapline, .page-style-green .site-header .introduction *,
  .page-style-blue .site-header h1,
  .page-style-blue .site-header .summary,
  .page-style-blue .site-header .strapline,
  .page-style-blue .site-header .introduction * {
    color: #ffffff; }
  @media (max-width: 991px) {
    .site-header .inner {
      padding-left: 0;
      padding-right: 0; } }

.site-header-top {
  padding-top: 50px; }
  @media (max-width: 991px) {
    .site-header-top {
      display: block; } }
  @media (max-width: 767px) {
    .site-header-top {
      padding-top: 40px; } }

.site-nav {
  grid-column-start: 4;
  grid-column-end: 13;
  grid-row-start: 1;
  grid-row-end: 1; }

.page-header {
  padding-top: 92px;
  overflow-y: visible; }
  .page-header h1 {
    grid-column-start: 4;
    grid-column-end: 11;
    grid-row-start: 1;
    grid-row-end: 1;
    text-align: left;
    z-index: 1; }
    @media (min-width: 768px) and (max-width: 1199px) {
      .page-header h1 {
        grid-column-start: 3;
        grid-column-end: 11;
        grid-row-start: 1;
        grid-row-end: 1; } }
    @media (max-width: 767px) {
      .page-header h1 {
        width: 90%; } }
  .page-header img, .page-header video {
    width: 100%;
    height: auto; }
  .page-header .content {
    min-height: 400px; }
  .page-header .summary {
    grid-column-start: 6;
    grid-column-end: 12;
    grid-row-start: 2;
    grid-row-end: 2;
    z-index: 2;
    color: #1D314D; }
    @media (max-width: 767px) {
      .page-header .summary {
        width: 75%; } }
  .page-header .fold-hint {
    display: none;
    position: absolute;
    transform: translateX(-25%);
    z-index: 3;
    height: 38px;
    width: 38px;
    top: calc(80vh - 38px);
    left: 50%; }
    @media (max-width: 991px) {
      .page-header .fold-hint {
        top: calc(60vh - 38px); } }
    @media (max-width: 767px) {
      .page-header .fold-hint {
        top: calc(80vh - 38px) !important;
        transform: translateX(-50%); } }
    .page-header .fold-hint::after {
      background-size: 100% !important;
      height: 100%;
      width: 100%; }

.hero {
  overflow-x: visible; }
  .hero img, .hero video {
    position: absolute; }

.hero:nth-of-type(1) {
  grid-column-start: 7;
  grid-column-end: 13;
  grid-row-start: 1;
  grid-row-end: 1;
  margin-top: 37px; }
  .hero:nth-of-type(1) img, .hero:nth-of-type(1) video {
    width: calc(50vw);
    max-height: 50vh;
    object-fit: cover; }
  .hero:nth-of-type(1):not(:only-of-type) {
    grid-column-start: 8;
    grid-column-end: 13;
    grid-row-start: 1;
    grid-row-end: 1;
    margin-top: 200px;
    margin-bottom: 114.28571px; }
    .hero:nth-of-type(1):not(:only-of-type) img, .hero:nth-of-type(1):not(:only-of-type) video {
      width: calc(50vw - 106.25px);
      right: 0; }
      @media (max-width: 991px) {
        .hero:nth-of-type(1):not(:only-of-type) img, .hero:nth-of-type(1):not(:only-of-type) video {
          width: calc(50vw - 53.125px); } }
  @media (min-width: 768px) and (max-width: 1199px) {
    .hero:nth-of-type(1) {
      margin-top: 143.33333px; } }
  @media (max-width: 767px) {
    .hero:nth-of-type(1) {
      margin-top: 0 !important; }
      .hero:nth-of-type(1) img, .hero:nth-of-type(1) video {
        width: calc(100% - 80px) !important; } }

.hero:nth-of-type(2) {
  grid-column-start: 1;
  grid-column-end: 6;
  grid-row-start: 2;
  grid-row-end: 2;
  margin-top: -280px; }
  @media (max-width: 991px) {
    .hero:nth-of-type(2) {
      grid-column-start: 1;
      grid-column-end: 6;
      grid-row-start: 2;
      grid-row-end: 2; } }
  .hero:nth-of-type(2) img, .hero:nth-of-type(2) video {
    width: calc(50vw - 106.25px);
    left: 0; }
  @media (max-width: 991px) {
    .hero:nth-of-type(2) img, .hero:nth-of-type(2) video {
      width: calc(50vw - 53.125px); } }
  @media (max-width: 767px) {
    .hero:nth-of-type(2) {
      margin-top: -80px !important; }
      .hero:nth-of-type(2) img, .hero:nth-of-type(2) video {
        width: calc(100% - 80px) !important; } }

/*
Article
*/
.site-header.type-article {
  background: white;
  padding-bottom: 80px; }
  .site-header.type-article .date {
    grid-column-start: 4;
    grid-column-end: 8;
    grid-row-start: 1;
    grid-row-end: 1; }
  .site-header.type-article h1 {
    grid-column-start: 4;
    grid-column-end: 11;
    grid-row-start: 2;
    grid-row-end: 2;
    color: #1D314D;
    margin-bottom: 120px; }
    @media (max-width: 767px) {
      .site-header.type-article h1 {
        margin-bottom: 40px; } }
  .site-header.type-article .logos {
    grid-column-start: 3;
    grid-column-end: span 2;
    grid-row-start: 3;
    grid-row-end: 3;
    transform: translateX(-50px); }
    @media (max-width: 767px) {
      .site-header.type-article .logos {
        width: 50%;
        transform: none;
        display: flex;
        justify-content: space-between;
        align-items: center; } }
  .site-header.type-article .logo {
    margin-bottom: 40px; }
    @media (max-width: 767px) {
      .site-header.type-article .logo {
        width: 45%; } }
  .site-header.type-article .summary {
    grid-column-start: 5;
    grid-column-end: span 6;
    grid-row-start: 3;
    grid-row-end: 3;
    font-family: "Roboto", arial, sans-serif; }
    @media (max-width: 767px) {
      .site-header.type-article .summary {
        width: 80%; } }
  .site-header.type-article .btn-primary {
    grid-column-start: 5;
    grid-column-end: span 3;
    grid-row-start: 4;
    grid-row-end: 4;
    margin-top: 40px; }

/*
Investment
*/
.site-header.type-investment {
  position: relative;
  overflow-x: inherit; }
  .site-header.type-investment[class*="halo"]::before {
    background-position: 50% bottom;
    min-height: 100vh;
    bottom: 0; }
  .site-header.type-investment h1 {
    grid-column-start: 4;
    grid-column-end: 11;
    grid-row-start: 1;
    grid-row-end: 1;
    margin-bottom: 0;
    color: #ED5436; }
    @media (min-width: 768px) and (max-width: 991px) {
      .site-header.type-investment h1 {
        grid-column-start: 2;
        grid-column-end: 11;
        grid-row-start: 1;
        grid-row-end: 1; } }
    @media (max-width: 767px) {
      .site-header.type-investment h1 {
        margin-bottom: 40px; } }
    .site-header.type-investment h1[data-status]::before {
      content: attr(data-status);
      display: block;
      color: #ffffff;
      margin-bottom: 20px; }
    .site-header.type-investment h1[data-status="realised" i]::before {
      color: #1D314D; }
  .site-header.type-investment .hero {
    grid-column-start: 7;
    grid-column-end: 13;
    grid-row-start: 1;
    grid-row-end: 2;
    margin-top: 0; }
    @media (min-width: 768px) and (max-width: 991px) {
      .site-header.type-investment .hero {
        grid-column-start: 6;
        grid-column-end: 13;
        grid-row-start: 1;
        grid-row-end: 2; }
        .site-header.type-investment .hero img {
          width: calc(60vw); } }
    @media (max-width: 767px) {
      .site-header.type-investment .hero img {
        width: 100% !important; } }
    .site-header.type-investment .hero img {
      position: static; }
  .site-header.type-investment .summary {
    margin-top: -200px;
    padding-right: 40px;
    margin-bottom: 0;
    grid-column-start: 2;
    grid-column-end: 7;
    grid-row-start: 2;
    grid-row-end: 3; }
    @media (min-width: 992px) and (max-width: 1199px) {
      .site-header.type-investment .summary {
        margin-top: -100px; } }
    @media (min-width: 768px) and (max-width: 991px) {
      .site-header.type-investment .summary {
        grid-column-start: 2;
        grid-column-end: 12;
        grid-row-start: 2;
        grid-row-end: 2;
        margin-top: 40px; } }
    @media (max-width: 767px) {
      .site-header.type-investment .summary {
        margin-top: 0;
        margin-bottom: 40px; } }
  .site-header.type-investment .introduction {
    grid-column-start: 7;
    grid-column-end: 12;
    grid-row-start: 2;
    grid-row-end: 3;
    padding: 80px 0; }
    @media (min-width: 768px) and (max-width: 991px) {
      .site-header.type-investment .introduction {
        grid-column-start: 2;
        grid-column-end: 11;
        grid-row-start: 3;
        grid-row-end: 3;
        padding-top: 40px; } }
    .site-header.type-investment .introduction .btn-primary {
      margin-top: 40px; }

/*
Configuration
*/
.nav-search a, .nav-member a {
  display: block;
  transition: background 600ms cubic-bezier(0.19, 1, 0.22, 1); }

.nav-search {
  padding: 0 20px;
  margin-right: 20px; }
  .nav-search a {
    width: 23px;
    height: 23px; }
  @media (max-width: 991px) {
    .nav-search {
      position: absolute;
      top: 50px;
      margin-top: 2px;
      right: 80px;
      z-index: 99; }
      .nav-search.desktop {
        display: none; } }
  @media (max-width: 767px) {
    .nav-search {
      top: 40px; } }
  @media (min-width: 992px) {
    .nav-search.small-screen {
      display: none; } }

.nav-member a {
  width: 27px;
  height: 27px; }

@media (min-width: 992px) {
  nav.nav-items {
    text-align: left;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    margin-left: -10px; }
    nav.nav-items ol {
      display: flex;
      flex-grow: 1;
      align-items: center;
      justify-content: space-between;
      margin-right: 80px; } }
    @media (min-width: 992px) and (max-width: 1199px) {
      nav.nav-items ol {
        margin-right: 40px; } }

@media (min-width: 992px) {
    nav.nav-items li {
      display: inline-block; }
      nav.nav-items li .nav-sub-menu {
        display: none; }
      nav.nav-items li.nav-item a {
        white-space: nowrap;
        position: relative;
        transition: color 600ms cubic-bezier(0.19, 1, 0.22, 1);
        text-decoration: none;
        box-sizing: content-box;
        display: block;
        padding: 10px; }
        .page-style-blue nav.nav-items li.nav-item a {
          color: #ffffff; }
        .page-style-green nav.nav-items li.nav-item a {
          color: #ffffff; }
        nav.nav-items li.nav-item a::after {
          bottom: -5px;
          opacity: 0; }
      nav.nav-items li.nav-item:hover a,
      nav.nav-items li.nav-item.active a {
        color: #ED5436; }
        .page-style-green nav.nav-items li.nav-item:hover a, .page-style-green
        nav.nav-items li.nav-item.active a {
          color: #1D314D; }
        nav.nav-items li.nav-item:hover a::after,
        nav.nav-items li.nav-item.active a::after {
          opacity: 1;
          bottom: 4px; }
          .page-style-green nav.nav-items li.nav-item:hover a::after, .page-style-green
          nav.nav-items li.nav-item.active a::after {
            border-color: #1D314D; } }

.nav-small-screen-button {
  position: absolute;
  top: 50px;
  right: 35px;
  display: none;
  height: 25px;
  width: 25px;
  margin: 0;
  z-index: 103; }
  @media (max-width: 767px) {
    .nav-small-screen-button {
      top: 40px; } }
  .nav-small-screen-button:focus {
    outline: none; }

@media (max-width: 991px) {
  .nav-small-screen-button {
    display: block; }
  .nav-items {
    display: none; }
  .nav-item .nav-sub-menu-button {
    display: inline-block;
    margin-left: 40px;
    width: 32px;
    position: relative;
    top: 5px;
    cursor: pointer; }
  .nav-item .nav-sub-menu {
    display: none;
    flex-direction: column;
    margin-left: 40px; }
    .nav-item .nav-sub-menu.open {
      display: flex; }
  .nav-item .nav-sub-menu-link {
    margin-top: 7px;
    margin-bottom: 3px; }
    .nav-item .nav-sub-menu-link:first-of-type {
      margin-top: 12px; }
  .site-nav {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: none;
    position: fixed;
    z-index: 100;
    width: 100vw;
    height: 100vh;
    background: #ED5436;
    padding: 160px 40px 40px 40px; }
  .nav-member {
    margin-top: 40px;
    padding-top: 20px;
    border-top: 1px solid #ffffff; }
    .nav-member .i-user {
      background-image: url("/assets/media/graphics/user-light.svg"); }
      .nav-member .i-user:hover {
        background-image: url("/assets/media/graphics/user-light.svg"); }
    .nav-member a {
      text-decoration: none !important; }
    .nav-member a::after {
      content: "Login";
      display: block;
      padding-left: 40px;
      color: #ffffff !important;
      text-decoration: none !important; }
  body.nav-small-screen--open {
    overflow: hidden; }
    body.nav-small-screen--open .nav-small-screen-button {
      right: 40px; }
    body.nav-small-screen--open .nav-items {
      display: block; }
    body.nav-small-screen--open .site-nav {
      display: block; }
    body.nav-small-screen--open .nav-member a {
      filter: none !important; } }

/*
Overlay
*/
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  width: 100vw;
  z-index: 200;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  animation: fade-in 1s normal forwards ease-in-out;
  display: none; }
  .overlay.active {
    display: block; }
  .overlay > .outer {
    margin-left: auto;
    width: calc((1200px / 12 * 10) - 25px);
    height: 100vh;
    overflow-y: scroll;
    background: #E7EDEC; }
    @media (max-width: 991px) {
      .overlay > .outer {
        max-width: 100vw; } }
    .overlay > .outer > .inner {
      position: relative; }
  .overlay .close {
    transition: background 0.3s ease-in-out;
    background-image: url("/assets/media/graphics/close-circle-light.svg");
    cursor: pointer;
    position: absolute;
    top: 40px;
    right: 40px;
    z-index: 1;
    width: 75px;
    height: 75px; }
    @media (max-width: 767px) {
      .overlay .close {
        right: 20px;
        width: 45px;
        height: 45px;
        top: 20px;
        background-size: contain; } }
    .overlay .close:hover {
      background-image: url("/assets/media/graphics/close-circle-dark.svg"); }

.overlay.person {
  display: block;
  z-index: 300; }
  .overlay.person > .outer {
    margin-left: auto;
    min-width: 900px;
    width: 80vw; }
    @media (max-width: 1199px) {
      .overlay.person > .outer {
        min-width: auto;
        width: 90vw; } }
    @media (max-width: 767px) {
      .overlay.person > .outer {
        min-width: auto;
        width: 100vw; } }
  .overlay.person .inner {
    padding-bottom: 80px; }
    @media (max-width: 767px) {
      .overlay.person .inner {
        padding: 20px 20px 80px 20px; } }
  .overlay.person .close {
    background-image: url("/assets/media/graphics/close-circle-orange.svg"); }
    .overlay.person .close:hover {
      background-image: url("/assets/media/graphics/close-circle-dark.svg"); }
  .overlay.person a[class*='control-'] {
    display: none; }
  .overlay.person a[class*='control-prev'] {
    left: 30px; }
  .overlay.person .portrait {
    grid-column-start: 5;
    grid-column-end: 11;
    grid-row-start: 1;
    grid-row-end: 3;
    margin-bottom: 40px; }
  .overlay.person .details {
    grid-column-start: 3;
    grid-column-end: 11;
    grid-row-start: 1;
    grid-row-end: 3;
    position: relative;
    z-index: 1;
    margin-top: 160px; }
    @media (max-width: 767px) {
      .overlay.person .details {
        margin-top: 40px; } }
  .overlay.person .team {
    color: #ED5436; }
    @media (min-width: 768px) {
      .overlay.person .team {
        margin-bottom: 80px; } }
  .overlay.person .name {
    margin-bottom: 20px; }
  .overlay.person .summary {
    grid-column-start: 5;
    grid-column-end: 10;
    grid-row-start: 3;
    grid-row-end: 3; }
  .overlay.person .insights {
    grid-column-start: 1;
    grid-column-end: 11;
    grid-row-start: 5;
    grid-row-end: 5;
    margin-top: 40px;
    background: #ffffff; }
    .overlay.person .insights:empty {
      display: none; }
  .overlay.person .statement {
    grid-column-start: 2;
    grid-column-end: 10;
    grid-row-start: 1;
    grid-row-end: 1;
    margin: 80px 0; }
  .overlay.person .interest {
    grid-column-start: 4;
    grid-column-end: 10;
    grid-row-start: auto;
    grid-row-end: auto; }
    .overlay.person .interest:last-of-type {
      margin-bottom: 80px; }
    .overlay.person .interest .question {
      margin: 40px 0 20px 0;
      color: #ED5436;
      width: 75%; }
  .overlay.person .investments {
    grid-column-start: 2;
    grid-column-end: 10;
    grid-row-start: 6;
    grid-row-end: 6;
    text-align: center;
    padding-top: 80px; }
    .overlay.person .investments h3 {
      grid-column-start: 2;
      grid-column-end: 11;
      grid-row-start: 1;
      grid-row-end: 1; }
    .overlay.person .investments .carousel {
      grid-column-start: 2;
      grid-column-end: 10;
      grid-row-start: 2;
      grid-row-end: 2;
      position: relative; }
      @media (max-width: 767px) {
        .overlay.person .investments .carousel {
          margin-top: 100px; } }
    .overlay.person .investments .carousel-control {
      position: absolute;
      top: 0;
      right: 0;
      transform: translateY(calc(-100% - 20px)); }
      .overlay.person .investments .carousel-control[role="Previous"] {
        transform: translateY(calc(-100% - 20px)) scaleX(-1) !important;
        right: 100px; }
        @media (max-width: 767px) {
          .overlay.person .investments .carousel-control[role="Previous"] {
            right: auto;
            left: 0; } }
    .overlay.person .investments .carousel-pane.active {
      display: flex;
      animation: slideup-and-fade-in 3s normal forwards cubic-bezier(0.19, 1, 0.22, 1); }
      @media (max-width: 767px) {
        .overlay.person .investments .carousel-pane.active {
          flex-wrap: wrap; } }
      .overlay.person .investments .carousel-pane.active.hide {
        animation: fade-out 0.5s normal forwards cubic-bezier(0.19, 1, 0.22, 1); }
    .overlay.person .investments .carousel-pagination {
      width: 66%;
      margin-top: 40px; }
      @media (max-width: 767px) {
        .overlay.person .investments .carousel-pagination {
          margin-top: 10px;
          width: 100%;
          margin-left: auto;
          margin-right: auto; } }
    .overlay.person .investments .investment-thumb {
      width: calc(100% / 3); }
      @media (max-width: 767px) {
        .overlay.person .investments .investment-thumb {
          width: 100%;
          margin-right: 0 !important;
          margin-bottom: 25px; } }
      .overlay.person .investments .investment-thumb:not(:last-of-type) {
        margin-right: 25px; }
      @media (max-width: 767px) {
        .overlay.person .investments .investment-thumb {
          width: 100%;
          margin-right: 0 !important;
          margin-bottom: 25px; } }
    .overlay.person .investments .btn-primary {
      margin-top: 40px; }
  .overlay.person .news {
    grid-column-start: 2;
    grid-column-end: 10;
    grid-row-start: 7;
    grid-row-end: 7;
    text-align: center;
    padding-top: 80px; }
    .overlay.person .news .articles {
      display: flex;
      justify-content: space-between; }
    .overlay.person .news .news-article-thumb {
      width: calc(50% - 12.5px); }
    .overlay.person .news .btn-primary {
      margin-top: 40px; }

/*
Search
- Used for inline search fields on team and search pages
*/
.overlay.search > .outer {
  background: #ED5436;
  margin-right: auto;
  margin-left: 0;
  overflow-y: auto; }

.overlay.search .inner {
  display: block;
  margin-left: 80px;
  padding-top: 40px; }
  @media (max-width: 767px) {
    .overlay.search .inner {
      margin: 0 auto;
      width: calc(100vw - 80px); } }

.overlay.search .close {
  right: 0 !important;
  margin-right: 40px; }
  @media (max-width: 767px) {
    .overlay.search .close {
      top: 30px;
      margin-right: 0; } }

.overlay.search h3 {
  color: #ffffff; }
  .overlay.search h3 .count {
    color: #ffffff !important;
    font-style: italic; }

.overlay.search form {
  position: relative;
  width: 50%; }
  @media (max-width: 767px) {
    .overlay.search form {
      width: 100%; } }
  .overlay.search form input.field {
    -webkit-appearance: none;
    -webkit-border-radius: 0;
    border-radius: 0;
    width: 100%;
    margin: 80px 0; }
    .overlay.search form input.field::-webkit-search-cancel-button {
      -webkit-appearance: none;
      cursor: pointer;
      height: 20px;
      width: 20px;
      margin-right: 75px;
      background-image: url("/assets/media/graphics/close-light.svg");
      background-size: contain;
      transition: opacity 0.3s ease-in-out; }
      .overlay.search form input.field::-webkit-search-cancel-button:hover {
        opacity: .5; }
  .overlay.search form .btn-inline-search {
    background: rgba(255, 255, 255, 0.3) !important;
    color: #ffffff;
    position: absolute;
    right: 0;
    bottom: -10px;
    opacity: 0; }
    .overlay.search form .btn-inline-search:hover {
      background: #ffffff !important;
      color: #ED5436 !important; }
  .overlay.search form .btn-inline-search:hover,
  .overlay.search form .field:focus + .btn-inline-search,
  .overlay.search form .field:valid:not(:placeholder-shown) + .btn-inline-search,
  .overlay.search form .field:hover + .btn-inline-search {
    opacity: 1; }

.overlay.search .no-results *, .overlay.search .result * {
  color: #ffffff; }

.overlay.search .query {
  font-style: italic;
  quotes: "'" "'"; }
  .overlay.search .query::before {
    content: open-quote; }
  .overlay.search .query::after {
    content: close-quote; }

.overlay.search .result {
  display: block;
  text-decoration: none;
  width: 50%;
  margin-bottom: 40px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  padding-bottom: 20px; }
  .overlay.search .result .url {
    opacity: 0.5 !important;
    font-style: italic; }
  @media (max-width: 991px) {
    .overlay.search .result {
      width: calc(100% - 80px); } }
  .overlay.search .result .title {
    margin-bottom: 10px; }
  .overlay.search .result .desc {
    display: none; }

/***
* Side menu
***/
.side-menu {
  position: fixed;
  z-index: 20;
  top: 50vh;
  transform: translate3d(0, -25%, 0);
  transition: all 600ms cubic-bezier(0.19, 1, 0.22, 1);
  padding-left: 10px; }
  @media (max-width: 991px) {
    .side-menu {
      display: none; } }
  .side-menu.mini .side-menu-link {
    width: 20px;
    overflow: hidden; }
    .side-menu.mini .side-menu-link:hover {
      width: auto; }
  .side-menu.mute {
    transform: translate3d(-100%, -25%, 0) !important; }
  .side-menu .side-menu-inner {
    display: flex;
    flex-direction: column; }
  .side-menu .side-menu-link {
    text-decoration: none;
    margin-bottom: 20px;
    padding-left: 10px;
    font-size: 0.875rem;
    letter-spacing: .7px;
    transition: all 1s ease-in-out;
    white-space: nowrap;
    cursor: pointer; }
    .side-menu .side-menu-link:last-of-type {
      margin-bottom: 0; }
    .side-menu .side-menu-link.o-style-blue {
      color: #1D314D; }
    .side-menu .side-menu-link.o-style-white {
      color: #ffffff; }
    .side-menu .side-menu-link::before {
      content: "\00B7";
      display: inline-block;
      transform: scale(3) translateY(0.5px);
      margin-right: 10px; }
    .side-menu .side-menu-link.active, .side-menu .side-menu-link:hover {
      color: #ED5436 !important; }

/*
Page
*/
body.page-style-white {
  background-color: #ffffff; }

body.page-style-grey {
  background-color: #E7EDEC; }

body.page-style-green {
  background-color: #5A8080; }

body.page-style-blue {
  background-color: #1D314D; }

.page-content .block:first {
  padding-top: 80px; }

#site {
  width: 100%; }

/*
Article
*/
/*
Contact page type
*/
.type-contact .content {
  padding-bottom: 80px; }

.page-investments .site-header {
  min-height: 600px; }
  @media (max-width: 767px) {
    .page-investments .site-header {
      min-height: auto; } }

/*
News Page
*/
.type-article ul, .type-article ol {
  columns: 1; }

/*
Team
*/
.page-team .site-header {
  min-height: 60vh; }
  .page-team .site-header .page-header .content {
    padding-bottom: 80px; }
  @media (max-width: 767px) {
    .page-team .site-header {
      min-height: auto; } }
