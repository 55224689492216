/*
Redactor fixes
*/
p:empty {
	display:none;
}

/*
Body
*/
body {
	overflow-x: hidden;
}

/*
No scrolling
*/
body.no-scroll {
	overflow: hidden!important;
	position: sticky!important;
}

/*
Sniffer for mobile
*/
.is-mob {
	height:0px;
	width:0px;
	position:absolute;
	left:-1000px;
	display:none;

	@include respond-below(md) {
		display:block;
	}
}

/*
SVG Filter
*/
.svg-filter {
	position:absolute;
	left:-10000px;
}


/*
Skip to content
*/
.skip-to-content {
  background: $colour-grey;
	color: $colour-blue;
  height: 30px;
  left: 50%;
  padding: 8px;
  position: absolute;
  transform: translateY(-100%);
  transition: transform 0.3s;
}
.skip-to-content:focus {
  transform: translateY(0%);
}


/*
Back to top
*/
.back-to-top {
	background:$colour-orange;
	height:40px;
	width:40px;
	display:flex;
	justify-content: center;
	position:absolute;
	top:0;
	right:0;
	text-decoration: none;


  @include target-ie() {
    img {
			width:auto;
		}
  }

	&::before {
		@extend .small;

		transition: opacity $transition-800-bezier;
		visibility: hidden;
		opacity: 0;
		position: absolute;
		content:"Back to top";
		width: max-content;
		right:55px;
		top:9px;
		color: $colour-blue
	}

	&:hover {
		background:$colour-blue;
		&::before {
			visibility:visible;
			opacity: 1;
		}
	}

	@include respond-below(md){
		&::before {
			visibility: visible!important;
			opacity: 1;
			color: $colour-orange;
		}
	}

	img {
		transform: rotate(-90deg);
	}
}
