/*
Pre-Footer
*/
.block-pre-footer {

  .previous {
    @include grid-area(1, 2, 1, 6);
    @include respond-below(md) {
      @include grid-area(1, 2, 1, 7);
    }
    @include respond-below(sm) {
      margin-bottom:$spacer-normal;
    }
  }

  .next {
    @include grid-area(1, 8, 1, 12);
    @include respond-below(md) {
      @include grid-area(1, 7, 1, 12);
    }
  }

}
