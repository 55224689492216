/*
Quote
*/

.block.block-quote {

  .content {
    position:relative;

    .carousel-control {
      position:absolute;
      top:50%;
      transform: translateY(-50%);

      &[role="Next"] {
        right:0;
      }
      &[role="Previous"] {
        left:0;
      }
    }

  }

  .quote.carousel-pane {
    display:none;

    &.active {
      display:flex;
      animation: slideup-and-fade-in 2s normal forwards $animation-easing-bezier;

      &.hide {
        animation: fade-out .5s normal forwards $animation-easing-bezier;
      }
    }
  }

  &.o-style-blue {
    .quote cite .role,
    .quote blockquote {
      color:$colour-white!important;
    }
  }

  .quotes {
    @include grid-area(1,3,1,11);
    @include respond-below(sm) {
      width:65%;
      margin:0 auto;
    }

    blockquote {
      color:$colour-blue!important;
    }

    cite {

      a {
        text-decoration: none;
        color:inherit;
        display:inline-block;

        &:not(.portrait) {
          transform:translateY(-20px);
        }
      }

      .portrait {
        $size:80px;

        display:inline-block;
        width:$size;
        height:$size;
        margin-right:$spacer-s + 5;

        .o-silhouette {
          background:$colour-grey;

          img {
            width:auto;
            height:$size;
            transform: translateX(-20px);
          }
        }

        img {
          vertical-align:bottom;
        }

        @include respond-below(sm) {
          margin-bottom:$spacer-m;
        }
      }

      .name, .role {
        display:block;
        font-size:$font-size-normal;
      }

      .name {
        font-size:$font-size-m;
        color:$colour-orange;
        text-transform: uppercase;
      }

      .role {
        color:$colour-blue;
        text-transform: capitalize;
      }
    }

    .carousel-controls {
      left:50%;
      bottom:0;
    }
  }
}
