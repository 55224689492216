.inline-block.people-highlight {

  display:flex;
  justify-content: flex-start;
  flex-wrap:wrap;

  .block.block-one-column.o-size-narrow & {
    @include grid-area(1,2,1,12);
  }

  &[data-no-people="1"],
  &[data-no-people="2"] {
    .person-thumb {
      max-width:calc(50% - #{$spacer-normal});
    }
  }

  .person-thumb {

    display:flex;
    align-items: center;
    align-content: flex-start;
    margin:$spacer-m $spacer-normal 0 0;
    max-width:calc(33% - #{$spacer-normal});
    width:max-content;

    &:nth-of-type(-n+3) {
      margin-top:0;
    }

    .portrait {
      $size: 75px;
      @extend .round;

      width:$size;
      height:$size;

      &.fx-gradient {
        background-position:center center;
      }

      img {
        width:auto;
        height:100%;
        width:100%;
        object-fit: cover;
      }
    }

    .name, .role {
      color:$colour-white;
      margin-left:$spacer-xs;
    }
  }
}
