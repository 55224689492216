.block-team-and-news {

  h3 {
    margin-bottom:$spacer-m;
  }

  .the-team {
    @include grid-area(1,1,1,6);

    // No related news so make it full width
    &:only-child {
      @include grid-area(1,3,1,11);
    }

    .people {
      display:flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
    .person-thumb {
      margin-bottom:$layout-grid-gap;
      color:$colour-white;
      width:calc(100% / 2 - #{$layout-grid-gap});

      @include respond-below(md) {
        width:100%;
      }

      .role {
          display:none;
      }
    }
  }

  .related-news {
    @include grid-area(1,7,1,13);
    @include respond-below(sm) {
      margin-top:$spacer-normal;
    }

    .articles {
      &[data-num-articles="2"] {
        @include respond-above(md) {
          display:grid;
          grid-template-columns: 1fr 1fr;
          grid-column-gap: $layout-grid-gap;
        }
      }
      @include respond-between(sm, md) {
        .news-article-thumb:nth-child(2) {
          display:none;
        }
      }
      margin-bottom:$layout-grid-gap;
    }
  }
}
