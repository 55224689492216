/*
Search
- Used for inline search fields on team and search pages
*/
.overlay.search {

  >  .outer {
    background:$colour-orange;
    margin-right:auto;
    margin-left:0;
    overflow-y: auto;
  }

  .inner {
    display:block;
    margin-left:$spacer-normal;
    padding-top:$spacer-m;
    @include respond-below(sm) {
      margin:0 auto;
      width:calc(100vw - #{$spacer-normal});
    }
  }

  .close {
    right:0!important;
    margin-right:$spacer-m;
    @include respond-below(sm) {
      top:30px;
      margin-right:0;
      
    }
  }

  h3 {
    color:$colour-white;
    .count {
      color:$colour-white!important;
      font-style: italic;
    }
  }

  form {
    position:relative;
    width:50%;

    @include respond-below(sm) {
      width:100%;
    }

    input.field {
      -webkit-appearance: none;
      -webkit-border-radius: 0;
      border-radius:0;
      width:100%;
      margin:$spacer-normal 0;

      &::-webkit-search-cancel-button {
        -webkit-appearance: none;
        cursor: pointer;
        height: 20px;
        width: 20px;
        margin-right:75px;
        background-image: url(get-icon(close, "light"));
        background-size: contain;
        transition: opacity $motion-easing-basic;

        &:hover {
          opacity:.5;
        }
      }
    }

    .btn-inline-search {
      background:rgba(255,255,255,.3)!important;
      color:$colour-white;
      position:absolute;
      right:0;
      bottom:0-$spacer-xs;
      opacity:0;

      &:hover {
        background:$colour-white!important;
        color:$colour-orange!important;
      }
    }

    .btn-inline-search:hover,
    .field:focus + .btn-inline-search,
    .field:valid:not(:placeholder-shown) + .btn-inline-search,
    .field:hover + .btn-inline-search {
      opacity:1;
    }
  }

  .no-results *, .result * {
    color:$colour-white;
  }

  .query {
    font-style:italic;
    quotes: "'" "'";
    &::before {
      content: open-quote;
    }
    &::after {
      content: close-quote;
    }
  }

  .result {
    display:block;
    text-decoration: none;
    width:50%;
    margin-bottom:$spacer-m;
    border-bottom:1px solid transparentize($colour-white, .7);
    padding-bottom:$spacer-s;

    .url {
      opacity:.5!important;
      font-style: italic;
    }

    @include respond-below(md) {
      width:calc(100% - #{$spacer-normal});
    }

    .title {
      margin-bottom:$spacer-xs;
    }

    .desc {
      display:none;
    }

    &:hover {
      // background:rgba(255,255,255,.1);
    }
  }
}
