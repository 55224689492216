/*
Accordian
*/
.block.block-accordian {
  &.o-size-normal .accordian {
    @include grid-area(1, 1, 1, 13);
  }
  &.o-size-narrow {
    .top .inner {
      grid-column-start: 2;
    }
    .accordian {
      @include grid-area(1, 2, 1, 12);
    }
  }

  .accordian-section {
    padding-bottom: $spacer-xs;
    border-bottom: 1px solid transparentize($colour-white, 0.5);
    margin-bottom: $spacer-s;

    &.active {
      .accordian-column {
        display: block;
        // opacity:1;
      }

      .accordian-title {
        @extend .o-up;
        &::after {
          right: 3px;
          top: $spacer-xs + 13;
        }
      }
    }
  }

  .accordian-title {
    @extend .i-arrow;
    @extend .o-down;

    position: relative;
    padding-bottom: $spacer-s;
    margin-bottom: 0;
    padding-top: $spacer-xs;
    cursor: pointer;
    padding-right: $spacer-normal * 2;

    @include respond-below(sm) {
      padding-right: $spacer-normal;
    }

    &:hover::after {
      opacity: 1;
    }

    &::after {
      @include absolute-position(true);
      background-image: url(get-icon(arrow, "light"));

      transition: opacity $transition-d-bezier;
      opacity: 0.7;
      right: 5px;
      top: $spacer-xs + 15;
      left: auto;
    }
  }

  .accordian-content {
    margin-top: 0;
    display: flex;
    flex-direction: row;

    @include respond-below(sm) {
      display: block;
    }
  }

  .accordian-column {
    padding: $spacer-m 0;
    display: none;
    opacity: 0;
    transition: opacity $transition-d-bezier;
    flex-basis: 50%;
    margin-right: $spacer-normal;;

    li {
      display: block;
    }

    @include respond-below(sm) {
      margin-right: 0;
    }

    ul,
    ol {
      columns: 1;
    }

    &:only-of-type {
      flex-basis: 75%;
    }
  }
}
