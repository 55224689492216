/*
Investment Timeline
*/
.block.block-timeline {
  .content {
    @extend %grid-layout;
  }
  .timeline-phase {
    @include grid-area(auto,auto,auto, span 2);

    h3 {
      color:$colour-orange!important;
      &::after {
        background:$colour-orange;
      }
    }
  }
}
