/*
Forms
*/

.field {
  @include remove-focus-highlight();
  @extend .normal.large;
  //
  display:block;
  border:none;
  border-bottom: 1px solid $colour-white;
  padding:$spacer-s $spacer-s $spacer-xs 0;
  background:none;
  font-size:$font-size-normal;
  margin-bottom:$spacer-s;
  color:$colour-white;

  &::placeholder {
    color:transparentize($colour-white, .5);
  }

  // Animation
  transition: all $motion-easing-basic;

  &:invalid, &.state-invalid {
    border-color:$colour-red;
    color:$colour-red;
    font-weight:$font-weight-heavy;
  }

}


.form-quick-search {
  border-bottom:1px solid $colour-grey-mid;
  position:relative;

  &::before {

    $size: 22px;
    @extend .i-search;

    background-size:contain;
    display:block;
    position:absolute;
    top:35px;
    opacity:.7;
    left:0;
    content:"";
    width:$size;
    height:$size;
    z-index:1;
  }

  input.search-input {
    @extend .normal;
    @include remove-focus-highlight();

    background:transparent;
    margin-left:$spacer-s;
    padding:$spacer-xs;
    border:0;
    display:block;
    margin-bottom:0;
    width:100%;
  }
}
