// Team block
.block.block-team-grid {
  margin-top:-$spacer-normal;
  background-image:linear-gradient(
    white, white
  );
  background-repeat:no-repeat;
  background-position:0% #{$spacer-normal * 2.5};
  background-size: 100% calc(100% + #{$spacer-normal});

  @include respond-below(md) {
    background-position:0% #{$spacer-normal * 4};
  }

  // Filter
  .top {
    display:block;
    position: relative;
    z-index:1;
  }

  .top .inner {
    @extend %grid-layout;
    margin-bottom:$spacer-m;

    @include respond-below(md) {
      .toggle-team, .search-team {
        margin-bottom:$spacer-s;
      }
    }

    .toggle-team {
      @include grid-area(1, 1, 1, 4);
      display:flex;
      flex-direction: column;

      a {
        @extend .normal;
        text-decoration: none;
        font-weight: 500;

        &.active {
          color:$colour-orange;
        }
      }

      a:first-of-type::after {
        display:inline-block;
        content:"|";
        margin:0 $spacer-xs;
        color:$colour-grey-mid;
      }
    }

    .search-team {
      @include grid-area(1, 4, 1, 10);
      position:relative;

      .search-input {
        -webkit-appearance: none;

        &::-webkit-search-cancel-button {
          -webkit-appearance: none;
          cursor: pointer;
          height: 20px;
          width: 20px;
          margin-right:6.2em;
          transform: translateY(-5px);
          background-image: url(get-icon(close, "dark"));
          background-size: contain;
          transition: opacity $motion-easing-basic;

          &:hover {
            opacity:.5;
          }
        }
      }

      .btn-inline-search {
        position:absolute;
        right:0;
        bottom:0 - $spacer-xs - 2;
        opacity:0;
      }

      .btn-inline-search:hover,
      .btn-inline-search:focus,
      input:focus + .btn-inline-search,
      input:hover + .btn-inline-search {
        opacity:1;
      }
    }

    .filter-team {
      @include grid-area(1, 10, 1, 13);

      @include respond-below(md) {
        margin-bottom:$spacer-s;
      }

      &.o-disabled {
        opacity:.4;
        pointer-events: none;
        cursor:no-drop;
      }
    }
  }

  .content {
    grid-row-gap: $spacer-m;
    @include target-ie {
      display:flex;
      flex-wrap:wrap;
      justify-content: space-between;
    }
  }

  .search-no-results {
    @include grid-area(1, 1, 1, span 6);
  }

  .person-thumb {
    @include grid-area(auto, auto, auto, span 3);
    @include target-ie {
      width:calc(100% / 4 - #{$layout-grid-gap});
      margin-bottom:$spacer-s;
    }
    @include respond-below(lg) {
      @include grid-area(auto, auto, auto, span 4);
      @include target-ie {
        width:calc(100% / 3 - #{$layout-grid-gap});
      }
    }
    @include respond-below(md) {
      @include grid-area(auto, auto, auto, span 6);
      @include target-ie {
        width:calc(100% / 2 - #{$layout-grid-gap});
      }
    }
    @include respond-below(sm) {
      margin-bottom:$spacer-m;
    }
  }

  .bottom {
    margin-top:$spacer-normal;
  }

  .btn-primary {
    background:$colour-white!important;
    margin-bottom:$spacer-normal;
  }

}
