// Team block
.block.block-investment-grid {

  // Filter
  .top {
    display:block;
    position: relative;
    z-index:1;

    .inner {
      @extend %grid-layout;
      margin-bottom:$spacer-m;
    }

    .result-count {
      @include grid-area(2,1,2,6);
      align-self: flex-end;
      opacity:.5;
      margin-bottom:$spacer-xs - 2;
    }

    .filter {
      @include grid-area(2,10,2,13);
    }

  }

  .content {
    grid-row-gap: $spacer-m;

    @include target-ie {
      display:flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }

  .investment-thumb {
    @include target-ie {
      margin-bottom:$spacer-m;
    }
    @include grid-area(auto, auto, auto, span 3);
    @include target-ie {
      width:calc(100% / 4 - #{$layout-grid-gap});
    }
    @include respond-between(md,lg) {
      @include grid-area(auto, auto, auto, span 4);
      @include target-ie {
        width:calc(100% / 3 - #{$layout-grid-gap});
      }
    }
    @include respond-between(sm,md) {
      @include grid-area(auto, auto, auto, span 6);
      @include target-ie {
        width:calc(100% / 6 - #{$layout-grid-gap});
      }
    }
    @include respond-below(sm) {
      margin-bottom:$layout-grid-gap;
    }
    &, a, .inner {
      height:100%;
    }
  }

  .bottom {
    margin-top:$spacer-normal;
    padding-bottom:$spacer-normal;
  }
}
