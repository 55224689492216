/*
Two Column
*/
.block-two-column {

  position:relative;
  overflow-y:hidden;

  .content {
    @extend %grid-layout;

    height:max-content;

    .column p:only-child {
      a.btn-primary {
        display:block;
        width:max-content;
        margin-right:0;
        margin-left:auto;
      }
    }
  }

  /*
  Column Gap
  */
  &.o-gap- {

    &narrow {
      @include set-column-areas(
        "1 / 2 / 1 / 7",
        "1 / 7 / 1 / 12"
      );
    }

    &normal {

      @include set-column-areas(
        "1 / 1 / 1 / 7",
        "1 / 8 / 1 / 13"
      );

      &.o-size-narrow {
        @include set-column-areas(
          "1 / 2 / 1 / 7",
          "1 / 8 / 1 / 12"
        );
        @include respond-below(lg) {
          @include set-column-areas(
            "1 / 1 / 1 / 7",
            "1 / 8 / 1 / 13"
          );
        }
      }

      .top .inner {
        @include grid-area(1, 1, 1, 7);
      }

      .bottom .inner {
        @include grid-area(1, 1, 1, 12);
      }

      &.o-ratio- {
        &3070 {
          @include set-column-areas(
            "1 / 2 / 1 / 5",
            "1 / 6 / 1 / 12"
          );
          .top .inner {
            @include grid-area(1, 2, 1, 9);
          }
        }

        &7030 {
          @include set-column-areas(
            "1 / 2 / 1 / 9",
            "1 / 9 / 1 / 13"
          );
          .top .inner {
            @include grid-area(1, 2, 1, 9);
          }
        }
      }
    }

    &wide {
      @include set-column-areas(
        "1 / 1 / 1 / 6",
        "1 / 8 / 1 / 13"
      );
    }

  }

  /*
  Image block positions
  */
  &.o-image-right,
  &.o-image-left {

    @include respond-below(md) {
      .content {
        display:block;
      }
      .image {

        // Override all the image types
        position:static!important;
        width:100%!important;
        transform:none!important;

        // Hide images if their portrait
        img[data-orientation="portrait"] {
          display:none;
        }
      }
    }
  }
  &.o-image-right {
    @include respond-below(md) {

      .image, .video {
        margin-top:$spacer-m!important;
      }
    }
  }
  &.o-image-left {
    @include respond-below(md) {
      .image, .video {
        margin-bottom:$spacer-m!important;
      }
    }
  }

  /*
  Normal position
  */
  &.o-image-position-normal {
    &.o-image-right .content > .image,
    &.o-image-right .content > .video {
      @include grid-area(1, 8, 1, span 5);
    }
    &.o-image-left .content > .image,
    &.o-image-left .content > .video {
      @include grid-area(1, 1, 1, span 6);
    }
  }

  /*
  Side offset
  */
  &.o-image-position-offset-side {
    position:relative;

    .content > .image,
    .content > .video {
      @include respond-above(sm) {
        position:absolute;
      }
    }

    &.o-image-right, &.o-image-left {
      .content > .image,
      .content > .video {
        @include respond-above(sm) {
          width:calc(50% - (#{$layout-grid-gap} * 2));
        }
      }
    }

    &.o-image-right .content > .image,
    &.o-image-right .content > .video {
      &:nth-child(1) {
        left:0;
      }
      &:nth-child(2) {
        right:0;
      }
    }

    &.o-image-left .content > .image,
    &.o-image-left .content > .video {
      &:nth-child(1) {
        left:0;
      }
      &:nth-child(2) {
        right:0;
      }
    }
  }

  /*
  Top offset
  */
  &.o-image-position-offset-top {
    @extend .o-image-position-normal;

    $move: -$spacer-normal * 2;
    overflow:visible;
    padding-bottom:$spacer-s;

    .content > .image,
    .content > .video {
      transform:translateY($move);
      margin-bottom:$move;

      @include respond-below(md) {
        transform:translateY($move / 1.5)!important;
        margin-bottom:0!important;
      }
      @include respond-below(sm) {
        transform:translateY($move / 2)!important;
      }
    }
  }

  /*
  Top and Bottom offset
  */
  &.o-image-position-offset-top-bottom {

    &.o-image-left .content > .image,
    &.o-image-left .content > .video  {
      @include grid-area(1, 1, 1, span 5);
    }
    &.o-image-right .content > .image,
    &.o-image-right .content > .video  {
      @include grid-area(1, 8, 1, span 5);
    }

    overflow:visible;

    .content > .image,
    .content > .video {
      height:0;
      position:relative;
      top:-200px;
      height:calc(100% + 320px);
      margin-bottom:0;

      img {
        height:100%;
        width:100%;
        object-fit: cover;
      }
    }
  }

  /*
  Vertical Align Center
  */
  &.o-verticalalign-center {
    .column {
      @include vertical-center();
    }
  }

  /*
  Vertical Offset
  */
  &.o-verticaloffset {
    overflow-y:visible;
    // margin-top:$spacer-normal;

    .content {
      padding-bottom:0!important;
      height: 100%;
    }

    .column:nth-of-type(1) {
      // This configuration has a wider image
      @include grid-area(1, 1, 1, 7);
      // position:relative;
      // top:-50%;
      transform: translateY(-50%);
    }
    .column:nth-of-type(2) {
      @include grid-area(1, 9, 1, 13);
      overflow-y:hidden;
    }
  }
}
