/*
Carousel
*/
.carousel-panes {
  .carousel-pane {
    display:none;
    &.active {
      display:block;
    }
  }
}

.carousel-pagination {

  display:flex;
  align-items: flex-start;
  height:3px;

  .bar {
    display:block;
    cursor:pointer;
    padding-top:$spacer-xs;
    height:15px;
    margin-right:$spacer-s - 5;
    width:100%;
    transition: all $transition-800-bezier;
    border-top:2px solid rgba(0,0,0,.15);

    .o-style-grey &,
    .page-style-grey &,
    .site-footer & {
      border-bottom-color:$colour-grey-mid;
    }

    &:hover,
    &.active {
      border-color:$colour-orange;
      border-bottom-width: 2px;
    }

    &.active {
      width:200%;
    }

    &:last-of-type {
      margin-right:0;
    }
  }
}
