.block.block-people-highlight {

  &.o-size-narrow {
    .top .inner {
      @include grid-area(1,2,1,12);
    }
    .people {
      @include grid-area(1,2,1,12);
    }
  }

  .people {
    @include grid-area(1,1,1,13);
    display:flex;
    justify-content: flex-start;
    flex-wrap:wrap;
  }

  .person-thumb {
    display:flex;
    align-items: center;
    align-content: flex-start;
    margin:$spacer-m $spacer-normal $spacer-s 0;
    width:max-content;

    @include respond-below(md) {
      max-width:none;
      width:50%;
    }

    @include respond-below(sm) {
      max-width:none;
      width:100%;
    }

    &:nth-of-type(-n+3) {
      margin-top:0;
    }

    .portrait {
      $size: 75px;
      @extend .round;
      overflow: hidden;
      width:$size;
      height:$size;
      webkit-mask-image: -webkit-radial-gradient(white, black);

      &.fx-gradient {
        background-position:center center;
      }

      img {
        width:auto;
        height:100%;
        width:100%;
        overflow: hidden;
        object-fit: cover;
        webkit-mask-image: -webkit-radial-gradient(white, black);
        @include target-ie() {
          width:auto;
          transform: translateX(-16%);
        }
      }
    }

    .name, .role {
      color:$colour-white;
      margin-left:$spacer-xs;
    }

    .name {
      white-space: nowrap;
    }
  }
}
