/*
Images -> Grid
*/
.block.block-images-grid {

	.content {
		display:flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content:space-between;
		align-items:flex-start;

		@include respond-below(sm) {
			display:block;
		}
	}

	$no-columns: 1 2 3 4 5;
	@each $no-col in $no-columns {
		&[data-grid-columns="#{$no-col}"] .item {
	    	width:calc((100% / #{$no-col}) - #{$spacer-s});
	    	@include respond-below(md) {
				width:calc(50% - (#{$spacer-s} / 2));
			}
			@include respond-below(sm) {
				width:100%;
			}
	  	}
	}

	.item {
		margin-bottom:$spacer-m;
	}
}
