.block.block-news-grid {

  .top {
    display:block;
    position: relative;
    z-index:1;

    .inner {
      @extend %grid-layout;
    }

    h3 {
      @include grid-area(1,1,1,4);
      margin-bottom:0;
    }

    .result-count {
      @include grid-area(2,1,2,6);
      align-self: flex-end;
      opacity:.5;
      margin-bottom:$spacer-xs - 2;
    }

    .filter {
      @include grid-area(2,9,2,13);
    }
  }

  .content {
    margin-bottom:$spacer-m + 20;
    padding-top:$spacer-m!important;
    @include target-ie {
      display:flex;
      flex-wrap: wrap;
      justify-content:space-between;
    }
  }

  .news-article-thumb  {
    margin-bottom:$layout-grid-gap;
    @include target-ie {
      width:calc(100% / 3 - #{$layout-grid-gap});
    }
    @include grid-area(auto,auto,auto,span 4);
    @include respond-below(md) {
      @include grid-area(auto,auto,auto,span 6);
      @include target-ie {
        width:calc(100% / 2 - #{$layout-grid-gap});
      }
    }

    .title {
      color:$colour-white;
    }
  }


  // Vary background colours on new grid
  .news-article-thumb.no-image {
    &:nth-of-type(4n-6) .inner {
      background-image:url($file-path-media + "shapes/shape-t-green.svg")!important;
      background-size:210%;
      background-position: 92% 55%;
    }
    &:nth-of-type(11n-6) .inner {
      background-image:url($file-path-media + "shapes/shape-o-offwhite.svg")!important;
      background-size:200%;
      background-position: 50% 50px;
    }
  }
}
