/*
Image
*/

// Fix for Safari contrast issue with SVG filters
// Forces
@keyframes fix-safari-fx {
	from { filter:url(#gradientFilter);	}
	to { filter:url(#gradientFilter) contrast(1.0001); }
}

// Apply gradient filter
figure.fx-gradient {
	img {
		filter:url(#gradientFilter);

		// Super short fix for Safari
		animation: fix-safari-fx .1s .1s;

		@include target-edge {
			animation:none;
			filter: brightness(0.86) contrast(0.76) opacity(0.92) sepia(1) hue-rotate(175deg);
		}
	}
}

figure {
	width:100%;
	// overflow:hidden;

	video {
		@include target-edge {
			filter: brightness(0.86) contrast(0.86) opacity(0.92) sepia(1) hue-rotate(186deg);
		}
	}

	img {
		width:100%;
		height:auto;
		&.lazyload, &.lazyloading {
			// filter: blur(12px);
		}
		&.lazyloaded {
			// filter: blur(0);
		}
	}

	/*
	Round borders
	*/
	&.round {
		border-radius: 100%;
		overflow: hidden;
	}

	// Image Ratio
	&[data-ratio] {
		height:auto;
		position: relative;

		img {
			position: absolute;
	  		top: 0;
	  		left: 0;
	  		width: 100%;
	  		height: 100%;
	  		object-fit: cover;
		}
	}


	// @include image-crop-ratio(natural) {
	// 	padding-top: 3px / 4px * 100%;
	// }
	// @include image-crop-ratio(4/3) {
	// 	padding-top: 3px / 4px * 100%;
	// }
	// @include image-crop-ratio(16/9) {
	// 	padding-top: 9px / 16px * 100%;
	// }
	// @include image-crop-ratio(1/1) {
	// 	padding-top: 1px / 1px * 100%;
	// }
	// @include image-crop-ratio(3/4) {
	// 	padding-top: 4px / 3px * 100%;
	// }
}
