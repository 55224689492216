/*
One Column
*/
.block-one-column {

  .content {
    display:block;
  }

  &.o-verticalalign-center {
    .column {

    }
  }

  &.o-size-normal {
    .large {
      width:75%;
    }
  }

  &.o-size-narrow {

    .top {
      margin-bottom:$spacer-m;

      .inner {
        @include grid-area(auto, 2, auto, 10);
      }
    }

    .content {
      @extend %grid-layout;
      * {
        @include grid-area(auto, 2, auto, 10);
        @include respond-below(md) {
          @include grid-area(auto, 2, auto, 12);
        }
      }
    }
  }


}
