/*
Typography
*/

//////////
// Variables
//////////
$link-external-arrow: 18px;
//
$font-family-base: "Roboto", arial, sans-serif;
$font-family-lead: "BwGradual-Regular", arial, sans-serif;
$font-ratio: $phi;
///
$font-base-size: 16px;
$font-base-line-height: 1.5em;
$font-base-bottom-margin: $spacer-normal;
//
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-heavy: 500;
//
$font-size-xs: rem(12px);
$font-size-s: rem(14px);
$font-size-normal: rem($font-base-size);
$font-size-m: rem(19px);
$font-size-l: rem(27px);
$font-size-xl: rem(40px);
$font-size-xxl: rem(50px);
$font-size-xxxl: rem(75px);
//
$quote-mark-height: 150px;
$quote-mark-width: 210px;

/*
Import fonts
*/
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;1,300;1,400;1,500&display=swap");
@include fontFace("BwGradual-Regular", $file-path-media + "fonts/bw-gradual/");

/*
Baseline definitions
*/
html,
body {
  font-size: $font-base-size;
  color: $colour-base;
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  ::selection {
    color: $colour-white;
    background: transparentize($colour-orange, 0);
  }
}

/*
Auto-overwrite
*/
$tags: h6 h5 h4 h3 p a button li td th nav input label select textarea
  blockquote cite span div;
@each $tag in $tags {
  $i: index($tags, $tag);
  #{$tag} {
    font-family: $font-family-base;
    color: $colour-base;
    text-align: left;
    line-height: $font-base-line-height;
  }
}

/*
Text styles (sans colour)
*/
a {
  text-decoration: underline;
  transition: color $transition-d-bezier;

  &:hover {
    color: $colour-orange;
    text-decoration: none;
  }

  &[rel="external"] {
    @extend %i-arrow;
    padding-right: $spacer-s;

    &::after {
      background-image: url(get-icon(arrow, "orange"));
      right: 0 !important;
      width: $link-external-arrow !important;
      height: $link-external-arrow !important;
      background-size: contain;
    }
  }

  // Wrap break emails
  &:not(.btn-primary)[href*="@"] {
    // @credit https://css-tricks.com/snippets/css/prevent-long-urls-from-breaking-out-of-container/
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-all;
    word-break: break-word;
    hyphens: auto;
  }
}
h1,
.h1 {
  font-family: $font-family-lead;
  font-size: $font-size-xxl;
  font-weight: $font-weight-normal;
  letter-spacing: 0.7px;
  line-height: rem(63px);
  color: $colour-orange;
  margin-bottom: $spacer-normal;

  @include respond-below(sm) {
    font-size: $font-size-xl;
    line-height: rem(50px);
  }
}

h2,
.h2 {
  font-family: $font-family-lead;
  font-size: $font-size-l;
  font-weight: $font-weight-normal;
  letter-spacing: 0.63px;
  line-height: rem(36px);
  margin-bottom: $spacer-m;
}

h3,
.h3 {
  font-size: $font-size-m;
  font-weight: $font-weight-normal;
  text-transform: uppercase;
  letter-spacing: 0.7px;
  line-height: rem(30px);
  margin-bottom: $spacer-m;
}

h4,
.h4 {
  font-size: $font-size-m;
  font-weight: $font-weight-normal;
  line-height: $font-size-m + 0.5;
  letter-spacing: 0;
  margin-bottom: $spacer-s;
}

h5,
.h5 {
  font-size: $font-size-normal;
  font-weight: $font-weight-heavy;
  letter-spacing: 0;
  color: $colour-orange !important;
  margin-bottom: $spacer-xs;
}

h6,
.h6 {
  font-size: $font-size-s;
  font-weight: $font-weight-heavy;
  letter-spacing: 0;
  margin-bottom: $spacer-s;
}

p,
.normal {
  font-size: $font-size-normal;
  font-weight: $font-weight-normal;
  letter-spacing: 0px;
  line-height: rem(24px);
  margin-bottom: $spacer-s;

  &.medium {
    font-family: $font-family-lead;
    font-size: $font-size-m;
  }

  &.large {
    @extend .h2;
  }
  &.large.highlight {
    color: $colour-orange !important;
    margin-top: $spacer-normal - 10;
    margin-bottom: 0;
  }
}

b,
.heavy {
  font-weight: $font-weight-heavy;
}

ol,
ul {
  @include respond-below(lg) {
    columns: 1 !important;
  }
  + h2,
  h3,
  h4,
  h5 {
    margin-top: $spacer-s;
  }
}

ul {
  list-style: none;
  list-style-position: inside;
  margin-top: $spacer-m;
  padding: 0;

  &.no-cols {
    li {
      display: block;
    }
  }

  &:not(.no-cols) {
    columns: 2;
    break-inside: avoid-column;
    li {
      break-inside: avoid-column;
    }
  }

  li {
    margin: 0 0 $spacer-xs $spacer-s;
    position: relative;
    padding-left: $spacer-xs;
    display: inline-block;
  }

  li::before {
    content: "\2022";
    display: inline-block;
    font-size: 40px;
    margin: 0 $spacer-s 0 -34px;
    // transform: translate3d(5px, 10px, 0);
    transform: translateX(5px) translateY(10px);
    color: $colour-orange;
  }
}

// List items should not have as much margins if their preceded by a header
h3,
h4,
h5 {
  + ul,
  ol {
    margin-top: $spacer-xs;
  }
}

address {
  font-style: normal;
}

small,
.small {
  font-size: $font-size-s;
  line-height: rem(21px);
  letter-spacing: 0;
  margin-bottom: $spacer-s;
}

.xsmall {
  font-size: $font-size-xs;
  letter-spacing: 0;
  line-height: rem(18px);
  margin-bottom: 0;
}

.label {
  // @extend .small;
  font-weight: $font-weight-normal;
  // color:transparentize($colour-blue, .6)!important;
  margin-bottom: $spacer-s;
}

.line {
  display: block;
  color: $colour-orange !important;
  position: relative;
  width: calc(100% - #{$spacer-s});
  overflow-x: hidden;
  margin-bottom: $spacer-s;

  .o-style-blue &,
  .o-style-green & {
    color: $colour-grey !important;
    &::after {
      background: $colour-grey;
    }
  }

  &::after {
    opacity: 0.7;
    display: inline;
    background: $colour-orange;
    content: "";
    height: 2px;
    width: inherit;
    position: absolute;
    top: 45%;
    transform: translateX(#{$spacer-s});
  }
}

.figure-large {
  font-size: $font-size-xxxl;
  font-weight: $font-weight-light;
  line-height: rem(80px);
}

.stat-figure {
  font-size: $font-size-xxl;
  font-weight: $font-weight-normal;
  color: $colour-orange !important;

  @include respond-between(sm, lg) {
    font-size: 6vw !important;
  }
  @include respond-below(sm) {
    font-size: 17vw !important;
  }
}

.link {
  @include respond-above(md) {
    color: $colour-blue;
    position: relative;

    &::after {
      position: absolute;
      content: "";
      display: block;
      bottom: 3px;
      left: $spacer-xs;
      right: $spacer-xs;
      height: 1px;
      width: calc(100% - (#{$spacer-xs} * 2));
      border: 1px solid $colour-orange;
      opacity: 1;
      transition: all $transition-d-bezier;
    }
  }
}

.nav-item {
  .nav-sub-menu-link {
    font-family: $font-family-base;
    font-size: 1.3em !important;
  }
  a {
    @extend .link;

    @include respond-above(md) {
      font-family: $font-family-base;
      font-weight: $font-weight-heavy;
      font-size: $font-size-s;
      letter-spacing: 0.52px;
      line-height: 23px;
      color: $colour-blue;
    }

    @include respond-below(md) {
      font-family: $font-family-lead;
      font-size: $font-size-l;
      color: $colour-white;
      text-decoration: none;
    }
  }
}

// Inline text quote format
blockquote:not([class]) {
  color: transparentize($colour-orange, 0.3) !important;
  padding: 0;
  margin-left: 0 !important;

  &::before {
    content: "";
    display: block;
    height: 60px;
    width: 85px;
    margin: $spacer-s 0;
    background: url($file-path-graphics+"quotes-orange.svg") no-repeat top left;
    background-size: contain;
  }
}

// Large quote format
.quote {
  &.o-large blockquote {
    font-size: $font-size-xl;
    line-height: 1.1em;

    cite {
      font-size: $font-size-normal;
    }
  }

  blockquote {
    @extend .h2;

    margin: 0;
    quotes: none;
    opacity: 1;

    &::before {
      content: "";
      display: block;
      height: $quote-mark-height;
      width: $quote-mark-width;
      background: url($file-path-graphics+"quotes-orange.svg") no-repeat top
        left;
      background-size: auto;
      margin-bottom: 0;
      position: relative;
      top: $spacer-m + 25;
      left: -($spacer-normal + 15);
    }

    cite {
      @extend .h3;
      display: block;
      margin-top: $spacer-m;
      color: $colour-orange;
    }
  }
}

// Horizontal rule
hr {
  margin: $spacer-m 0 $spacer-m 0;
  border: 0;
  height: 1px;
  background: transparent;
  background-image: linear-gradient(to right, $colour-grey, $colour-grey);
}
