address.contact {
  border-bottom:1px solid $colour-orange;
  border-right:1px solid $colour-orange;
  padding:$spacer-s;
  .name {
    @extend .h4;
    display:block;
    margin-bottom:$spacer-xs;
  }
  a[href] {
    text-decoration:none;
    overflow:hidden;
    text-overflow:ellipsis;
    white-space:nowrap;
    display:block;

    // &::before {
    //   @extend .h5;
    //   width:50px;
    //   display:inline-block;
    //   color:$colour-blue!important;
    // }
  }
  a[href*="mailto"]::before {
    // content:"Email:";
  }
  a[href*="tel"]::before {
    // content:"Tel:";
  }

}
