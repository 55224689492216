/*
Investment
*/
.site-header.type-investment {

  position:relative;
  overflow-x:inherit;

  &[class*="halo"]::before {
    background-position:50% bottom;
    min-height:100vh;
    bottom:0;
  }

  h1 {
    @include grid-area(1, 4, 1, 11);
    @include respond-between(sm, md) {
      @include grid-area(1, 2, 1, 11);
    }
    @include respond-below(sm) {
      margin-bottom:$spacer-m;
    }

    margin-bottom:0;
    color:$colour-orange;

    &[data-status]::before {
      @extend .h3;
      content:attr(data-status);
      display:block;
      color:$colour-white;
      margin-bottom:$spacer-s;
    }
    &[data-status="realised" i]::before {
      color:$colour-blue;
    }
  }

  .hero {
    @include grid-area(1, 7, 2, 13);
    @include respond-between(sm, md) {
      @include grid-area(1, 6, 2, 13);
      img {
        width:calc(60vw);
      }
    }
    @include respond-below(sm) {
      img {
        width:100%!important;
      }
    }
    margin-top:0;

    img {
      position:static;
    }
  }

  .summary {
    margin-top:-200px;
    padding-right:$spacer-m;
    margin-bottom:0;

    @include grid-area(2, 2, 3, 7);
    @include respond-between(md, lg) {
      margin-top:-100px;
    }
    @include respond-between(sm, md) {
      @include grid-area(2, 2, 2, 12);
      margin-top:$spacer-m;
    }
    @include respond-below(sm) {
      margin-top:0;
      margin-bottom:$spacer-m;
    }
  }

  .introduction {
    @include grid-area(2, 7, 3, 12);
    @include respond-between(sm, md) {
      @include grid-area(3, 2, 3, 11);
      padding-top:$spacer-m;
    }
    padding:$spacer-normal 0;

    .btn-primary {
      margin-top:$spacer-m;
    }
  }
}
