/*
Page
*/

$page-colours: ( "white": $colour-white, "grey": $colour-grey, "green": $colour-green, "blue": $colour-blue );

@each $name, $hex in $page-colours {
    body.page-style-#{$name} {
      background-color:$hex;
    }
}

.page-content .block:first {
  padding-top:$spacer-normal;
}

#site {
  width:100%;
}
