/*
Image Gallery
*/

.block.block-image-gallery {

  $width-xl: 1000px;
  $width-lg: 900px;
  $width-md: 800px;

  .top {
    margin-bottom:$spacer-m;
  }

  .top .inner {
    @include grid-area(1, 2, 1, 6);
    p {
      width:100%;
    }
  }

  .content {
    display:block;
    width:100vw;
    max-width:100vw;
    overflow-x:hidden;
    margin-left:0;
		margin-right:0;
  }

  .flickity-button {
    @extend .btn-page;

    top:50%;
    background-color:transparent;

    &.flickity-prev-next-button {
      &.previous {
        left:20px;
      }
      &.next {
        right:30px;
      }
    }

    svg {
      display:none;
    }
  }

  .carousel-cell {

    width: 100%;
    max-width: $layout-content-max-width;
    margin-right: $layout-grid-gap;

    .title {
      @extend .h2;
      text-align:center;
      opacity:0;
      margin-top:$spacer-m;
      transition: all $transition-d-bezier;

      display: none; // Hide titles for now
    }
    &.is-selected {
      .title {
        opacity:1;
      }
    }
  }
}
