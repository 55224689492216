/***
* Side menu
***/
.side-menu {
  position:fixed;
  z-index:20;
  top:50vh;
  transform: translate3d(0,-25%,0);
  transition: all $transition-d-bezier;
  padding-left:$spacer-xs;
  // animation: slidein-from-left 1.5s normal 2.5s forwards ease-in-out;

  @include respond-below(md) {
    display:none;
  }

  &.mini {
    // width:auto;
    // padding-right:100px;
    .side-menu-link {
      width:20px;
      overflow: hidden;

      &:hover {
        width:auto;
      }
    }
  }

  &.mute {
    // opacity:0!important;
    transform: translate3d(-100%,-25%,0)!important;
  }

  .side-menu-inner {
    display:flex;
    flex-direction: column;
  }

  .side-menu-link {
    text-decoration: none;
    margin-bottom:$spacer-s;
    padding-left:$spacer-xs;
    font-size:rem(14px);
    letter-spacing:.7px;
    transition: all 1s ease-in-out;
    white-space: nowrap;
    cursor:pointer;

    &:last-of-type {
      margin-bottom:0;
    }

    &.o-style-blue {
      color:$colour-blue;
    }

    &.o-style-white {
      color:$colour-white;
    }

    &::before {
      content: "\00B7";
      display:inline-block;
      transform: scale(3) translateY(.5px);
      margin-right:$spacer-xs;
    }

    &.active,
    &:hover {
      color:$colour-orange!important;
    }
  }
}
