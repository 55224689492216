/*
Halo
*/

.site-header[class*='halo-'] {
  &::before {
    @include absolute-position(true);

    // Avoid any mouse event traps
    pointer-events: none;
    width:100vw;
    height:100%;
    z-index:2;
  }

  // Halo variations
  $shapes: "o","t","g","s","r";
  $colours: orange, offwhite, blue, green, black;

  @each $shape in $shapes {
    @each $colour in $colours {
      &.halo-#{$shape}-#{$colour}::before {
        background-image:url($file-path-media + "shapes/shape-#{$shape}-#{$colour}.svg");
        background-size: cover;
        background-position: top center;
        background-repeat: no-repeat;
        mask-image: linear-gradient(to top, transparent 10%, black 20%);

        @if $shape == 'o' {
          background-position: 50% 220px;

          @include respond-below(md) {
            background-size:120%;
          }
        }

        @if $shape == 's' {
          background-size:calc(100% - 100px) 100%;
          background-position:top right;

          @include respond-below(md) {
            background-size:cover;
          }
        }

        @if $shape == 'g' {
          background-size: contain;
          background-position:120% 100%;
          height:85%;

          @include respond-below(md) {
            height:100%;
          }

          @include respond-below(sm) {
            background-position:50% 100%;
            background-size:auto 100%;
          }
        }

        @if $shape == 'r' {
          background-size: cover;
          background-position:top left;
        }
      }
    }
  }
}
