/*
SDG List
*/
.block.block-sdg-list {

  background:$colour-orange!important;

  .content {
    display:flex;
    justify-content:flex-start;
    align-content:flex-start;
    align-items: center;
    padding-top:$spacer-normal - 20!important;
    padding-bottom:$spacer-normal - 20!important;

    @include respond-below(lg) {
      padding-left:$spacer-m!important;
    }
    @include respond-below(sm) {
      display:block;
    }
  }

  .intro {
    min-width:220px;

    @include respond-below(sm) {
      width:calc(100% - #{$spacer-s});
      padding-bottom:$spacer-s;
      margin-bottom:$spacer-m;
      border-bottom:1px solid transparentize($colour-white, .8);
    }

    .large {
      color:$colour-white;
      margin:0;
      @include respond-below(sm) {
        margin-bottom:$spacer-xs;
      }
    }
  }

  .sdg-goals {
    width:100%;
    display:flex;
  }

  .sdg {
    max-width:110px;
    margin-bottom:0;
    margin-right:$spacer-s;

    img {
      display:block;
    }
  }

}
