.block.block-office-tiles {
  background-image:linear-gradient(
    white, white
  );
  background-repeat:no-repeat;
  background-position:0% #{$spacer-normal * 2};
  background-size: 100% calc(100% + #{$spacer-normal});

  .content {
    @include target-ie {
      display:flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }

  .office-thumb {
    margin-bottom:0;

    @include respond-below(sm) {
      margin-bottom:$spacer-m;
    }
    @include target-ie {
      width:40%;
      margin-top:0!important;
      margin-bottom:$spacer-normal;
      &:nth-of-type(even) {
        margin-top:$spacer-normal * 1.5!important;
      }
    }

    &:nth-of-type(odd) {
      @include grid-area(auto, 1, auto, 6);
      @include respond-below(md) {
        @include grid-area(auto, 1, auto, 7);
      }
      @include respond-above(sm) {
        &:last-child {
          margin-top:-$spacer-normal * 3;
        }
      }
    }

    &:nth-of-type(even) {
      @include grid-area(auto, 8, auto, 13);
      @include respond-below(md) {
        @include grid-area(auto, 7, auto, 13);
      }
      @include respond-above(sm) {
        margin-top:25%;
        &:nth-of-type(2) {
          margin-top:50%;
        }
      }
    }
  }
}
