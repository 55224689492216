/*
Job Tile
*/
.job-tile {
  padding: $spacer-s + 10;

  .office {
    text-transform: uppercase;
  }

  &.o-style-grey {
    background: $colour-grey;
    .title,
    .office,
    .summary,
    .btn-sec,
    a {
      color: $colour-black;
    }
    .btn-sec::after {
      background-image: url(get-icon(arrow, "dark"));
    }
  }
}
