/*
Team
*/

.page-team {

  // Header variations
  .site-header {
    min-height:60vh;
    .page-header .content {
      padding-bottom:$spacer-normal;
    }
    @include respond-below(sm) {
      min-height:auto;
    }
  }
}
