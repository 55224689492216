/*
Article
*/
.site-header.type-article {
  background:white;
  padding-bottom:$spacer-normal;

  .date {
    @include grid-area(1,4,1,8);
  }

  h1 {
    @include grid-area(2,4,2,11);
    @include respond-below(sm) {
      margin-bottom:$spacer-m;
    }

    color:$colour-blue;
    margin-bottom:$spacer-normal + 40;
  }

  .logos {
    @include grid-area(3,3,3,span 2);
    @include respond-below(sm) {
      width:50%;
      transform: none;
      display:flex;
      justify-content: space-between;
      align-items: center;
    }

    transform:translateX(-50px);
  }

  .logo {
    margin-bottom:$spacer-m;
    @include respond-below(sm) {
      width:45%;
    }
  }

  .summary {
    @include grid-area(3,5,3,span 6);
    @include respond-below(sm) {
      width:80%;
    }
    font-family:$font-family-base;
  }

  .btn-primary  {
    @include grid-area(4,5,4,span 3);

    margin-top:$spacer-m;
  }

}
