/*
In Numbers
*/
$padding: $spacer-normal * 2;
.block.block-in-numbers {
  position:relative;
  // overflow-y:hidden;

  &::before {
    @include absolute-position(true);

    pointer-events: none;
    width:100%;
    height:70%;
    z-index:0;
    top:0;
    opacity:.3;
    // background-position: top center;
    background-position: 50% 20%;
    background-repeat: no-repeat;
    background-size: 80%;
    animation: halo-in 2s $animation-easing-bezier 1s forwards;
    border-radius:100%;
  }

  &.o-style-blue,
  &.o-style-green {
    // margin-bottom:$spacer-normal;
  }

  &.o-style-grey::before {
    background-image:url($file-path-media + "shapes/shape-arch-blue.svg");
  }

  &.o-style-green {
    &::before {
      background-image:url($file-path-media + "shapes/shape-arch-blue.svg");
    }
    .stat-figure {
      color:$colour-white!important;
    }
  }

  &.o-style-blue::before {
    opacity:.7;
    background-image:url($file-path-media + "shapes/shape-arch-orange.svg");
  }

  @include respond-below(sm) {
    padding-top:$spacer-normal;
    &::before {
      background-size:contain;
    }
  }

  .content {
    padding-bottom:$padding!important;

    @include target-ie {
      display:flex;
      justify-content: space-between;
    }

    &:nth-child(1) {
      @include respond-above(md) {
        padding-top:$padding!important;
      }
    }
    @include respond-below(md) {
      padding-bottom:$padding/2!important;
    }
    @include respond-below(sm) {
      padding-bottom:$spacer-s!important;
    }
  }

  .column {
    @include grid-area(1, auto, 1, span 3);
  }

  // For modules with a top aligned header
  // Tweak the grid structure and style
  .top {
    margin-bottom:$spacer-s;

    & + .content {
      .column {
        @include grid-area(2, auto, 2, span 4);
      }

      .stat {
        border-left:2px solid $colour-orange;
        padding-left:$spacer-m - 10;
      }

      .stat-fact {
        text-transform:uppercase;
      }

      .stat-details {
        padding-right:$spacer-normal - 20;
      }
    }
  }

  @include respond-between(sm, md) {
    .stat {
      display:flex;
      flex-wrap: wrap;
    }
    .stat-figure {
      align-self: flex-end;
      text-align: left;
      width:100%;
    }
    .stat-fact {
      align-self: flex-start;
      margin-bottom:$spacer-m;
    }
  }

  @include respond-below(sm) {
    .title {
      padding-bottom:$spacer-s;
    }
    .stat {
      display:flex;
      margin-bottom:$spacer-m;
      align-items: center;
    }
    .stat-figure {
      flex-basis:60%!important;
      margin-left:$spacer-s;
      margin-bottom:0;
      @include respond-below(sm) {
        font-size:7vw!important;
      }
    }
    .stat-fact {
      flex:1;
      margin-bottom:0;
    }

  }
}
