/*
Investment Map
*/
.block.block-investment-map {

  .map-states {
    @include grid-area(1,1,1,4);
    @include respond-below(md) {
      @include grid-area(1,1,1,13);
      display:flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-top:$spacer-s;
      margin-bottom:$spacer-s;
    }
  }

  .map-state {
    opacity:.5;
    cursor: pointer;
    transition: opacity $motion-easing-basic;

    @include respond-below(md) {
      width:calc(50% - #{$spacer-s});
    }


    &:hover,
    &.active {
      opacity:1;
    }
    &:not(:last-of-type) {
      margin-bottom:$spacer-m;
    }
    .title {
      color:$colour-orange;
      margin-bottom:$spacer-xs;
    }
  }

  .map-display {
    @include grid-area(1,5,1,13);
    height:700px;
    @include respond-below(md) {
      @include grid-area(2,1,2,13);
    }
  }

}
