/*
Cookie Notice
*/
.cookie-notice {

  position:fixed;
  z-index:400;
  background:$colour-orange;
  padding:$spacer-normal - 10;
  right:0;
  width:calc(70vw);
  min-width:400px;
  display:flex;
  align-items:center;
  box-shadow:  -18px 18px 36px rgba(0,0,0,.1),
             18px -18px 36px rgba(50,50,50,.05);

  @include respond-below(md) {
    width:100vw;
    padding:$spacer-m + 5;
  }

  @include respond-below(sm) {
    width:100vw;
    display:block;
    padding:$spacer-m - 5;
  }

  .inner {
    padding-right:$spacer-normal;
    p, a {
      margin-bottom:0;
      color:$colour-white;
    }

    @include respond-below(sm) {
      margin-bottom:$spacer-s;
      padding-right:0;
    }
  }

  .close.btn-primary {
    white-space: nowrap;
    background-color:$colour-orange;
    border-color: $colour-white;
    color: $colour-white;
    &::after {
      background-image:url(get-icon(arrow, "light"));
    }
  }

}
