/*
Investment Promoter
*/
.block-investment-promoter {

  .content {
    @extend %grid-layout;

    .column {
      @include grid-area(1,1,1,4);
      padding-right:$spacer-s;

      @include respond-below(lg) {
        @include grid-area(1,1,1,13);
        margin-bottom:$spacer-s;
      }
    }
  }

  .investment-list {
    @include grid-area(1,4,1,13);
    @include respond-below(lg) {
      @include grid-area(2,1,2,13);
    }
    @include respond-below(sm) {
      margin-top:$spacer-normal - 10;
    }

    .carousel-panes {
      margin-bottom:$spacer-m;
    }

    .carousel-pane.active {
      display:flex;
      flex-direction: row;
      animation: slideup-and-fade-in 3s normal forwards $animation-easing-bezier;

      @include respond-below(sm) {
        display:block;
      }

      &.hide {
        animation: fade-out .5s normal forwards $animation-easing-bezier;
      }
    }


    // Display two items
    &[data-display-num="2"] {
      .investment-thumb {
        &:nth-of-type(2n) {
          margin-right:0;
        }
        &:nth-of-type(n + 3) {
          display:none;
          opacity:0;
        }
      }
    }

    // Display three items
    &[data-display-num="3"] {
      .investment-thumb {
        &:nth-of-type(3n) {
          margin-right:0;
        }
        &:nth-of-type(n + 4) {
          display:none;
          opacity:0;
        }
      }
    }

    .investment-thumb {
      flex: 1;
      margin-right:$layout-grid-gap;

      &:only-of-type {
        max-width:50%;
      }

      @include respond-below(sm) {
        margin-right:0;
      }

      @include respond-below(sm) {
        margin-bottom:$spacer-s;
      }
    }

    .carousel-pagination {
      width:50%;

      @include respond-below(sm) {
        width:100%;
      }
    }
  }
}
