/*
Stat
*/

.stat {
  .stat-fact {
    margin-bottom:$spacer-m + 15;
  }
  .stat-figure {
    font-family:$font-family-lead;
    color:$colour-orange;
    font-size:$font-size-xxxl;
  }
  .stat-details {
    margin-top:$spacer-normal + $spacer-m;
    margin-bottom:0;
  }
}
