table {

  table-layout: fixed;
  border-collapse: collapse;
  margin-top:$spacer-normal;

  tr {
    border-top:1px solid $colour-blue;
    .o-style-blue &,
    .o-style-green &  {
      border-top-color:$colour-white;
    }
  }

  th, td {
    vertical-align: top;
    padding:$spacer-s $spacer-normal $spacer-s 0;
    &:last-of-type {
      padding-right:0;
    }
  }

  td {
    min-width:100px;
  }
}
