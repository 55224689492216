/*
Buttons
*/

$btn-page-size: 70px;
$btn-page-btn-size: 50%;
$btn-border-style: 1px solid $colour-blue;

//////////////
// Extensions
//////////////

[class*='btn-'] {
  // Display buttons inline
  &.btn-inline {
    display:inline-block;
    margin-right:$spacer-s;
  }
}


/*
Primary Buttons
*/
a.btn-primary,
p.btn-primary > a:only-child {

  @extend %i-arrow;

  text-decoration: none;
  display:inline-block;
  // box-sizing: border-box;
  font-weight:$font-weight-normal;
  letter-spacing:.6px;
  padding:$spacer-xs $spacer-m * 1.5 $spacer-xs $spacer-xs + 5;
  border-right:$btn-border-style;
  border-bottom:$btn-border-style;
  margin-right:$spacer-m;
  background:$colour-white;
  transform-style: preserve-3d;
  transition: all $motion-easing-basic;

  @include respond-below(sm) {
    &:only-of-type {
      margin-right:0;
    }
  }

  &::before {
    @include absolute-position(true);

    right:-10px;
    bottom:-10px;
    left:25%;
    width:80%;
    height:50%;
    top:22px;
    transform: translateZ(-1px);
    background:$colour-orange;
    opacity:0;
    border-radius:50px;
    transition: all $motion-easing-basic;
    filter:blur(10px);

    @include target-ie() {
      display:none;
    }
  }

  &::after {
    transition: background $motion-easing-basic;
    background-image:url(get-icon(arrow, "dark"));
  }

  &:hover,
  &.active,
  &.loading {

    border-color:transparent;
    color:$colour-orange;

    @include target-ie() {
      background-color:$colour-orange!important;
      color:$colour-white;
    }

    &:before {
      opacity:.6;
      height:75%;
    }

    &::after {
      background-image:url(get-icon(arrow, "orange"));
    }
  }

  &.loading {

    &::before {
      animation: loading-pulse 2s infinite alternate;
    }
  }

  &[href*="@"] {
    // @credit https://css-tricks.com/snippets/css/prevent-long-urls-from-breaking-out-of-container/
    // text-overflow: clip;
    // overflow: hidden;
    // white-space: nowrap;
  }

  &[data-file-size] {
    &::after {
      // content:attr(data-file-size);
    }
  }

  // Varying background colours depending on context
  .page-style-blue .block.o-style-none &,
  .page-style-blue .site-header.type-investment &,
  .block.o-style-blue & {
    background:$colour-blue;
  }

  .page-style-grey .block.o-style-none &,
  .block.o-style-grey & {
    background:$colour-grey;
  }

  .page-style-green .block.o-style-none &,
  .block.o-style-green & {
    background:$colour-green;
    &::before {
      background-color: $colour-blue;
    }
    &:hover {
      color:$colour-blue;
      @include target-ie() {
        color:$colour-white;
      }
      &::after {
        background-image:url(get-icon(arrow,"dark"));
      }
    }
  }

  // Styles
  &.o-white {
    border-color:$colour-white;
    color:$colour-white;
    &::after {
      background-image:url(get-icon(arrow,"light"));
    }
    &:hover,
    &.active {
      @extend .btn-primary:hover;
    }
  }

  &.o-grey {
    border-color:$colour-blue;
    color:$colour-blue;
    background:$colour-grey;
    &::after {
      background-image:url(get-icon(arrow, "dark"));
    }
    &:hover,
    &.active {
      @extend .btn-primary:hover;
    }
  }

  &.o-orange {
    border-color:$colour-orange;
    color:$colour-orange;
    &::after {
      background-image:url(get-icon(arrow, "orange"));
    }
    &:hover,
    &.active {
      @extend .btn-primary:hover;
    }
  }
}




/*
Secondary Buttons
*/
.btn-sec {

  @extend %i-arrow;

  display:block;
  width:max-content;
  margin-right:$spacer-xs;
  font-weight:$font-weight-heavy;
  padding:$spacer-xs 0;
  padding-right:$spacer-m;

  // Arrow
  &::after {
    transition: all $motion-easing-basic;
    transform:translateX(0);
    right:$spacer-xs;
    background-image:url(get-icon(arrow, "dark"));
  }

  &:hover::after {
    transform:translateX(#{$spacer-xs});
    background-image:url(get-icon(arrow, "orange"));
  }

  // Styles
  &.o-white {
    color:$colour-white;
    &::after {
      background-image:url(get-icon(arrow, "light"));
    }
  }
}

/*
Download buttons
*/
$button-file-types: pdf, doc, png, zip, jpg, pptx;
@each $button-file-type in $button-file-types {
  a[class*="btn-"][href*=".#{$button-file-type}"] {
    @extend .i-arrow.o-down;
  }
}



/*
Scroll
*/
.btn-page {

  width:65px;
  height:65px;

  position:absolute;
  display:block;
  justify-content: center;
  align-items: center;
  align-content: center;
  text-align: center;
  z-index:1;
  top:40%;
  cursor:pointer;

  background-image:url(get-icon(arrow-circle, "orange"));
  background-repeat: no-repeat;
  background-size: contain;
  transition: opacity $motion-easing-basic;

  &[role="Previous"],
  &[aria-label="Previous"] {
    transform:translateY(-50%) scaleX(-1)!important;
  }

  &:hover {
    opacity:.5;
  }

  // Styles
  &.o-white {
    color:$colour-white;
    background-image:url(get-icon(arrow-circle, "light"));
  }

  &.o-orange {
    color:$colour-orange!important;
    background-image:url(get-icon(arrow-circle, "orange"))!important;
  }

  &.o-dark {
    color:$colour-blue!important;
    background-image:url(get-icon(arrow-circle, "dark"))!important;
  }
}


/*
Inline search
*/
.btn-inline-search {
  @extend .label;

  background:transparentize($colour-blue, .8);
  color:$colour-blue;
  padding:$spacer-xs $spacer-s;
  transition: all $motion-easing-basic;

  &:hover {
    background:$colour-orange;
    color:$colour-white;

  }
}
