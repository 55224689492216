.block.block-featured-news {

  .content {
    padding-top:$spacer-m!important;
    // justify-content:stretch;
    // align-items:stretch;
    // align-content:stretch;
  }

  .news-article-thumb {
    align-self: start;
    // height:100%;
    @include respond-below(sm) {
      margin-bottom:$layout-grid-gap;
    }

    &:nth-of-type(1) {
      @include grid-area(1,1,1,6);
      @include respond-below(md) {
        @include grid-area(1,1,1,7);
      }
    }

    &:nth-of-type(2) {
      @include grid-area(1,8,1,13);
      @include respond-below(md) {
        @include grid-area(1,7,1,13);
      }
    }

    a, .inner {
      display:block;
      height:auto;
    }

    &:not(.no-image) {
      .inner {
        background:transparent;
      }

      .title, .category, .date {
        color:$colour-blue;
      }
    }
  }
}
